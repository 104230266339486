import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/system/Box";
import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddForm from "./AddForm";
function AddAddress({ handleClose, isEditing, phoneNumber }) {
  const [addressType, setAddressType] = useState("local");
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const defaultAddress = useSelector(
    (state) => state.cart?.cartData?.user?.defaultAddress ?? ""
  );

  useEffect(() => {
    if (defaultAddress.id) {
      setAddressType((type) =>
        type !== defaultAddress.localType ? defaultAddress.localType : type
      );
    }
  }, [defaultAddress]);
  return (
    <>
      <FormControl>
        <Box
          sx={{
            display: "block",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {matches && <FormLabel>Please select address type</FormLabel>}
          <Button
            variant="text"
            onClick={handleClose}
            sx={{ position: "fixed", right: "10px" }}
          >
            <ClearIcon />
          </Button>
        </Box>
        <RadioGroup defaultValue="local" sx={{ display: "block" }}>
          <FormControlLabel
            value="local"
            control={
              <Radio
                checked={addressType === "local"}
                onClick={() => setAddressType("local")}
              />
            }
            label="Local Address"
          />
          <FormControlLabel
            value="international"
            control={
              <Radio
                checked={addressType === "international"}
                onClick={() => setAddressType("international")}
              />
            }
            label="International Address"
          />
        </RadioGroup>
      </FormControl>
      <AddForm
        handleClose={handleClose}
        addressType={addressType}
        isEditing={isEditing}
        phoneNumber={phoneNumber}
      />
    </>
  );
}

AddAddress.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default memo(AddAddress);
