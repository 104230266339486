const reduceObject = (firstObj, secondObj) => {
  let updatedObject = Object.entries(firstObj)
    .reduce((index, value) => {
      if (
        JSON.stringify(firstObj[value[0]]) !==
        JSON.stringify(secondObj[value[0]])
      )
        index.push(value);
      return index;
    }, [])
    .reduce((index, value) => {
      index[value[0]] = value[1];
      return index;
    }, {});
  return updatedObject;
};
export default reduceObject;
