import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import propTypes from "prop-types";
import { useMemo, useState } from "react";
import {
  BUTTON_HOVER,
  BUTTON_HOVER_BORDER_COLOR,
  FILTERS_BORDER_COLOR,
  FILTER_TEXT_COLOR,
} from "styles/colors";

const maxDate = dayjs(new Date());
export default function MonthPicker({ onChange }) {
  const [error, setError] = useState(null);
  const errorMessage = useMemo(() => {
    switch (error) {
      case "maxDate": {
        return "Date can't be greater than current date";
      }

      case "invalidDate": {
        return "Your date is not valid";
      }

      default: {
        return "";
      }
    }
  }, [error]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <DatePicker
          label={"Month"}
          onError={(newError) => setError(newError)}
          views={["month", "year"]}
          format="MM/YYYY"
          slotProps={{
            textField: {
              size: "small",
              helperText: errorMessage,
              sx: {
                "& .MuiFormHelperText-root": {
                  fontSize: "0.8rem",
                  marginLeft: 0,
                  marginRight: 0,
                  maxWidth: 180,
                },
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  maxWidth: 180,
                  color: FILTER_TEXT_COLOR,
                  backgroundColor: "#fff",
                  "& fieldset": {
                    borderColor: FILTERS_BORDER_COLOR,
                  },
                  "&:hover": {
                    backgroundColor: BUTTON_HOVER,
                    "& fieldset": {
                      borderColor: BUTTON_HOVER_BORDER_COLOR,
                    },
                  },
                },
              },
            },
          }}
          maxDate={maxDate}
          onChange={onChange}
        />
      </Box>
    </LocalizationProvider>
  );
}

MonthPicker.propTypes = {
  onChange: propTypes.func,
};
