import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ORDER_STATUS_OPTIONS } from "utils/constants/orderStatusOptions";

function FilterByStatus() {
  const navigate = useNavigate();

  return (
    <Autocomplete
      sx={{ minWidth: 170, maxWidth: 180, backgroundColor: "#fff" }}
      size="small"
      noOptionsText="No Options Available"
      onChange={(_, newValue) => {
        newValue
          ? navigate(`/orders/full-orders?status=${newValue.toLowerCase()}`)
          : navigate(`/orders/full-orders`);
      }}
      options={ORDER_STATUS_OPTIONS.map((option) => option.label)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Status"
          sx={{
            "& .MuiInputLabel-root": { fontSize: "1.031rem" },
            "& .MuiInputBase-root": { height: 44 },
          }}
        />
      )}
    />
  );
}

export default memo(FilterByStatus);
