import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EditReturns from "components/Returns/EditReturns";
import ReturnStatusTabs from "components/Returns/ReturnStatusTabs";
import ReturnsDataGrid from "components/Returns/ReturnsDataGrid";
import ReturnsFilters from "components/Returns/ReturnsFilters";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { store } from "store/configureStore";
import { getReturnedProductById } from "store/slices/returnsSlice";

function Returns({ edit }) {
  if (edit) {
    return <EditReturns />;
  }
  return (
    <Box py={2}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          ORDERS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
      >
        Returns
      </Typography>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <ReturnsFilters />
        <ReturnStatusTabs />
      </Box>
      <ReturnsDataGrid />
    </Box>
  );
}

Returns.propTypes = {
  edit: PropTypes.bool,
};

Returns.defaultProps = {
  edit: false,
};

export default Returns;

export function loadReturns({ params }) {
  return store.dispatch(getReturnedProductById(params.id));
}
