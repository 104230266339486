import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useSelector } from "react-redux";
import OrderDetail from "./OrderDetail";
import ProductDetail from "./ProductDetail";

function Summary() {
  const packages = useSelector((state) => state.cart.packageItems ?? []);

  return (
    <Box variant="container">
      <Typography
        variant="h3"
        color="primary"
        fontSize={{ xs: "0.75rem", sm: "1rem", md: "2rem" }}
        fontWeight="600"
        marginBottom="20px"
      >
        Order Summary
      </Typography>
      <Box display="flex">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Card
              sx={{
                borderRadius: 1.5,
                height: "auto",
              }}
            >
              {packages.map((_package, i) => (
                <ProductDetail packageDetail={_package ?? []} key={i} />
              ))}
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card
              sx={{
                borderRadius: 1.5,
                height: "350px",
              }}
            >
              <OrderDetail />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default memo(Summary);
