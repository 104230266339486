import Autocomplete from "@mui/material/Autocomplete";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import _ from "lodash";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import useDebounce from "utils/hooks/useDebounceValue";
import useQueryParams from "utils/hooks/useQueryParams";

function FormSearch({
  formik,
  searchKeyParam,
  searchKeyParamValue,
  searchQueryParam,
}) {
  const params = useQueryParams();
  const navigate = useNavigate();

  const { results } = useSelector((state) => state?.products);
  const [searchValue, setSearchValue] = useState("");
  const deferredValue = useDebounce(searchValue, 700);
  const _params = useMemo(() => {
    let _params = { ...params };
    if (deferredValue) {
      if (searchKeyParam) _params[searchKeyParam] = searchKeyParamValue;
      _params[searchQueryParam] = deferredValue;
    } else {
      _params = _.omit(
        params,
        searchKeyParam ? [searchKeyParam, searchQueryParam] : [searchQueryParam]
      );
    }
    return _params;
  }, [
    deferredValue,
    params,
    searchKeyParam,
    searchKeyParamValue,
    searchQueryParam,
  ]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate({
        search: createSearchParams(_params).toString(),
      });
    }, 100);
    return () => clearTimeout(timeout);
  }, [_params, deferredValue, navigate, params]);

  const handleChange = useCallback((value) => {
    return setSearchValue(value.trim());
  }, []);

  return (
    <>
      <Autocomplete
        options={results
          .filter((res) => res.active === true)
          .filter((res) => res.quantity >= 50)}
        name="productId"
        onChange={(_, option) => {
          formik.setFieldValue("productId", option.id);
          formik.setFieldValue("price", option.price);
          formik.setFieldValue("quantity", option.quantity);
        }}
        onBlur={formik.handleBlur}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option ? option.productName : "")}
        renderInput={(params) => (
          <StyledTextField
            fullWidth
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            {...params}
            label="Product name"
            error={Boolean(formik.touched.productId && formik.errors.productId)}
            helperText={
              formik.touched.productId && !!formik.errors.productId
                ? formik.errors.productId
                : "Enter a descriptive name to help customer find your product"
            }
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.productName}
          </li>
        )}
      />
    </>
  );
}

export default memo(FormSearch);

FormSearch.propTypes = {
  formik: PropTypes.object.isRequired,
  searchKeyParam: PropTypes.string,
  searchKeyParamValue: PropTypes.string,
  searchQueryParam: PropTypes.string.isRequired,
};
