import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStores } from "store/slices/filtersSlice";
import { FILTERS_BACKGROUND_COLOR, FILTER_TEXT_COLOR } from "styles/colors";
import useDebounce from "utils/hooks/useDebounceValue";

function FilterByStore() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { stores, loading } = useSelector((state) => state.filters);
  const [inputValue, setInputValue] = useState("");

  const deferredValue = useDebounce(inputValue, 500);

  useEffect(() => {
    if (deferredValue.length < 3) return;
    dispatch(getStores({ name: "brandName", value: deferredValue }));
  }, [dispatch, deferredValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputValue === "") dispatch(getStores());
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, inputValue]);

  return (
    <Autocomplete
      sx={{ minWidth: 170, maxWidth: 180 }}
      size="small"
      getOptionLabel={(stores) => stores.brandName}
      isOptionEqualToValue={(option, value) =>
        option.brandName === value.brandName
      }
      options={stores}
      noOptionsText="No Options Available"
      onInputChange={(_, value) => setInputValue(value)}
      onChange={(_, newValue) => {
        newValue?.seller
          ? navigate(`/videos?userId=${newValue.seller}`)
          : navigate(`/videos`);
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Store"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "1.063rem",
              height: 44,
            },
            "& .MuiInputBase-root": {
              height: 44,
              backgroundColor: FILTERS_BACKGROUND_COLOR,
              cursor: "pointer",
            },
            input: {
              color: FILTER_TEXT_COLOR,
              "&::placeholder": {
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default memo(FilterByStore);
