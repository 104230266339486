import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  getProductAfterVariantUpdate,
} from "store/slices/productsSlice";
import { setOpenVariantForm } from "store/slices/variantsSlice";
import VariantForm from "./VariantForm";

function EditVariant({ formik }) {
  const dispatch = useDispatch();
  // const [openIndex, setOpenIndex] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [variants, setVariants] = useState([]);
  const product = useSelector((state) => state.products.product);
  const { openVariantForm } = useSelector((state) => state.variants);
  useEffect(() => {
    if (product && product.variants) {
      setVariants(product.variants);
    }
  }, [product]);

  const handleClick = (index, variant) => {
    if (openVariantForm === index) {
      dispatch(setOpenVariantForm(null));
    } else {
      dispatch(setOpenVariantForm(index));
    }
    const array = Object.entries(variant).map(([name, value]) => ({
      name: name.charAt(0).toUpperCase() + name.slice(1),
      value,
    }));
    setSelectedAttributes(array);
  };

  const handleDelete = useCallback(
    (variant) => {
      dispatch(deleteProduct(variant?.id))
        .unwrap()
        .then(() => {
          dispatch(getProductAfterVariantUpdate(product?.id));
        });
    },
    [dispatch, product?.id]
  );

  return (
    <Box>
      {variants?.map((variant, index) => (
        <Box key={index} sx={{ marginBottom: "16px" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            gap={2}
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ cursor: "pointer" }}
              width="100%"
              onClick={() => handleClick(index, variant)}
            >
              <Box display="flex" gap={2}>
                {variant?.selectedAttributes?.map((item, index) => (
                  <Typography key={index} sx={{ textTransform: "capitalize" }}>
                    {`${item.name}: ${item.value}`}
                  </Typography>
                ))}
              </Box>
              <Box>
                {openVariantForm === index ? <ExpandLess /> : <ExpandMore />}
              </Box>
            </Box>
            <Box display="flex">
              <Tooltip title="Edit">
                <IconButton
                  size="small"
                  onClick={() => handleClick(index, variant)}
                >
                  <img src={WritingIcon} alt="edit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton size="small" onClick={() => handleDelete(variant)}>
                  <img src={deleteIcon} alt="delete" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Collapse in={openVariantForm === index} timeout="auto">
              <VariantForm
                formik={formik}
                variant={variant}
                selectedAttributes={selectedAttributes}
              />
            </Collapse>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default memo(EditVariant);

EditVariant.propTypes = {
  formik: PropTypes.object.isRequired,
};
EditVariant.defaultProps = {
  formik: {},
};
