import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import deleteIcon from "assets/icons/deleteIcon.svg";
import WritingIcon from "assets/icons/WritingIcon.svg";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteStore, getStores } from "store/slices/storesSlice";

import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import useQueryParams from "utils/hooks/useQueryParams";

function StoreDataGrid() {
  const params = useQueryParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { results, loading, totalPages } = useSelector((state) => state.stores);

  useEffect(() => {
    dispatch(getStores(params));
  }, [dispatch, params]);

  const handleClickOpen = useCallback(
    (storeId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(storeId));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 40,
        flex: 0.2,
        filterable: false,
      },
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 0.9,
        renderCell: (params) => (
          <Typography>{params?.row?.brandName}</Typography>
        ),
      },
      {
        field: "city",
        headerName: "City",
        minWidth: 100,
        flex: 0.5,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.city}
          </Typography>
        ),
      },
      {
        field: "address",
        headerName: "Address",
        minWidth: 150,
        flex: 1.5,
        renderCell: (params) => (
          <Typography
            textTransform="capitalize"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {params?.row?.address}
          </Typography>
        ),
      },
      {
        field: "date",
        headerName: "Date",
        minWidth: 30,
        flex: 0.5,
        renderCell: (params) => (
          <Typography>{params?.row?.createdAt.substring(0, 10)}</Typography>
        ),
      },

      {
        field: "market",
        headerName: "Market",
        minWidth: 30,
        flex: 0.5,
        renderCell: (params) => (
          <Typography>
            {params?.row?.market && params?.row?.market?.name}
          </Typography>
        ),
      },

      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 30,
        flex: 0.5,
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <IconButton
              variant="text"
              className="button-styles"
              onClick={() => handleClickOpen(params?.id)}
            >
              <img src={deleteIcon} alt="delete" className="icon-size" />
            </IconButton>
            <IconButton
              variant="text"
              className="button-styles"
              onClick={() => navigate(`update/${params.id}`)}
            >
              <img src={WritingIcon} alt="edit" className="icon-size" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [handleClickOpen, navigate]
  );

  return (
    <>
      <StyledDataGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteStore} />
    </>
  );
}

export default memo(StoreDataGrid);

const actionStyles = {
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
