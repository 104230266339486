import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CategoryDataGrid from "components/Category/CategoryDataGrid";
import CategoryForm from "components/Category/CategoryForm";
import CategoryHeaderComponents from "components/Category/CategoryHeaderComponents/index";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { store } from "store/configureStore";
import {
  getCategories,
  getCategory,
  setCategory,
} from "store/slices/categoriesSlice";

function Categories({ create, edit, addSubCategory }) {
  if (create || edit || addSubCategory) {
    return <CategoryForm />;
  }

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          PRODUCTS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
      >
        Categories
      </Typography>
      <CategoryHeaderComponents />
      <CategoryDataGrid />
    </Box>
  );
}

Categories.propTypes = {
  create: PropTypes.bool,
  edit: PropTypes.bool,
  addSubCategory: PropTypes.bool,
};

Categories.defaultProps = {
  create: false,
  edit: false,
  addSubCategory: false,
};

export default Categories;

export function loadCategories({ params }) {
  let data = {};
  if (params.lvl2 || params.lvl3) {
    data = {
      id: params.lvl3 ?? params.lvl2,
    };
  }
  return store.dispatch(getCategories(data));
}
export function loadCategory({ params }) {
  let category = store
    .getState()
    .categories.results.find((c) => c.id === params.id);

  if (category) {
    return store.dispatch(setCategory(category));
  } else {
    return store.dispatch(getCategory(params.id));
  }
}
