//Product variant option categories
export const colorOptionsCategories =
  process.env.REACT_APP_ENV === "production"
    ? [
        "603e0dcc7e4d2a1f859d8a63", // Footwear
        "6038dcbe7e4d2a1f859d8253", // Fashion
        "60ec3fdfdbae10002e984274", // Consumer Electronics --> Smart Phone and Tablets
      ]
    : [
        "647ee7b89c9a0126548ea9d0", // Footwear
        "647ee7a69c9a0126548ea776", // Fashion
        "647ee8219c9a0126548eb158", // Consumer Electronics --> Smart Phone and Tablets
      ];

export const sizeOptionsCategories =
  process.env.REACT_APP_ENV === "production"
    ? [
        "603e0dcc7e4d2a1f859d8a63", // Footwear
        "6038dcbe7e4d2a1f859d8253", // Fashion
        "604a18e905ec9502c9f8d4b7", // Consumer Electronics --> Home Appliances --> LED & Televisions
        "610ce6f01f0eb1002eb80872", // Consumer Electronics --> Home Appliances --> LED & TV Accessories
      ]
    : [
        "647ee7b89c9a0126548ea9d0", // Footwear
        "647ee7a69c9a0126548ea776", // Fashion
        "647ee7d49c9a0126548eabdf", // Consumer Electronics --> Home Appliances --> LED & Televisions
        "647ee7d49c9a0126548eabe0", // Consumer Electronics --> Home Appliances --> LED & TV Accessories
      ];

export const weightOptionsCategories =
  process.env.REACT_APP_ENV === "production"
    ? [
        "605064dd48fcad02d8aad2f1", // Pet --> Foods
        "620e024c09dbff02e9bc21dc", // BG Packaging
        "60c875096f0fe647a5476f0f", // Groceries
      ]
    : [
        "605064dd48fcad02d8aad2f1", // Pet --> Foods
        "620e024c09dbff02e9bc21dc", // BG Packaging
        "647ee7bc9c9a0126548eaa21", // Groceries
      ];

export const RAMOptionsCategories =
  process.env.REACT_APP_ENV === "production"
    ? [
        "60ec3fdfdbae10002e984274", // Consumer Electronics --> Smart Phone and Tablets
      ]
    : [
        "647ee8219c9a0126548eb158", // Consumer Electronics --> Smart Phone and Tablets
      ];

export const ROMOptionsCategories =
  process.env.REACT_APP_ENV === "production"
    ? [
        "60ec3fdfdbae10002e984274", // Consumer Electronics --> Smart Phone and Tablets
      ]
    : [
        "647ee8219c9a0126548eb158", // Consumer Electronics --> Smart Phone and Tablets
      ];

export const LCDSizeOptionsCategories =
  process.env.REACT_APP_ENV === "production"
    ? [
        "604a18e905ec9502c9f8d4b7", // Consumer Electronics --> Home Appliances --> LED & Televisions
        "610ce6f01f0eb1002eb80872", // Consumer Electronics --> Home Appliances --> LED & TV Accessories
      ]
    : [
        "647ee7d49c9a0126548eabdf", // Consumer Electronics --> Home Appliances --> LED & Televisions
        "647ee7d49c9a0126548eabe0", // Consumer Electronics --> Home Appliances --> LED & TV Accessories
      ];

// Product Options Constants
export const productOptions = [
  {
    label: "Color",
    value: "color",
    categories: colorOptionsCategories,
  },
  { label: "Weight", value: "weight", categories: weightOptionsCategories },
  { label: "Size", value: "size", categories: sizeOptionsCategories },
  { label: "RAM", value: "ram", categories: RAMOptionsCategories },
  { label: "ROM", value: "rom", categories: ROMOptionsCategories },
];

export const colorOptions = [
  { label: "Black", value: "black" },
  { label: "Jet Black", value: "jet_black" },
  { label: "Dark Grey", value: "dark_grey" },
  { label: "Grey", value: "grey" },
  { label: "Yellow", value: "yellow" },
  { label: "Red", value: "red" },
  { label: "Orange", value: "orange" },
  { label: "Purple", value: "purple" },
  { label: "Green", value: "green" },
  { label: "Sky Blue", value: "sky_blue" },
  { label: "Blue", value: "blue" },
  { label: "White", value: "white" },
  { label: "Off White", value: "off_white" },
  { label: "Brown", value: "brown" },
  { label: "Beige", value: "beige" },
  { label: "Indigo", value: "indigo" },
  { label: "Violet", value: "violet" },
  { label: "Maroon", value: "maroon" },
  { label: "Camel", value: "camel" },
  { label: "Moss", value: "moss" },
  { label: "Golden", value: "golden" },
  { label: "Burgundy", value: "burgundy" },
  { label: "Pink", value: "pink" },
  { label: "Baby Pink", value: "baby_pink" },
  { label: "Shocking Pink", value: "shocking_pink" },
  { label: "Tea Pink", value: "tea_pink" },
  { label: "Silver", value: "silver" },
  { label: "Tan", value: "tan" },
  { label: "Teal", value: "teal" },
  { label: "Mustard", value: "mustard" },
  { label: "Khaki", value: "khaki" },
  { label: "Peach", value: "peach" },
  { label: "Aqua/Ferozi", value: "aqua_ferozi" },
  { label: "Orchid", value: "orchid" },
  { label: "Magenta", value: "magenta" },
  { label: "Hot Pink", value: "hot_pink" },
  { label: "Light Blue", value: "light_blue" },
  { label: "Dark Blue", value: "dark_blue" },
  { label: "Turquoise", value: "turquoise" },
  { label: "Light Green", value: "light_green" },
  { label: "Crimson", value: "crimson" },
  { label: "Lavender", value: "lavender" },
  { label: "Royal Blue", value: "royal_blue" },
  { label: "Chocolate", value: "chocolate" },
  { label: "Coral", value: "coral" },
  { label: "Plum", value: "plum" },
  { label: "Cyan", value: "cyan" },
  { label: "Transparent", value: "transparent" },
  { label: "Olive", value: "olive" },
  { label: "Skin", value: "skin" },
];

export const weightOptions = [
  { label: "250 g", value: "0.25" },
  { label: "500 g", value: "0.5" },
  { label: "1 kg", value: "1" },
  { label: "1.5 kg", value: "1.5" },
  { label: "2 kg", value: "2" },
  { label: "2.5 kg", value: "2.5" },
  { label: "3 kg", value: "3" },
  { label: "3.5 kg", value: "3.5" },
  { label: "4 kg", value: "4" },
  { label: "4.5 kg", value: "4.5" },
  { label: "5 kg", value: "5" },
  { label: "8 kg", value: "8" },
  { label: "10 kg", value: "10" },
  { label: "15 kg", value: "15" },
  { label: "20 kg", value: "20" },
  { label: "25 kg", value: "25" },
  { label: "30 kg", value: "30" },
  { label: "35 kg", value: "35" },
  { label: "40 kg", value: "40" },
];

export const fashionSizeOptions = [
  { label: "Small", value: "small" },
  { label: "Medium", value: "medium" },
  { label: "Large", value: "large" },
  { label: "Extra Large", value: "xl" },
  { label: "XX Large", value: "xxl" },
  { label: "XXX Large", value: "xxxl" },
  { label: "32", value: "32" },
  { label: "34", value: "34" },
  { label: "36", value: "36" },
  { label: "38", value: "38" },
  { label: "40", value: "40" },
  { label: "42", value: "42" },
  { label: "44", value: "44" },
  { label: "46", value: "46" },
];

export const footwearSizeOptions = [
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "38", value: "38" },
  { label: "39", value: "39" },
  { label: "40", value: "40" },
  { label: "41", value: "41" },
  { label: "42", value: "42" },
  { label: "43", value: "43" },
  { label: "44", value: "44" },
];

export const LCDSizeOptions = [
  { label: "32 inch", value: "32" },
  { label: "40 inch", value: "40" },
  { label: "42 inch", value: "42" },
  { label: "43 inch", value: "43" },
  { label: "48 inch", value: "48" },
  { label: "50 inch", value: "50" },
  { label: "55 inch", value: "55" },
  { label: "60 inch", value: "60" },
  { label: "65 inch", value: "65" },
  { label: "70 inch", value: "70" },
  { label: "75 inch", value: "75" },
  { label: "80 inch", value: "80" },
  { label: "85 inch", value: "85" },
];

export const RAMSizeOptions = [
  { label: "1 GB", value: "1" },
  { label: "2 GB", value: "2" },
  { label: "3 GB", value: "3" },
  { label: "4 GB", value: "4" },
  { label: "6 GB", value: "6" },
  { label: "8 GB", value: "8" },
  { label: "12 GB", value: "12" },
  { label: "16 GB", value: "16" },
  { label: "32 GB", value: "32" },
];

export const ROMSizeOptions = [
  { label: "8 GB", value: "8" },
  { label: "16 GB", value: "16" },
  { label: "32 GB", value: "32" },
  { label: "64 GB", value: "64" },
  { label: "128 GB", value: "128" },
  { label: "256 GB", value: "256" },
  { label: "512 GB", value: "512" },
  { label: "1 TB", value: "1024" },
  { label: "2 TB", value: "2048" },
];

// Delivery Section
export const volumeUnits = [
  { label: "None", value: "none" },
  { label: "cm", value: "cm" },
  { label: "m", value: "m" },
  { label: "inch", value: "inch" },
  { label: "ft", value: "ft" },
];

export const weightUnits = ["g", "kg"];

// Service Section
export const warrantyTypes = [
  { label: "None", value: "none" },
  { label: "Seller", value: "seller" },
  { label: "Brand", value: "brand" },
];

export const warrantyPeriodUnits = [
  // { label: "None", value: "none" },
  { label: "Day", value: "day" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

export const VouchersOptions = [
  { label: "Product", value: "product" },
  { label: "Order", value: "order" },
];
