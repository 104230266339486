import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductGalleryImagePlaceholder from "assets/icons/ProductGalleryImagePlaceholder.svg";
import CurrencyFormatter from "components/CurrencyFormatter";
import { memo } from "react";
import { useSelector } from "react-redux";

function ReturnProductsDetail() {
  const returnedData = useSelector((state) => state.returns.refundData);

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  return (
    <Card
      sx={{
        borderRadius: 1.5,
        height: "20",
      }}
    >
      <Grid
        container
        spacing={2}
        pl={2}
        sx={{
          my: 1,
          fontSize: { xs: "0.5rem", md: "1rem" },
          fontWeight: "bold",
        }}
      >
        <Grid item lg={3} xs={4}>
          Image/Name/Details
        </Grid>
        <Grid item lg={2} xs={2} sx={flexGridStyles}>
          Amount
        </Grid>
        <Grid item lg={3} xs={3} sx={flexGridStyles}>
          Reason
        </Grid>
        <Grid item lg={3} xs={3} sx={flexGridStyles}>
          Note
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        pl={2}
        sx={{
          my: 1,
          fontSize: { xs: "0.5rem", md: "1rem" },
        }}
      >
        <Grid item lg={3} xs={4}>
          <Box display="flex" alignItems="center">
            <Box>
              <img
                src={
                  returnedData?.refundProduct?.product
                    ? returnedData?.refundProduct?.product?.mainImage
                    : ProductGalleryImagePlaceholder
                }
                height={
                  isTablet
                    ? "70px"
                    : isLaptop
                    ? "80px"
                    : isLed
                    ? "85px"
                    : "113px"
                }
                width={
                  isTablet
                    ? "70px"
                    : isLaptop
                    ? "80px"
                    : isLed
                    ? "85px"
                    : "113px"
                }
                style={{
                  borderRadius: "10px",
                  marginTop: 4,
                  objectFit: "contain",
                }}
                alt={"product"}
              />
            </Box>
            <Box px="10px" sx={{ wordBreak: "break-all" }}>
              <Typography
                sx={{
                  textTransform: "capitalize",
                  color: "#000000",
                  overflow: "auto",
                  textOverflow: " ellipsis",
                  lineClamp: "2",
                  WebkitLineClamp: "2",
                }}
              >
                {returnedData?.refundProduct?.product?.productName ?? ""}
              </Typography>
              <Box display="flex">
                Quantity:
                <Typography paddingLeft="5px">
                  {returnedData?.refundProduct?.quantity ?? 0}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} sx={flexGridStyles}>
          <CurrencyFormatter number={returnedData?.refundTotalAmount ?? 0} />
        </Grid>
        <Grid item lg={3} xs={3} sx={flexGridStyles}>
          <Tooltip title={returnedData?.refundReason}>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
            >
              {returnedData?.refundReason ?? "N/A"}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item lg={3} xs={3} sx={flexGridStyles}>
          <Tooltip title={returnedData?.refundNote}>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
            >
              {returnedData?.refundNote ?? "N/A"}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    </Card>
  );
}

export default memo(ReturnProductsDetail);

const flexGridStyles = {
  display: {
    xs: "block",
    md: "flex",
  },
  textAlign: "-webkit-center",
  top: "0px",
  bottom: "0px",
  margin: "auto",
  flexDirection: "row-reverse",
  justifyContent: "center ",
};
