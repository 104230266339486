import ProductGalleryImagePlaceholder from "assets/icons/ProductGalleryImagePlaceholder.svg";
import { memo } from "react";

function GalleryImagePlaceholder() {
  return (
    <img
      src={ProductGalleryImagePlaceholder}
      alt="Product Gallery Placeholder"
      height="auto"
      width="150px"
    />
  );
}

export default memo(GalleryImagePlaceholder);
