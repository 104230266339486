import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const getOrders = createAsyncThunk(
  "orders/getOrders",
  async (data, { getState, rejectWithValue }) => {
    const { userRole } = getState().auth;
    const { page, limit } = getState().fullOrders;
    const url = userRole === "admin" ? "/order/admin" : "/order/seller";
    try {
      const response = await http.get(url, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      if (responseData?.results) {
        responseData.results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getOrder = createAsyncThunk(
  "Orders/getOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get(`/order/${data.orderId}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateOrderStatus = createAsyncThunk(
  "Orders/updateOrderStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/orderStatus`, data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    loading: true,
    orderLoading: false,
    results: [],
    order: null,
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: null,
    orderError: null,
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.orderLoading = false;
      state.results = [];
      state.order = null;
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = null;
      state.orderError = null;
    },
    resetOrderState: (state) => {
      state.order = null;
      state.orderLoading = true;
      state.orderError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getOrder.pending, (state) => {
        state.orderLoading = true;
        state.orderError = false;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.orderLoading = false;
        state.order = action.payload;
        state.orderError = false;
      })
      .addCase(getOrder.rejected, (state) => {
        state.orderLoading = false;
        state.orderError = true;
      })
      .addCase(updateOrderStatus.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateOrderStatus.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(updateOrderStatus.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetOrderState } = ordersSlice.actions;

// Exporting default reducer
export default ordersSlice.reducer;
