import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NotificationForm from "components/Notifications/NotificationForm";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo } from "react";

const Notifications = () => {
  // if (create || update) {
  //   return <NotificationForm />;
  // }
  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          NOTIFICATIONS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      {/* <NotificationActions /> */}
      {/* <NotificationDataGrid /> */}
      <NotificationForm />
    </Box>
  );
};

export default memo(Notifications);
