import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "assets/icons/HomeIcon.svg";
import { memo } from "react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { PRIMARY } from "styles/colors";

function BannersCustomBreadCrumb() {
  const params = useParams();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((path) => path);
  let bannersArray = [];
  if (pathnames.includes("banner-set")) {
    bannersArray.push({ name: "Banner Set", route: "/content/banner-set" });
  }
  if (params.device) {
    bannersArray.push({
      name: "Banners",
      route: `/content/banner-set/${params?.id}/${params?.device}/banners`,
    });
  }
  if (pathnames.includes("create")) {
    bannersArray.push({ name: "Create" });
  }
  if (pathnames.includes("update")) {
    bannersArray.push({ name: "Update" });
  }

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" sx={{ color: "#000" }} />}
    >
      <Link color="inherit" component={RouterLink} to="/">
        <img src={HomeIcon} alt="home" />
      </Link>
      {bannersArray.map((link, index) => {
        if (index === bannersArray.length - 1) {
          return (
            <span key={link} style={{ color: PRIMARY }}>
              {link?.name}
            </span>
          );
        } else {
          return (
            <Link
              key={link}
              color={PRIMARY}
              component={RouterLink}
              to={link?.route}
              sx={{
                margin: " 0 -.25rem",
                padding: "0 .25rem",
                "&:hover": {
                  backgroundColor: "#068ECF",
                  color: "#fff",
                  textDecoration: "none",
                },
              }}
            >
              {link?.name}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
}

export default memo(BannersCustomBreadCrumb);
