import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import placeholderImg from "assets/images/imagePlaceholder.png";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledGrid from "components/StyledGrid";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteCategory } from "store/slices/categoriesSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import PlusIcon from "../../assets/icons/PlusIcon.png";
import WritingIcon from "../../assets/icons/WritingIcon.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";
import eyeIcon from "../../assets/icons/eyeIcon.svg";

function CategoryDataGrid() {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { results, loading, totalPages } = useSelector(
    (state) => state.categories
  );

  const viewSubCategories = useCallback(
    (id) => {
      navigate(id);
    },
    [navigate]
  );

  const addSubCategory = useCallback(
    (id) => {
      navigate(`/products/categories/${id}/subcategory`);
    },
    [navigate]
  );
  const updateCategory = useCallback(
    (id) => {
      navigate(`/products/categories/update/${id}`);
    },
    [navigate]
  );
  const handleClickOpen = useCallback(
    (categoryId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(categoryId));
    },
    [dispatch]
  );
  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 40,
        flex: 0.2,
      },
      {
        field: "image",
        headerName: "Image",
        minWidth: 100,
        flex: 0.6,
        renderCell: (params) => (
          <Box>
            <img
              src={params.row.mainImage ? params.row.mainImage : placeholderImg}
              height={
                isTablet
                  ? "80px"
                  : isLaptop
                  ? "90px"
                  : isDesktop
                  ? "95px"
                  : "113px"
              }
              width={
                isTablet
                  ? "80px"
                  : isLaptop
                  ? "90px"
                  : isDesktop
                  ? "95px"
                  : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 4,
                objectFit: "contain",
              }}
              alt={params.row.name}
            />
          </Box>
        ),
      },
      {
        field: "details",
        headerName: "Name/Details",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography>{params.row.name}</Typography>
          </Box>
        ),
      },
      {
        field: "commissions",
        headerName: "Commissions",
        minWidth: 100,
        flex: 0.6,
        renderCell: (params) => (
          <Box>
            <Typography>
              {params.row.commission ? params.row.commission + "%" : 0 + "%"}
            </Typography>
          </Box>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 130,
        flex: 1,
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            {params.row.subCategories.length > 0 && (
              <Tooltip title="View Sub Category">
                <Button
                  variant="text"
                  className="button-styles"
                  onClick={() => viewSubCategories(params.id)}
                >
                  <img src={eyeIcon} alt="view" className="icon-size" />
                </Button>
              </Tooltip>
            )}
            <Tooltip title="Update">
              <Button
                variant="text"
                className="button-styles"
                onClick={() => updateCategory(params.id)}
              >
                <img src={WritingIcon} alt="delete" className="icon-size" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                variant="text"
                sx={{
                  minWidth: "20px",
                }}
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="edit" className="icon-size" />
              </Button>
            </Tooltip>
            <Tooltip title="Add Sub Category">
              <Button
                variant="text"
                className="button-styles"
                sx={{
                  borderRadius: "50%",
                }}
                onClick={() => addSubCategory(params.id)}
              >
                <img src={PlusIcon} alt="view" className="icon-size" />
              </Button>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [
      isTablet,
      isLaptop,
      isDesktop,
      viewSubCategories,
      updateCategory,
      handleClickOpen,
      addSubCategory,
    ]
  );

  return (
    <>
      <StyledGrid
        columns={columns}
        loading={loading}
        rows={results}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteCategory} />
    </>
  );
}

export default memo(CategoryDataGrid);

const actionStyles = {
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
