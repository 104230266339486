import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updatePhoneNumber } from "store/slices/authSlice";
import formatPhoneNumber from "utils/helpers/formatPhoneNumber";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import StyledTextField from "./StyledTextField";

function AddPhoneNumberForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const data = {
        phone: formatPhoneNumber(values?.phone),
      };
      dispatch(updatePhoneNumber(data))
        .unwrap()
        .then(() => {
          toast.success("Phone Number updated successfully");
          navigate("/");
        })
        .catch((error) => {
          if (error === "Phone number already taken")
            return setErrors({ phone: "Phone Number already taken" });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <Container>
      <Stack
        direction="column"
        alignContent="center"
        justifyContent="center"
        minHeight="100vh"
        maxWidth={{ sm: "250px", md: "450px", lg: "400px", xl: "535px" }}
        m="auto"
        py={2}
        spacing={{ xs: 1.75, md: 2.4, lg: 3, xl: 6 }}
      >
        <Box>
          <Typography
            variant="h3"
            component="h2"
            fontWeight="bold"
            fontSize={{ xs: "1rem", sm: "1.5rem", md: "2.75rem", lg: "4rem" }}
          >
            Phone Number
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ lg: "1rem", xl: "1.33rem" }}
          >
            Please add your phone number to continue
          </Typography>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <Stack direction="column" spacing={1} alignItems="flex-end" pb={4}>
            <StyledTextField
              fullWidth
              label="Phone Number"
              type="tel"
              size={isSmall ? "small" : "large"}
              placeholder="+92"
              {...formik.getFieldProps("phone")}
              error={!!formik.touched.phone && !!formik.errors.phone}
              helperText={formik.touched.phone && formik.errors.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphoneIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              Add Phone Number
            </Button>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}

export default memo(AddPhoneNumberForm);

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Required*")
    .matches(/^(?:\+92|0)(3)([0-9]{9})$/gm, "Invalid phone number"),
});
