import { memo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import USER_ROLE from "utils/constants/userRole";

function AdminRoute() {
  const token = useSelector((state) => state.auth?.token ?? "");
  const userRole = useSelector((state) => state.auth?.userRole ?? "");

  if (token && userRole === USER_ROLE.ADMIN) {
    return <Outlet />;
  }

  return <Navigate to="/login" />;
}

export default memo(AdminRoute);
