import { ToggleButtonGroup } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import styled from "@mui/material/styles/styled";
import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTranslatedData,
  resetLanguageState,
} from "store/slices/languageTranslatorSlice";
import { setLanguage } from "store/slices/uiSlice";

function LanguageToggleButtons(data) {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.UI?.language ?? "En");
  const StyledToggleButton = styled(ToggleButton)`
    && {
      border-radius: 5px;
      margin: 0;
      height: 40px;
      padding: 0 0.5rem;
      color: ${({ theme, selected }) =>
        selected ? theme.palette.common.white : theme.palette.text.primary};
      background-color: ${({ theme, selected }) =>
        selected ? theme.palette.primary.main : "transparent"};

      &:hover {
        background-color: ${({ theme, selected }) =>
          selected ? theme.palette.primary.dark : theme.palette.grey[200]};
      }
    }
  `;

  const handleChange = useCallback(
    (event, newSelectedLanguage) => {
      dispatch(setLanguage(newSelectedLanguage));
    },
    [dispatch]
  );

  useEffect(() => {
    if (data?.data) {
      const timeOut = setTimeout(() => {
        dispatch(getTranslatedData(data.data));
      }, 500);
      return () => {
        dispatch(resetLanguageState());
        clearTimeout(timeOut);
      };
    }
  }, [data, dispatch]);

  return (
    <ToggleButtonGroup
      value={selectedLanguage}
      exclusive
      onChange={handleChange}
    >
      <StyledToggleButton value="En">En</StyledToggleButton>
      <StyledToggleButton value="Ar">Ar</StyledToggleButton>
    </ToggleButtonGroup>
  );
}

export default memo(LanguageToggleButtons);
