import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductAddImagePlaceholder from "assets/icons/ProductAddImagePlaceholder.svg";
import { memo } from "react";
import { useSelector } from "react-redux";

function ReturnedImages() {
  const returnedOrderImages = useSelector(
    (state) => state.returns.refundData?.images ?? []
  );

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  return (
    <Card sx={{ p: 2 }}>
      <Grid container spacing={1}>
        {returnedOrderImages.map((image, index) => (
          <Grid item key={index} xs={6} lg={4} sm={6} md={6}>
            <img
              src={image ? image : ProductAddImagePlaceholder}
              alt="Returned Main Media"
              height="auto"
              width={isSmall ? "70px" : isLarge ? "180px" : "250px"}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}

export default memo(ReturnedImages);
