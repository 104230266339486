import Button from "@mui/material/Button";
import { memo, useCallback } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch, useSelector } from "react-redux";
import {
  getFacebookBusinesses,
  getFacebookPages,
  setFacebookResponse,
} from "store/slices/storesSlice";
import { setIsFacebookCatalogDialogOpen } from "store/slices/uiSlice";
import { toast } from "utils/hooks/useToast";
import FacebookCatalogDialog from "./FacebookCatalogDialog";

function FacebookIntegration() {
  const dispatch = useDispatch();
  const facebookResponse = useSelector(
    (state) => state?.stores?.facebookResponse
  );

  const handleFacebookResponse = useCallback(
    (response) => {
      if (response) {
        dispatch(setFacebookResponse(response));
        dispatch(setIsFacebookCatalogDialogOpen(true));
        dispatch(getFacebookPages());
        dispatch(getFacebookBusinesses());
      }
    },
    [dispatch]
  );

  const handleFacebookError = useCallback((error) => {
    console.error(error);
    toast.error(
      error === "facebookNotLoaded"
        ? "Facebook SDK is not loaded. Please try again"
        : "Something went wrong. Please try again"
    );
  }, []);

  const openFacebookIntegrationDialog = useCallback(() => {
    dispatch(setIsFacebookCatalogDialogOpen(true));
    dispatch(getFacebookPages());
    dispatch(getFacebookBusinesses());
  }, [dispatch]);

  return (
    <>
      {facebookResponse ? (
        <Button
          onClick={openFacebookIntegrationDialog}
          className="integrate-facebook"
          color="primary"
          variant="contained"
          size="large"
        >
          Add Facebook Catalog
        </Button>
      ) : (
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          callback={handleFacebookResponse}
          onFailure={handleFacebookError}
          scope="user_videos, user_friends, email, read_insights, publish_video, openid, catalog_management, pages_show_list, ads_management, business_management, instagram_basic, instagram_manage_insights, instagram_content_publish, publish_to_groups, groups_access_member_info, pages_read_engagement, pages_manage_posts"
          render={({ onClick, isDisabled, isSdkLoaded }) => (
            <Button
              onClick={onClick}
              className="integrate-facebook"
              disabled={!isSdkLoaded || isDisabled}
              color="primary"
              variant="contained"
              size="large"
            >
              Integrate Facebook
            </Button>
          )}
        />
      )}
      <FacebookCatalogDialog />
    </>
  );
}

export default memo(FacebookIntegration);
