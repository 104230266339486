import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { memo } from "react";
import Media from "./Media";

const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const Message = ({ content, isReply, timestamp, multimedia, isMultimedia }) => {
  return (
    <Stack
      width="100%"
      alignItems={isReply ? "flex-start" : "flex-end"}
      my={0.5}
    >
      {isMultimedia ? (
        <Media media={multimedia} isReply={isReply} />
      ) : (
        <Typography
          variant="body1"
          sx={{
            backgroundColor: isReply ? "primary.light" : "secondary.light",
            // color: isReply ? "inherit" : "#fff",
            px: 1,
            py: 0.7,
            borderRadius: 1,
            wordBreak: "break-word",
          }}
        >
          {content}
        </Typography>
      )}
      <Typography
        component="span"
        fontSize="0.75rem"
        mt="2px"
        color="text.secondary"
        px="5px"
      >
        {dayjs(timestamp).format("LLL")}
      </Typography>
    </Stack>
  );
};

Message.propTypes = {
  content: PropTypes.string,
  isMultimedia: PropTypes.bool,
  isReply: PropTypes.bool,
  multimedia: PropTypes.array,
  timestamp: PropTypes.string,
};

export default memo(Message);
