import { memo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import USER_ROLE from "utils/constants/userRole";

function ProtectedRoute() {
  const token = useSelector((state) => state.auth.token);
  const userRole = useSelector((state) => state.auth?.userRole);
  const isEmailVerified = useSelector(
    (state) => state.auth.data?.user?.isEmailVarified
  );
  const phoneNumber = useSelector(
    (state) => state.auth.data?.user?.phone ?? ""
  );
  const isPhoneVerified = useSelector(
    (state) => state.auth.data?.user?.isPhoneVarified
  );
  const isVerified = isEmailVerified && isPhoneVerified;

  if (token && !phoneNumber) {
    return <Navigate to="/phone-number" />;
  }

  if (token && !isVerified) {
    return <Navigate to="/verification" />;
  }
  if (userRole === "") {
    return <Navigate to="/login" />;
  }

  if (
    userRole === USER_ROLE.ADMIN ||
    (userRole === USER_ROLE.REQUESTED_SELLER && isVerified) ||
    (userRole === USER_ROLE.SUPPLIER && isVerified)
  ) {
    return <Outlet />;
  }

  return <Navigate to="/login" />;
}

export default memo(ProtectedRoute);
