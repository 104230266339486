import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import eyeIcon from "assets/icons/eyeIcon.svg";
import placeholderImg from "assets/images/imagePlaceholder.png";
import CurrencyFormatter from "components/CurrencyFormatter";
import StyledDataGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getReturns, resetState } from "store/slices/returnsSlice";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";

export default function ReturnsDataGrid() {
  const dispatch = useDispatch();
  const { results, loading, totalPages } = useSelector(
    (state) => state.returns
  );

  const params = useQueryParams();
  const navigate = useNavigate();

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const fetchData = useCallback(() => {
    dispatch(getReturns(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchData();
    }, 500);
    return () => {
      dispatch(resetState());
      clearTimeout(timeOut);
    };
  }, [dispatch, fetchData]);

  const ViewReturnsRow = useCallback(
    (returnId) => {
      navigate(`update/${returnId}`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 40,
        flex: 0.2,
        filterable: false,
      },
      {
        field: "image",
        headerName: "Image",
        type: "actions",
        minWidth: 100,
        flex: 0.6,
        renderCell: (params) => (
          <Box>
            <img
              src={
                params?.row?.refundProduct?.product?.mainImage
                  ? params?.row?.refundProduct?.product?.mainImage
                  : placeholderImg
              }
              height={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              width={
                isTablet
                  ? "70px"
                  : isLaptop
                  ? "80px"
                  : isDesktop
                  ? "85px"
                  : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 4,
                objectFit: "contain",
              }}
              alt={params?.row?.refundProduct?.product?.productName}
            />
          </Box>
        ),
      },
      {
        field: "productName",
        headerName: "Order ID/Name",
        minWidth: 160,
        flex: 1.6,
        renderCell: (params) => (
          <Box>
            <Typography
              sx={{
                textTransform: "capitalize",
              }}
            >
              {params?.row?.refundProduct?.product?.productName}
            </Typography>
          </Box>
        ),
      },

      {
        field: "seller",
        headerName: "Seller Name",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography
              sx={{
                textTransform: "capitalize",
              }}
            >
              {params?.row?.seller?.fullname}
            </Typography>
          </Box>
        ),
      },
      {
        field: "date",
        headerName: "Date",
        minWidth: 100,
        flex: 0.6,
        type: "dateTime",
        renderCell: (params) => (
          <Box>
            <Typography>
              {dayjs(params?.row?.refundDate).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        type: "actions",
        minWidth: 90,
        flex: 0.9,
        renderCell: (params) => (
          <Chip
            sx={{
              backgroundColor: statusBadges(params?.row?.refundStatus).bgColor,
              color: statusBadges(params?.row?.refundStatus).color,
              borderRadius: "5px",
              height: "26px",
              minWidth: "88px",
              fontSize: "12px",
              textTransform: "capitalize",
            }}
            variant="contained"
            label={params?.row?.refundStatus}
          />
        ),
      },
      {
        field: "OrderNote",
        headerName: "Order Note",
        minWidth: 150,
        flex: 1.2,
        renderCell: (params) => (
          <Tooltip title={params?.row?.refundReason}>
            <Typography
              textTransform="capitalize"
              sx={{
                maxHeight: "70px",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
            >
              {params?.row?.refundReason}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: "price",
        headerName: "Price",
        minWidth: 90,
        flex: 0.6,
        renderCell: (params) => (
          <Box>
            <CurrencyFormatter number={params?.row?.refundAmount} />
          </Box>
        ),
      },

      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 80,
        flex: 0.8,
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Tooltip title="View">
              <Button
                variant="text"
                sx={{
                  minWidth: "20px",
                }}
                onClick={() => ViewReturnsRow(params.id)}
              >
                <img src={eyeIcon} alt="view" className="icon-size" />
              </Button>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [isTablet, isLaptop, isLed, isDesktop, ViewReturnsRow]
  );
  return (
    <StyledDataGrid
      rows={results}
      columns={columns}
      loading={loading}
      totalPages={totalPages}
    />
  );
}

const actionStyles = {
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
