import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardTitle from "components/CardTitle";
import DateFilter from "components/Pickers/DateRange";
import dayjs from "dayjs";
import { memo, useCallback, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersChart } from "store/slices/dashboardSlice";
import {
  BUTTON_HOVER,
  BUTTON_HOVER_BORDER_COLOR,
  DASHBOARD_CHART_ROW_COLOR,
  FILTERS_BORDER_COLOR,
  STATS_PRIMARY_BLUE,
} from "styles/colors";
import { toast } from "utils/hooks/useToast";

function LineChart() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const ordersData = useSelector((state) => state.dashboard.ordersChart);
  const [render, setRender] = useState(false);

  const orders = ordersData?.map((val) => val?.y);
  const dailyOrders = ordersData?.map((item) => {
    const { year, month, date } = item.x;
    const dateObj = new Date(year, month, date); // month is zero-indexed
    const options = { month: "short", day: "numeric" };
    return dateObj.toLocaleString("en-US", options);
  });

  const totalOrders = orders?.reduce((pre, curr) => pre + curr, 0);

  const series = [
    {
      name: "Orders",
      data: orders,
    },
  ];

  const options = {
    chart: {
      type: "line",
      stacked: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    markers: {
      size: 5,
      colors: "#fff",
      strokeColors: STATS_PRIMARY_BLUE,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    grid: {
      row: {
        colors: [DASHBOARD_CHART_ROW_COLOR, "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: dailyOrders,
    },
  };

  const handleChange = useCallback(
    (range) => {
      const startDate = range.startDate;
      const endDate = range.endDate;
      const dateIsSame = dayjs(startDate).isSame(endDate, "day");
      if (dateIsSame) {
        toast.error("Start date must be less than end date");
      } else {
        dispatch(getOrdersChart({ range }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (isOpen === !render) {
      const timeOut = setTimeout(() => {
        setRender(isOpen);
      }, 500);
      return () => clearTimeout(timeOut);
    }
  }, [isOpen, render]);

  return (
    <Box id="chart" p="1rem">
      <Box sx={containerStyles}>
        <Box>
          <CardTitle title="Orders" color="black" />
          <Typography fontSize="0.875rem">Last 30 Days</Typography>
          <Typography fontSize="1.125rem" fontWeight="bold" color="#5e5873">
            {totalOrders ?? 0}
          </Typography>
        </Box>
        <Box>
          <DateFilter onChange={handleChange} />
        </Box>
      </Box>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={isOpen ? 378 : 361}
      />
    </Box>
  );
}

export default memo(LineChart);

const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiOutlinedInput-root": {
    height: "44px",
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
    "&:hover": {
      backgroundColor: BUTTON_HOVER,
      "& fieldset": {
        borderColor: BUTTON_HOVER_BORDER_COLOR,
      },
    },
  },
};
