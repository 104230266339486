import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCategories, resetState } from "store/slices/filtersSlice";
import { FILTERS_BACKGROUND_COLOR, FILTER_TEXT_COLOR } from "styles/colors";

function FilterByCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categories, loading } = useSelector((state) => state.filters);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(getCategories());
    }, 500);
    return () => {
      clearTimeout(timeOut);
      dispatch(resetState());
    };
  }, [dispatch]);

  return (
    <Autocomplete
      sx={{ minWidth: 170, maxWidth: 180 }}
      size="small"
      getOptionLabel={(stores) => stores.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      options={categories}
      noOptionsText="No Options Available"
      onChange={(_, newValue) => {
        newValue?.id
          ? navigate(`/products?category=${newValue.id}`)
          : navigate(`/products`);
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Category"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "1.031rem",
            },
            "& .MuiInputBase-root": {
              height: 44,
              backgroundColor: FILTERS_BACKGROUND_COLOR,
              cursor: "pointer",
            },
            input: {
              color: FILTER_TEXT_COLOR,
              "&::placeholder": {
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default memo(FilterByCategory);
