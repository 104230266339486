export const UPDATE_ORDER_STATUS_OPTIONS = [
  { label: "New", value: "new", isEnabled: [] },
  {
    label: "Confirmed",
    value: "confirmed",
    isEnabled: ["new"],
  },
  { label: "Cancel", value: "cancel", isEnabled: ["new"] },
  { label: "Ready", value: "ready", isEnabled: ["confirmed"] },
  {
    label: "Delivered",
    value: "delivered",
    isEnabled: ["ready"],
  },
  {
    label: "Completed",
    value: "completed",
    isEnabled: ["delivered"],
  },
];
