import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo } from "react";
import { mobileBannerOptions } from "utils/constants/mobileBannerOptions";

function MobileBannerForm({ formik }) {
  return (
    <>
      <Grid item xs={6} lg={6}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Link ID
          </Typography>
          <StyledTextField
            fullWidth
            type="text"
            {...formik.getFieldProps("linkId")}
            error={Boolean(formik.touched.linkId && formik.errors.linkId)}
            helperText={
              formik.touched.linkId && !!formik.errors.linkId
                ? formik.errors.linkId
                : "Enter link ID"
            }
          />
        </Box>
      </Grid>
      <Grid item xs={6} lg={6}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Link Type
          </Typography>
          <StyledTextField
            fullWidth
            select
            type="text"
            {...formik.getFieldProps("linkType")}
            error={Boolean(formik.touched.linkType && formik.errors.linkType)}
            helperText={
              formik.touched.linkType && !!formik.errors.linkType
                ? formik.errors.linkType
                : "Select Banner link Type"
            }
          >
            {mobileBannerOptions.map((option) => (
              <MenuItem key={option.value} dense value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledTextField>
        </Box>
      </Grid>
    </>
  );
}

MobileBannerForm.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(MobileBannerForm);
