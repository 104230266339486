import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import productPlaceholder from "assets/images/imagePlaceholder.png";
import CurrencyFormatter from "components/CurrencyFormatter";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledGrid from "components/StyledGrid";
import StyledSwitch from "components/ToggleButton";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addFeaturedProduct,
  deleteProduct,
  getAdminProducts,
  removeFeaturedProduct,
} from "store/slices/productsSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import { ACTIVE_COLOR, INACTIVE_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const StyledDel = styled("del")(() => ({}));

export default function ProductDataGrid() {
  const dispatch = useDispatch();
  const { results, loading, totalPages } = useSelector(
    (state) => state.products
  );

  const params = useQueryParams();
  const navigate = useNavigate();

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const fetchData = useCallback(() => {
    dispatch(getAdminProducts(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchData();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchData]);

  const handleChange = useCallback(
    (row) => {
      if (row.featured === false) {
        dispatch(
          addFeaturedProduct({
            id: row.id,
          })
        )
          .unwrap()
          .then(() => fetchData());
      } else {
        dispatch(
          removeFeaturedProduct({
            id: row.id,
          })
        )
          .unwrap()
          .then(() => fetchData());
      }
    },
    [dispatch, fetchData]
  );

  const handleClickOpen = useCallback(
    (productId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(productId));
    },
    [dispatch]
  );

  const UpdateProductRow = useCallback(
    (productId) => {
      navigate(`/products/update/${productId}`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 50,
        flex: 0.15,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Typography>{params?.row?.serialNumber}</Typography>
        ),
      },
      {
        field: "image",
        headerName: "Image",
        headerAlign: "center",
        align: "center",
        minWidth: 125,
        flex: 0.8,
        renderCell: (params) => (
          <Box mx="auto">
            <img
              src={
                params?.row?.mainImage
                  ? params?.row?.mainImage
                  : productPlaceholder
              }
              height={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              width={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 6,
                objectFit: "contain",
              }}
              alt="product_image"
            />
          </Box>
        ),
      },
      {
        field: "productName",
        headerName: "Name/Details",
        minWidth: 200,
        flex: 2,
        renderCell: (params) => (
          <Box>
            <Tooltip
              title={
                <p style={{ margin: 0, fontSize: "14px" }}>
                  {params?.row?.productName}
                </p>
              }
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  color: "#000000",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  // Addition lines for 2 line or multiline ellipsis
                  display: "-webkit-box !important",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  whiteSpace: "normal",
                }}
              >
                {params?.row?.productName}
              </Typography>
            </Tooltip>
            <Box display="flex" alignItems="baseline">
              <Typography
                sx={{
                  height: "12px",
                  width: "12px",
                  margin: "5px 6px 3px 0",
                  backgroundColor: params?.row?.active
                    ? ACTIVE_COLOR
                    : INACTIVE_COLOR,
                  borderRadius: "20px",
                }}
              />
              <Typography
                sx={{
                  color: params?.row?.active ? ACTIVE_COLOR : INACTIVE_COLOR,
                }}
              >
                {params?.row?.active ? "Active" : "Inactive"}
              </Typography>
            </Box>
            <Box display="flex" alignItems="baseline">
              Vendor:
              <Typography sx={{ color: "primary.main", marginLeft: 0.5 }}>
                {params?.row?.user?.sellerDetail
                  ? params?.row.user?.sellerDetail?.brandName
                  : params?.row?.user?.fullName}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "quantity",
        headerName: "Qty/Options",
        minWidth: 90,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography>Qty: {params?.row?.quantity}</Typography>
            <Typography>Options: {params?.row?.variants?.length}</Typography>
          </Box>
        ),
      },
      {
        field: "price",
        headerName: "Price",
        minWidth: 90,
        flex: 1,
        renderCell: (params) =>
          params?.row?.onSale ? (
            <Box>
              <CurrencyFormatter
                className="product-price"
                number={params?.row?.salePrice}
              />
              <StyledDel
                className="product-price"
                sx={{ color: "error.dark", fontSize: "1rem" }}
              >
                <CurrencyFormatter number={params?.row?.regularPrice} />
              </StyledDel>
            </Box>
          ) : (
            <CurrencyFormatter number={params?.row?.price} />
          ),
      },
      {
        field: "featured",
        headerName: "Featured",
        headerAlign: "center",
        align: "center",
        minWidth: 90,
        flex: 1,
        renderCell: (params) => (
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <StyledSwitch
                checked={params?.row?.featured}
                onChange={() => handleChange(params.row)}
              />
            }
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        align: "center",
        minWidth: 130,
        flex: 1,
        renderCell: (params) => (
          <Box display="flex">
            <Tooltip title="Edit product" arrow placement="top">
              <IconButton
                size="small"
                onClick={() => UpdateProductRow(params.id)}
              >
                <img src={WritingIcon} alt="edit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete product" arrow placement="top">
              <IconButton
                size="small"
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="delete" />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [isTablet, isLaptop, isLed, handleChange, UpdateProductRow, handleClickOpen]
  );
  return (
    <>
      <StyledGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteProduct} />
    </>
  );
}
