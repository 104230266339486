import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LanguageToggleButtons from "components/LanguageToggleButtons";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllCities,
  getAllMarkets,
  getCategories,
  resetState,
} from "store/slices/filtersSlice";
import { resetLanguageState } from "store/slices/languageTranslatorSlice";
import {
  getAdminSellerDetailById,
  getStore,
  updateStore,
} from "store/slices/storesSlice";
import USER_ROLE from "utils/constants/userRole";
import flatMarketArray from "utils/helpers/flatMarketArray";
import parseEditorValue from "utils/helpers/parseEditorValue";
import * as Yup from "yup";
import StoreDescriptionSection from "./StoreDescriptionSection";
import StoreMediaSection from "./StoreMediaSection";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function UpdateStore() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const userRole = useSelector((state) => state.auth?.userRole ?? "");
  const store = useSelector((state) => state.stores.store ?? null);
  const storeId = useSelector((state) => state.stores.store?.id ?? "");
  const cities = useSelector((state) => state.filters.cities ?? []);
  const markets = useSelector((state) =>
    flatMarketArray(state.filters.markets ?? [])
  );
  const categories = useSelector((state) =>
    state.filters?.categories.map((category) => {
      const _category = { ...category };
      if (_category.subcategories) {
        delete _category.subcategories;
      }
      return _category;
    })
  );
  const selectedLanguage = useSelector((state) => state.UI.language ?? "En");
  const translatedData = useSelector(
    (state) =>
      state.languageTranslation?.translatedData ??
      state.stores?.store?.lang?.ar ??
      null
  );
  const [data, setData] = useState(null);
  const GENERAL_CATEGORY_ID = process.env.REACT_APP_GENERAL_CATEGORY_ID;

  useEffect(() => {
    if (selectedLanguage === "Ar") {
      setData({
        text: {
          brandName: store?.brandName,
          description: store?.description,
          address: store?.address,
        },
        lang: "ar",
      });
    }
  }, [dispatch, selectedLanguage, store]);

  const initialValues = useMemo(
    () => ({
      storeName:
        selectedLanguage === "Ar"
          ? translatedData?.brandName
          : store?.brandName ?? "",
      address:
        selectedLanguage === "Ar"
          ? translatedData?.address
          : store?.address ?? "",
      city: store?.city ?? "",
      categories: store?.categories ?? [],
      description:
        selectedLanguage === "Ar"
          ? parseEditorValue(translatedData?.description)
          : store?.description
          ? parseEditorValue(store?.description)
          : "",
      storeImage: store?.images?.length ? store?.images[0] : "",
      cityCode: store?.cityCode ?? "",
      market: store?.market?.name ?? "",
      marketId: store?.market?.id ?? "",
    }),
    [store, selectedLanguage, translatedData]
  );

  const handleSubmit = useCallback(
    (values) => {
      const data = {
        brandName: values?.storeName,
        description: values?.description,
        market: values?.market?.value ?? values.marketId,
        address: values?.address,
        city: values?.city,
        categories: values?.categories,
        cityCode: values?.cityCode,
      };

      const image = values?.storeImage ?? "";
      const id = params.id ?? storeId;

      if (selectedLanguage === "Ar") {
        const data = {
          lang: {
            ar: {
              brandName: values?.storeName,
              description: values?.description,
              address: values?.address,
            },
          },
        };
        dispatch(updateStore({ id, data, image }))
          .unwrap()
          .then(() => {
            if (userRole === USER_ROLE.ADMIN) {
              dispatch(resetLanguageState());
              navigate("/stores");
            } else if (
              userRole === USER_ROLE.SUPPLIER ||
              userRole === USER_ROLE.REQUESTED_SELLER
            ) {
              dispatch(resetLanguageState());
              navigate(`/store`);
            } else {
              dispatch(resetLanguageState());
              dispatch(getStore());
            }
          });
      } else
        dispatch(updateStore({ id, data, image }))
          .unwrap()
          .then(() => {
            if (userRole === USER_ROLE.ADMIN) {
              navigate("/stores");
            } else if (
              userRole === USER_ROLE.SUPPLIER ||
              userRole === USER_ROLE.REQUESTED_SELLER
            ) {
              dispatch(resetLanguageState());
              navigate(`/store`);
            } else {
              dispatch(getStore());
            }
          });
    },
    [dispatch, navigate, params.id, selectedLanguage, storeId, userRole]
  );

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getAllCities());
    dispatch(getAllMarkets());
    return () => dispatch(resetState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAdminSellerDetailById(params?.id));
  }, [dispatch, params?.id]);

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          STORES
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography
          mb={2}
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        >
          Update Store
        </Typography>
        <LanguageToggleButtons data={data} />
      </Box>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Basic Information"
                  subTitle="Please enter the basic information of the store such as name and description"
                >
                  <CardContent>
                    <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Store Name
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="text"
                              {...formik.getFieldProps("storeName")}
                              error={Boolean(
                                formik.touched.v && formik.errors.storeName
                              )}
                              helperText={
                                formik.touched.storeName &&
                                !!formik.errors.storeName
                                  ? formik.errors.storeName
                                  : "Enter a descriptive name of the store here"
                              }
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Address
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="text"
                              {...formik.getFieldProps("address")}
                              error={Boolean(
                                formik.touched.address && formik.errors.address
                              )}
                              helperText={
                                formik.touched.address &&
                                !!formik.errors.address
                                  ? formik.errors.address
                                  : "Enter a descriptive address of the store here"
                              }
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              City
                            </Typography>
                            <Autocomplete
                              name="city"
                              options={cities}
                              value={formik.values.city}
                              onChange={(_, value) => {
                                formik.setFieldValue("city", value.CITY_NAME);
                                formik.setFieldValue(
                                  "cityCode",
                                  value.CITY_CODE
                                );
                              }}
                              onBlur={formik.handleBlur}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value
                              }
                              renderInput={(params) => (
                                <StyledTextField
                                  fullWidth
                                  {...params}
                                  hiddenLabel={true}
                                  error={Boolean(
                                    formik.touched.city && formik.errors.city
                                  )}
                                  helperText={
                                    formik.touched.city && !!formik.errors.city
                                      ? formik.errors.city
                                      : "Please select a city for the store"
                                  }
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option.CITY_CODE}>
                                  {option.CITY_NAME}
                                </li>
                              )}
                              disabled={selectedLanguage === "Ar"}
                            />
                          </Stack>
                        </Grid>
                        {userRole === "admin" && (
                          <Grid item xs={6} lg={6}>
                            <Stack direction="column" gap={1}>
                              <Typography
                                variant="h3"
                                fontWeight="bold"
                                fontSize="1.25rem"
                                color="text.secondary"
                              >
                                Market
                              </Typography>
                              <Autocomplete
                                name="market"
                                options={markets}
                                value={formik.values.market}
                                onChange={(_, value) => {
                                  formik.setFieldValue("market", value);
                                }}
                                onBlur={formik.handleBlur}
                                isOptionEqualToValue={(option, value) =>
                                  option.label === value
                                }
                                renderInput={(params) => (
                                  <StyledTextField
                                    fullWidth
                                    {...params}
                                    hiddenLabel={true}
                                    error={Boolean(
                                      formik.touched.market &&
                                        formik.errors.market
                                    )}
                                    helperText={
                                      formik.touched.market &&
                                      !!formik.errors.market
                                        ? formik.errors.market
                                        : "Please select a market for the store"
                                    }
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <li {...props} key={option.value}>
                                    {option.label}
                                  </li>
                                )}
                                disabled={selectedLanguage === "Ar"}
                              />
                            </Stack>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Categories
                            </Typography>
                            <Autocomplete
                              multiple
                              name="categories"
                              id="checkboxes-tags-demo"
                              disableCloseOnSelect
                              options={categories ?? []}
                              value={
                                formik.values?.categories?.length
                                  ? categories.filter((category) =>
                                      formik.values?.categories?.includes(
                                        category?.id
                                      )
                                    )
                                  : []
                              }
                              onChange={(_, dropdownCategories) => {
                                if (dropdownCategories.length > 3) {
                                  return;
                                }
                                if (dropdownCategories.length > 2) {
                                  let newCategory = dropdownCategories.filter(
                                    (obj) =>
                                      !formik.values.categories.includes(obj.id)
                                  );
                                  if (newCategory.length) {
                                    newCategory = newCategory[0];
                                  } else {
                                    newCategory = null;
                                  }

                                  if (
                                    (formik.values.categories.includes(
                                      GENERAL_CATEGORY_ID
                                    ) &&
                                      newCategory.id !== GENERAL_CATEGORY_ID) ||
                                    (!formik.values.categories.includes(
                                      GENERAL_CATEGORY_ID
                                    ) &&
                                      newCategory.id === GENERAL_CATEGORY_ID)
                                  ) {
                                    formik.setFieldValue(
                                      "categories",
                                      dropdownCategories?.map(
                                        (category) => category?.id
                                      )
                                    );
                                  }
                                } else {
                                  formik.setFieldValue(
                                    "categories",
                                    dropdownCategories?.map(
                                      (category) => category?.id
                                    )
                                  );
                                }
                              }}
                              onBlur={formik.handleBlur}
                              getOptionLabel={(option) => option?.name}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    disabled={
                                      selected &&
                                      formik.values.categories.length >= 3
                                    }
                                  />
                                  {option.name}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Categories"
                                  placeholder="Category"
                                  error={Boolean(
                                    formik.touched.categories &&
                                      formik.errors.categories
                                  )}
                                  helperText={
                                    formik.touched.categories &&
                                    formik.errors.categories
                                      ? formik.errors.categories
                                      : "You can add at most 3 categories, one general and any two of the desired categories"
                                  }
                                />
                              )}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </CardContent>
                </StyledMuiCard>
              </Grid>

              {/*Store Description Section */}
              <Grid item xs={12} lg={12}>
                <StoreDescriptionSection formik={formik} />
              </Grid>
              {/*Store Media Section */}
              {selectedLanguage === "En" ? (
                <Grid item xs={12} lg={12}>
                  <StoreMediaSection formik={formik} />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    disabled={formik.isSubmitting}
                    onClick={() => formik.resetForm()}
                    variant="outlined"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
export default memo(UpdateStore);

const validationSchema = Yup.object().shape({
  storeName: Yup.string()
    .trim()
    .required("Required*")
    .min(5, "Name should be at least 10 characters long")
    .max(100, "Name is too long"),
  address: Yup.string().required("Required*"),
  city: Yup.string().required("Required*"),
  description: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Description must be at least 10 characters long")
    .max(1500, "Description is too long"),
  storeImage: Yup.mixed().required("Store Banner Image is required"),
});
