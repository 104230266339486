import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { handleDiscountedProduct } from "store/slices/cartSlice";
import { TEXTFIELD_BACKGROUND_COLOR } from "styles/colors";

function AdminDiscountOptions({ item }) {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState("");
  const [selectedDiscountType, setSelectedDiscountType] = useState("amount");

  const handleChange = useCallback(
    (value) => {
      if (value < 0) {
        setError("Value must be greater than 0");
      } else if (selectedDiscountType === "percentage" && value >= 100) {
        setError("Value should be less than 100");
      } else if (selectedDiscountType === "amount" && value >= item.total) {
        setError(`Value should be less than ${item.total}`);
      } else {
        setError("");
        setAmount(value);
        dispatch(
          handleDiscountedProduct({
            discountType: selectedDiscountType,
            amount: value,
            product: item.product.id,
          })
        );
      }
    },
    [dispatch, item.product.id, item.total, selectedDiscountType]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ py: "10px" }}
    >
      <Box sx={{ marginBottom: "5px" }}>
        <FormControl fullWidth>
          <InputLabel>Discount Type</InputLabel>
          <Select
            IconComponent={ExpandMoreIcon}
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": {
                border: "1px solid primary.main",
              },
              ".MuiOutlined": {
                border: "1px solid primary.main",
              },
              backgroundColor: "#fff",
            }}
            value={selectedDiscountType}
            label="Discount Type"
            onChange={(e) => {
              setSelectedDiscountType(e.target.value);
              dispatch(
                handleDiscountedProduct({
                  discountType: e.target.value,
                  amount,
                  product: item.product.id,
                })
              );
            }}
          >
            <MenuItem value="amount">Amount</MenuItem>
            <MenuItem value="percentage">Percentage</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TextField
        fullWidth
        type="number"
        placeholder="Enter Value"
        value={amount}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        sx={{
          backgroundColor: TEXTFIELD_BACKGROUND_COLOR,
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          ".MuiOutlined": {
            border: 0,
          },
        }}
        inputProps={{
          min: 0,
        }}
        error={!!error}
        helperText={error ? error : ""}
      />
    </Box>
  );
}

AdminDiscountOptions.propTypes = {
  item: PropTypes.object.isRequired,
};

export default memo(AdminDiscountOptions);
