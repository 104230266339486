import { store } from "store/configureStore";

export const calculateProductDiscount = (item) => {
  const discountedProducts = store.getState().cart.adminDiscountProducts;
  const discountProduct = discountedProducts?.find(
    (_item) => _item.product === item?.product?.id
  );
  if (!discountProduct) return item?.total ?? 0;
  return (
    item?.total -
    calculateDiscount(
      discountProduct?.discountType,
      discountProduct?.amount,
      item?.total
    )
  );
};

export const calculateAdminDiscount = () => {
  const discountedProducts = store.getState().cart.adminDiscountProducts;
  const packages = store.getState().cart?.packageItems;

  if (!discountedProducts.length) return 0;

  let totalDiscount = 0;

  packages.forEach((_package) => {
    _package?.forEach((item) => {
      const discountProduct = discountedProducts?.find(
        (_item) => _item.product === item?.product?.id
      );
      if (discountProduct) {
        totalDiscount += Number(
          calculateDiscount(
            discountProduct?.discountType,
            discountProduct?.amount,
            item?.total
          )
        );
      }
    });
  });

  return totalDiscount;
};

export const calculateDiscount = (discountType, amount, total) => {
  if (discountType === "percentage") {
    return Number((amount / 100) * total).toFixed(2);
  } else if (discountType === "amount") {
    return amount;
  }
};
