import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getRequestedSellers = createAsyncThunk(
  "getRequestedSellers",
  // async (dataProvidedInFunctionParameter, { getState, dispatch }) => {}
  async () => {}
);

export const getRequestedSeller = createAsyncThunk(
  "getRequestedSeller",
  async () => {}
);

export const addRequestedSeller = createAsyncThunk(
  "addRequestedSeller",
  async () => {}
);

export const updateRequestedSeller = createAsyncThunk(
  "updateRequestedSeller",
  async () => {}
);

export const deleteRequestedSeller = createAsyncThunk(
  "deleteRequestedSeller",
  async () => {}
);

const requestedSellersSlice = createSlice({
  name: "requestedSellers",
  initialState: {
    loading: true,
    seller: null,
    results: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: "",
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.seller = null;
      state.results = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = "";
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase();
  // },
});

// Action creators generated for each case reducer function
export const { resetState } = requestedSellersSlice.actions;

// Exporting default reducer
export default requestedSellersSlice.reducer;
