import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FilterByStatus from "components/FullOrders/FilterByStatus";
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FILTER_TEXT_COLOR, PRIMARY } from "styles/colors";

function OrderFilters() {
  const navigate = useNavigate();
  // const params = useQueryParams();
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
    if (open) {
      navigate(`/orders/full-orders`);
    }
  };

  // const handleChange = useCallback(
  //   (range) => {
  //     let _params = params;
  //     _params.from = range.startDate.toDateString();
  //     _params.to = range.endDate.toDateString();
  //     navigate({
  //       search: createSearchParams(_params).toString(),
  //     });
  //   },
  //   [navigate, params]
  // );

  return (
    <Box mb={2} sx={containerStyles}>
      <Box display="flex" gap={2}>
        {!open && (
          <Button
            variant="text"
            onClick={toggle}
            className="Filter-toggle-btn"
            sx={{
              borderBottom: "2px solid",
              borderColor: PRIMARY,
              borderRadius: 0,
              textTransform: "none",
            }}
          >
            Filter
          </Button>
        )}
        {open && (
          <>
            {/* <DateFilter onChange={handleChange} /> */}
            <FilterByStatus />
          </>
        )}
        {open && (
          <Button
            onClick={toggle}
            variant="text"
            className="Filter-toggle-btn"
            sx={{
              borderBottom: "2px solid #707070",
              borderRadius: 0,
              color: FILTER_TEXT_COLOR,
              textTransform: "none",
            }}
          >
            Clear All
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default memo(OrderFilters);

const containerStyles = {
  display: { xl: "flex", lg: "grid" },
  justifyContent: "end",
  alignItems: "center",
  "& .MuiFormControl-root": {
    borderColor: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#069ddd80",
    },
    "&:hover": {
      backgroundColor: "#edf8fd",
      "& fieldset": {
        borderColor: "#02b4fffc",
      },
    },
  },
  "& .MuiSelect-select": { height: "29px !important" },
  "& .Filter-toggle-btn": {
    height: 42,
    textTransform: "none",
    fontSize: "1rem",
  },
};
