import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { memo, useCallback } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { PRIMARY, STATS_CARD_TEXT_COLOR } from "styles/colors";
import { getErrorMessage } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import streamingHttpClient from "utils/httpRequest/streamingHttpClient";

function UpdateFbViews() {
  const responseFacebook = useCallback((response) => {
    let accessToken = response.accessToken;
    streamingHttpClient
      .patch(`/facebook-streaming/views`, {
        token: accessToken,
      })
      .then((res) => {
        if (res && res.data && res.data.status === 200 && res.data.isSuccess) {
          this.getVideos(this.props.parsedFilter);
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        toast.error(message);
      });
  }, []);

  return (
    <Box sx={containerStyles}>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        callback={responseFacebook}
        render={({ onClick, isDisabled, isSdkLoaded }) => (
          <Button
            onClick={onClick}
            className="UpdateFbViews"
            fullWidth
            disabled={!isSdkLoaded || isDisabled}
            color="primary"
            variant="outlined"
          >
            Update FB Views
          </Button>
        )}
      />
      <Typography color={STATS_CARD_TEXT_COLOR} mt={1}>
        * Click the button to update Facebook views
      </Typography>
    </Box>
  );
}

export default memo(UpdateFbViews);

const containerStyles = {
  "& .UpdateFbViews": {
    textTransform: "none",
    py: 1,
    fontSize: {
      xs: "0.8rem",
      sm: "1rem",
    },
    width: "auto",
    background: PRIMARY,
    color: "#fff",
    "&:hover": {
      background: PRIMARY,
    },
    "&:disabled": {
      color: "#f5f5f5",
    },
  },
};
