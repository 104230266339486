import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import StyledSwitch from "components/ToggleButton";
import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handlePayment } from "store/slices/checkoutSlice";
import PaymentRadioOptions from "./PaymentRadioOptions";

function PaymentMethod({ formik }) {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const userId = useSelector((state) => state.cart?.userId ?? "");
  const active = useSelector((state) => state.cart?.wallet ?? false);
  const addressType = useSelector(
    (state) => state.cart?.cartData?.user?.defaultAddress?.localType ?? "local"
  );

  const walletBalance = useSelector((state) => state.cart?.walletBalance ?? 0);

  const handleChange = useCallback(() => {
    if (active === false) {
      const toggle = true;
      dispatch(
        handlePayment({
          wallet: toggle,
          userId,
        })
      );
    } else {
      const toggle = false;
      dispatch(
        handlePayment({
          wallet: toggle,
          userId,
        })
      );
    }
  }, [active, dispatch, userId]);

  return (
    <Box variant="container">
      <Typography
        variant="h3"
        color="primary"
        fontSize={{ xs: "0.75rem", sm: "1rem", md: "2rem" }}
        fontWeight="600"
        marginBottom="20px"
      >
        Payment Method
      </Typography>
      {addressType === "local" ? (
        <Card>
          <Grid container spacing={1} sx={{ paddingTop: "10px" }}>
            <Grid item xs={6} lg={6}>
              <PaymentRadioOptions />
            </Grid>
            {!!walletBalance && (
              <Grid item xs={6} lg={6}>
                <Box
                  display="flex"
                  justifyContent="end"
                  padding="20px"
                  alignItems="center"
                >
                  <Typography
                    variant="p"
                    px="10px"
                    fontSize={{
                      sm: "0.5rem",
                      md: "1rem",
                      lg: "1.25rem",
                      xl: "1.5rem",
                    }}
                  >
                    Use Wallet
                  </Typography>
                  <StyledSwitch
                    checked={active}
                    onChange={() => handleChange()}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <Box padding="20px">
                <StyledTextField
                  fullWidth
                  size="large"
                  type="text"
                  placeholder="Enter Order Note"
                  onChange={(e) => {
                    if (e.target.value.trim() === "") {
                      setError("Required*");
                    } else {
                      setError("");
                      formik.setFieldValue("orderNote", e.target.value);
                    }
                  }}
                  error={Boolean(
                    formik.touched?.orderNote && formik.errors?.orderNote
                  )}
                  helperText={
                    formik.touched?.orderNote && !!formik.errors?.orderNote
                      ? formik.errors?.orderNote
                      : "Enter at least 10 character long note"
                  }
                  sx={{
                    "& fieldset": { border: "none" },
                    backgroundColor: "#f7fcfe",
                  }}
                  multiline
                  rows={4}
                />
                {error ? (
                  <Typography sx={{ color: "red", fontSize: "12px", py: 1 }}>
                    {error}
                  </Typography>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Card sx={{ p: 2 }}>
          <Typography
            variant="p"
            py="10px"
            fontSize={{
              sm: "0.75rem",
              md: "1.25rem",
              lg: "1.5rem",
              xl: "1.75rem",
            }}
            fontWeight="bold"
          >
            Manual
          </Typography>
          <Divider sx={{ borderColor: "black", my: 1 }} />
          <Typography>
            Our customer support agent would shortly contact you to confirm this
            order and inform about the shipping charges required for your
            destination, feel free to ask any query by email at{" "}
            <Link href="mailto:hello@bazaarghar.com" target="_blank">
              hello@bazaarghar.com
            </Link>
          </Typography>
        </Card>
      )}
    </Box>
  );
}

PaymentMethod.propTypes = {
  formik: PropTypes.object.isRequired,
};
export default memo(PaymentMethod, arePropsEqual);

function arePropsEqual(oldProps, newProps) {
  return (
    oldProps.formik.values.orderNote === newProps.formik.values.orderNote &&
    oldProps.formik.errors.orderNote === newProps.formik.errors.orderNote
  );
}
