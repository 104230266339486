import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImagePlaceholder from "assets/icons/ProductAddImagePlaceholder.svg";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";

function BannerImageForm({ formik }) {
  const isMobile = useParams()?.device === "mobile";
  const thumbnailInputRef = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  const [banner, setBanner] = useState("");

  const imageHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        thumbnailInputRef.current.value = "";
        formik.setFieldValue("bannerImage", image);
        setBanner(URL.createObjectURL(image));
        // // function params
        // // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        // validateImageAspectRatio(
        //   image
        //   // isMobile ? 640 : 1200,
        //   // isMobile ? 320 : 400,
        //   // false,
        //   // false,
        //   // isMobile ? 2 / 1 : 3 / 1,
        //   // isMobile ? "2 /1" : "3 / 1"
        // )
        //   .then((img) => {

        //   })
        //   .catch((error) => {
        //     thumbnailInputRef.current.value = "";
        //     dispatch(setIsImageValidationDialogOpen(true));
        //     dispatch(setImageValidationError(error?.message ?? ""));
        //   });
      }
    },
    [formik]
  );

  return (
    <StyledMuiCard title="Banner Image" subTitle="Upload Banner Image">
      <CardContent>
        <Stack direction="column" gap={2}>
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Image
          </Typography>
          <Box display="flex" gap={4}>
            {!formik.values.bannerImage && (
              <Box>
                <img
                  src={ImagePlaceholder}
                  alt="Upload"
                  height={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                  width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    thumbnailInputRef.current.click();
                  }}
                />
                <input
                  type="file"
                  ref={thumbnailInputRef}
                  style={{ display: "none" }}
                  hidden
                  accept="image/x-png,image/png,image/jpeg,image/jpg"
                  onChange={imageHandler}
                />
                {formik.errors.bannerImage ? (
                  <Typography color="#ef5350">
                    {formik.errors.bannerImage}*
                  </Typography>
                ) : (
                  <Typography color="#6e6b7b">
                    {`The image should have a width of ${
                      isMobile ? "640 " : "1200"
                    } pixels and a height of
                  ${isMobile ? "320 " : "400"} pixels or more.`}
                  </Typography>
                )}
              </Box>
            )}
            {formik.values.bannerImage && (
              <Box pb={2}>
                <img
                  src={banner ? banner : formik.values.bannerImage}
                  alt=""
                  height="100%"
                  width="100%"
                  style={{
                    aspectRatio: 3 / 1,
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    thumbnailInputRef.current.click();
                  }}
                />
                <input
                  type="file"
                  ref={thumbnailInputRef}
                  style={{ display: "none" }}
                  hidden
                  accept="image/x-png,image/png,image/jpeg,image/jpg"
                  onChange={imageHandler}
                />
                {formik.errors.bannerImage ? (
                  <Typography color="#ef5350">
                    {formik.errors.bannerImage}*
                  </Typography>
                ) : (
                  <Typography color="#6e6b7b">
                    Click on the current image to upload new one
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

BannerImageForm.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(BannerImageForm);
