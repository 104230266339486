import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveRoom } from "store/slices/chatSlice";
import { BORDER_LIGHT } from "styles/colors";
import getInitialLetters from "utils/helpers/getInitialLetters";

function ChatRooms() {
  const dispatch = useDispatch();
  const rooms = useSelector((state) => state.chat?.rooms ?? []);
  const activeRoom = useSelector((state) => state.chat?.activeRoom ?? null);

  const handleClick = useCallback(
    (room) => {
      if (activeRoom?._id === room._id) return;
      dispatch(setActiveRoom(room));
    },
    [activeRoom?._id, dispatch]
  );

  return (
    <Stack direction="column" gap={2} height="100%">
      <Typography variant="h4" fontWeight="600" color="primary.main">
        Chats
      </Typography>
      {rooms.length ? (
        <Stack direction="column" sx={containerStyles}>
          {rooms.map((room, index) => (
            <Box
              key={room._id}
              onClick={() => handleClick(room)}
              sx={{
                cursor: "pointer",
              }}
            >
              <Stack
                direction="row"
                gap={1.5}
                alignItems="center"
                p={0.5}
                sx={{
                  "&:hover": {
                    bgcolor: BORDER_LIGHT,
                  },
                  bgcolor:
                    room._id === activeRoom?._id ? BORDER_LIGHT : "transparent",
                }}
              >
                <Badge
                  badgeContent={room?.unreadCount}
                  overlap="circular"
                  color="error"
                >
                  <Avatar
                    src=""
                    sx={{
                      height: { xs: 40, md: 50 },
                      width: { xs: 40, md: 50 },
                      bgcolor: "primary.main",
                    }}
                  >
                    {room?.customerName
                      ? getInitialLetters(room?.customerName)
                      : "N/A"}
                  </Avatar>
                </Badge>
                <Typography variant="h6" component="p">
                  {room?.customerName ?? "N/A"}
                </Typography>
              </Stack>
              {index + 1 !== rooms.length && <Divider />}
            </Box>
          ))}
        </Stack>
      ) : (
        <Typography variant="h6" color="text.secondary">
          No chats found
        </Typography>
      )}
    </Stack>
  );
}

export default memo(ChatRooms);

const containerStyles = {
  overflowX: "hidden",
  overflowY: "auto",
  scrollBehavior: "smooth",
  "&::-webkit-scrollbar": {
    width: 5,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    minHeight: 25,
    backgroundColor: "primary.light",
    borderRadius: 1,
  },
};
