import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { memo } from "react";
import CollectionDataGrid from "./CollectionDataGrid";

function DeleteCollectionProducts({ collectionProducts }) {
  return (
    <Box py={2} px={1}>
      <Typography variant="h4" fontWeight="700" color="primary.main" py={2}>
        Remove Product
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <StyledMuiCard
            title="Select Products"
            subTitle="Click to remove products from collection"
          >
            <CardContent>
              <Stack
                direction="row"
                gap={3}
                p={{ xs: 1, md: 1.5 }}
                maxHeight={600}
                sx={{ overflowY: "scroll", overflowX: "hidden" }}
              >
                <Grid item xs={12} lg={12}>
                  <CollectionDataGrid collectionProducts={collectionProducts} />
                </Grid>
              </Stack>
            </CardContent>
          </StyledMuiCard>
        </Grid>
      </Grid>
    </Box>
  );
}

DeleteCollectionProducts.propTypes = {
  collectionProducts: PropTypes.array.isRequired,
};

export default memo(DeleteCollectionProducts);
