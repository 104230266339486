import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import BasicReturnInfo from "./BasicReturnInfo";
import ReturnedImages from "./ReturnedImages";
import ReturnProductsDetail from "./ReturnProductsDetail";
import UpdateReturnNote from "./UpdateReturnNote";

function EditReturns() {
  return (
    <Box py={2} px={1}>
      <Typography
        variant="h4"
        fontWeight="600"
        fontSize={{ md: "1rem", lg: "1.5rem" }}
        my={1}
      >
        Returns Detail
      </Typography>
      <Grid container spacing={2}>
        {/* Basic Info Section */}
        <Grid item xs={12}>
          <BasicReturnInfo />
        </Grid>
        {/* Return Note Section */}
        <Grid item xs={12}>
          <UpdateReturnNote />
        </Grid>
        {/* Returned Product Detail Section */}
        <Grid item xs={12}>
          <ReturnProductsDetail />
        </Grid>
        {/* Returned Images Section */}
        <Grid item xs={12}>
          <ReturnedImages />
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(EditReturns);
