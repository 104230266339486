import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import { memo } from "react";
import { useSelector } from "react-redux";
import { CARD_HEADER_BACKGROUND } from "styles/colors";

function PackagesSection() {
  const order = useSelector((state) => state.orders.order);

  return (
    <Stack direction="column" gap={2}>
      <Card>
        <CardContent
          sx={{
            p: 0,
            "&:last-Child": {
              p: 0,
            },
          }}
        >
          <Box bgcolor={CARD_HEADER_BACKGROUND} p={2}>
            <Typography variant="h6" fontWeight="bold">
              Products
            </Typography>
            <Typography variant="body1">
              {`Sold by: `}
              <Typography
                component={"a"}
                href={`${process.env.REACT_APP_BAZAARGHAR_URL}/store/${order?.seller?.sellerDetail?.slug}`}
                target="_blank"
                variant="body1"
                fontWeight="bold"
                color="primary"
                textTransform="capitalize"
              >
                {order?.seller?.fullname}
              </Typography>
            </Typography>
          </Box>
          <Box>
            {order?.orderItems?.map((item) => (
              <Stack direction="row" key={item.id} mb={1} p={2} gap={2}>
                <img
                  src={item?.product?.mainImage}
                  alt={item?.product?.productName}
                  height="50px"
                  width="50px"
                />
                <Box flexGrow={1}>
                  <Typography variant="body1">
                    {item?.product?.productName ?? ""}
                  </Typography>
                  <Box display="flex">
                    {item?.product?.selectedAttributes?.map(
                      (attribute, index) => (
                        <Typography variant="body1" key={index} mr={1}>
                          {`${attribute?.name}: ${attribute?.value}`}
                        </Typography>
                      )
                    )}
                  </Box>
                </Box>
                <Typography variant="body1" minWidth="125px">
                  {`Quantity: ${item?.quantity}`}
                </Typography>
                <CurrencyFormatter
                  variant="body1"
                  minWidth="125px"
                  number={item?.product?.price ?? 0}
                />
              </Stack>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default memo(PackagesSection);
