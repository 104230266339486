import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";
import httpv2 from "utils/httpRequest/httpv2";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().users;
    try {
      const response = await httpv2.get(`/users/admin`, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          role: data.role ?? "user",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      if (responseData?.results) {
        responseData.results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getUser = createAsyncThunk(
  "users/getUser",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { users, sellers, requestedSellers } = getState().users;
      let user =
        users.find((user) => user.id === id) ||
        sellers.find((user) => user.id === id) ||
        requestedSellers.find((user) => user.id === id);
      if (user) {
        const userPromise = new Promise((resolve) => {
          setTimeout(() => {
            resolve(user);
          }, 100);
        });
        return userPromise.then((user) => {
          return user;
        });
      }
      const response = await http.get(`/users/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (values = {}, { rejectWithValue }) => {
    const { id, updatedValues } = values;
    try {
      const response = await http.patch(`/users/${id}`, updatedValues);
      toast.success("User updated successfully");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "users/updateUserPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await httpv2.patch(`/users/admin`, data);
      toast.success("Password Updated successfully");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (sellerId, { getState, rejectWithValue }) => {
    const { users, page, limit } = getState().users;
    try {
      await http.delete(`/users/${sellerId}`);
      toast.success("Deleted successfully");
      let returnedRequestedSellers = users.filter(
        (seller) => seller.id !== sellerId
      );
      if (returnedRequestedSellers) {
        returnedRequestedSellers = returnedRequestedSellers.map(
          (item, index) => {
            const pageStart = (page - 1) * limit;
            return {
              ...item,
              serialNumber: pageStart + index + 1,
            };
          }
        );
        return returnedRequestedSellers;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getRequestedSellers = createAsyncThunk(
  "users/getRequestedSellers",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().users;
    try {
      const response = await httpv2.get(`/users/admin`, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          role: data.role ?? "requestedSeller",
          ...data,
        },
      });
      const responseData = getResponseData(response);

      if (responseData?.results) {
        responseData.results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const approveRequestedSeller = createAsyncThunk(
  "users/approveRequestedSeller",
  async (sellerId, { getState, rejectWithValue }) => {
    const { requestedSellers, page, limit } = getState().users;
    try {
      await http.post(`/users/accept-requested-seller`, {
        userId: sellerId,
      });
      toast.success("Approved successfully");
      let returnedRequestedSellers = requestedSellers.filter(
        (seller) => seller.id !== sellerId
      );
      if (returnedRequestedSellers) {
        returnedRequestedSellers = returnedRequestedSellers.map(
          (item, index) => {
            const pageStart = (page - 1) * limit;
            return {
              ...item,
              serialNumber: pageStart + index + 1,
            };
          }
        );
        return returnedRequestedSellers;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteRequestedSeller = createAsyncThunk(
  "users/deleteRequestedSeller",
  async (sellerId, { getState, rejectWithValue }) => {
    const { requestedSellers, page, limit } = getState().users;
    try {
      await http.delete(`/users/${sellerId}`);
      toast.success("Deleted successfully");
      let returnedRequestedSellers = requestedSellers.filter(
        (seller) => seller.id !== sellerId
      );
      if (returnedRequestedSellers) {
        returnedRequestedSellers = returnedRequestedSellers.map(
          (item, index) => {
            const pageStart = (page - 1) * limit;
            return {
              ...item,
              serialNumber: pageStart + index + 1,
            };
          }
        );
        return returnedRequestedSellers;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getSellers = createAsyncThunk(
  "users/getSellers",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().users;
    try {
      const response = await httpv2.get(`/users/admin`, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          role: "supplier",
          ...data,
        },
      });
      const responseData = getResponseData(response);

      if (responseData?.results) {
        responseData.results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteSeller = createAsyncThunk(
  "users/deleteSeller",
  async (sellerId, { getState, rejectWithValue }) => {
    const { sellers, page, limit } = getState().users;
    try {
      await http.delete(`/users/${sellerId}`);
      toast.success("Deleted successfully");
      let returnedRequestedSellers = sellers.filter(
        (seller) => seller.id !== sellerId
      );
      if (returnedRequestedSellers) {
        returnedRequestedSellers = returnedRequestedSellers.map(
          (item, index) => {
            const pageStart = (page - 1) * limit;
            return {
              ...item,
              serialNumber: pageStart + index + 1,
            };
          }
        );
        return returnedRequestedSellers;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    requestedSellers: [],
    sellers: [],
    loading: true,
    users: [],
    user: {},
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.loading = true;
      state.users = [];
      state.user = {};
      state.requestedSellers = [];
      state.sellers = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = null;
    },
    resetUserState: (state) => {
      state.user = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        if (action.payload.results) {
          state.users = action.payload.results;
          state.totalResults = action.payload.totalResults;
          state.totalPages = action.payload.totalPages;
          state.page = action.payload.page;
          state.limit = action.payload.limit;
        } else {
          state.users = [];
          state.totalResults = 0;
          state.totalPages = 0;
        }
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserPassword.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.users = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getRequestedSellers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRequestedSellers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        if (action.payload.results) {
          state.requestedSellers = action.payload.results;
          state.totalResults = action.payload.totalResults;
          state.totalPages = action.payload.totalPages;
          state.page = action.payload.page;
          state.limit = action.payload.limit;
        } else {
          state.requestedSellers = [];
          state.totalResults = 0;
          state.totalPages = 0;
        }
      })
      .addCase(getRequestedSellers.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(deleteRequestedSeller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRequestedSeller.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.requestedSellers = action.payload;
      })
      .addCase(deleteRequestedSeller.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(approveRequestedSeller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveRequestedSeller.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.requestedSellers = action.payload;
      })
      .addCase(approveRequestedSeller.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getSellers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSellers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        if (action.payload.results) {
          state.sellers = action.payload.results;
          state.totalResults = action.payload.totalResults;
          state.totalPages = action.payload.totalPages;
          state.page = action.payload.page;
          state.limit = action.payload.limit;
        } else {
          state.sellers = [];
          state.totalResults = 0;
          state.totalPages = 0;
        }
      })
      .addCase(getSellers.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(deleteSeller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSeller.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.sellers = action.payload;
      })
      .addCase(deleteSeller.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetUserState } = usersSlice.actions;

// Exporting default reducer
export default usersSlice.reducer;
