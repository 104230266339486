import axios from "axios";
import interceptorConfiguration from "./interceptorConfiguration";

const httpv2 = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_V2,
});

interceptorConfiguration(httpv2);

export default httpv2;
