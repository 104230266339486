import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import streamingHttpClient from "utils/httpRequest/streamingHttpClient";

export const getVideos = createAsyncThunk(
  "videos/getVideos",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().videos;
    const { userRole, id } = getState().auth;
    const url =
      userRole === "admin"
        ? "/getRecordedVideo"
        : `getrecordedvideobyuserid?userId=${id}`;

    try {
      const response = await streamingHttpClient.get(url, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          ...data,
        },
      });
      const responseData = getResponseData(response);
      if (responseData?.results) {
        responseData.results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const addFeaturedVideo = createAsyncThunk(
  "videos/addFeaturedVideo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await streamingHttpClient.post(`/feature`, null, {
        params: {
          videoId: data.id,
          featured: data.featured,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const removeFeaturedVideo = createAsyncThunk(
  "videos/removeFeaturedVideo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await streamingHttpClient.post(`/feature`, null, {
        params: {
          videoId: data.id,
          featured: data.featured,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const responseFacebook = createAsyncThunk(
  "videos/responseFacebook",
  async (data, { dispatch, rejectWithValue }) => {
    let accessToken = data.accessToken;
    try {
      const response = await streamingHttpClient.patch(
        `/facebook-streaming/views`,
        {
          token: accessToken,
        }
      );
      const responseData = getResponseData(response);
      if (responseData?.status === 200 && responseData?.isSuccess) {
        dispatch(getVideos());
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const saveUploadVideo = createAsyncThunk(
  "videos/saveUploadVideo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_STREAMING_BASE_URL + `/vod/save-upload-video`,
        data
      );
      const responseData = getResponseData(response);
      if ((responseData?.status || response.status) === 200) {
        toast.success("Video Uploaded successfully");
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getVideoBySlug = createAsyncThunk(
  "videos/getVideoBySlug",
  async (slug, { getState, rejectWithValue }) => {
    const results = getState().videos.results;
    const _video = results.find((video) => video.slug === slug);
    if (_video) {
      return _video;
    }
    const url = `/getbyslug`;
    try {
      const response = await streamingHttpClient.post(url, {
        slug: slug,
      });
      const responseData = getResponseData(response);
      return responseData?.streaming;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getSignature = createAsyncThunk(
  "videos/getSignature",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_STREAMING_BASE_URL + `/vod/token`,
        {
          token: localStorage.getItem("token"),
        }
      );
      const responseData = getResponseData(response);
      return responseData.result;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const updateVideo = createAsyncThunk(
  "videos/updateVideo",
  async (updatedValues = {}, { rejectWithValue }) => {
    const id = updatedValues._id;
    const url = `/updatevideo/${id}`;
    try {
      const response = await streamingHttpClient.post(
        url,
        updatedValues?.formData ? updatedValues?.formData : updatedValues?.data
      );
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteVideo = createAsyncThunk(
  "videos/deleteVideo",
  async (videoId, { getState, rejectWithValue }) => {
    const { results } = getState().videos;
    try {
      const response = await streamingHttpClient.delete(`/${videoId}`);
      if (response.status === 200) {
        const returnedData = results.filter((data) => data.id !== videoId);
        toast.success("Successfully deleted");
        return returnedData;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

const videosSlice = createSlice({
  name: "videos",
  initialState: {
    videoUploading: false,
    loading: false,
    video: {},
    results: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    videoUploadError: "",
    error: null,
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = false;
      state.video = {};
      state.results = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = null;
    },
    resetVideoUploadState: (state) => {
      state.videoUploading = false;
      state.videoUploadError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVideos.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getVideos.fulfilled, (state, action) => {
        if (action.payload) {
          state.results = action.payload.results;
          state.totalResults = action.payload.totalResults;
          state.totalPages = action.payload.totalPages;
          state.page = action.payload.page;
          state.limit = action.payload.limit;
        } else {
          state.results = [];
          state.totalResults = 0;
          state.totalPages = 0;
        }
        state.loading = false;
      })
      .addCase(getVideos.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(deleteVideo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSignature.pending, (state) => {
        state.videoUploading = true;
      })
      .addCase(getSignature.fulfilled, (state, action) => {
        state.videoUploading = true;
        state.groupBuyProduct = action.payload;
      })
      .addCase(getSignature.rejected, (state, action) => {
        state.videoUploading = false;
        state.videoUploadError = action.payload;
      })
      .addCase(saveUploadVideo.pending, (state) => {
        state.videoUploading = true;
      })
      .addCase(saveUploadVideo.fulfilled, (state, action) => {
        state.videoUploading = false;
        state.groupBuyProduct = action.payload;
      })
      .addCase(saveUploadVideo.rejected, (state, action) => {
        state.videoUploading = false;
        state.videoUploadError = action.payload;
      })
      .addCase(getVideoBySlug.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVideoBySlug.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        if (action.payload) state.video = action.payload;
      })
      .addCase(getVideoBySlug.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateVideo.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState } = videosSlice.actions;

// Exporting default reducer
export default videosSlice.reducer;
