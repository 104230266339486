import TextField from "@mui/material/TextField";
import styled from "@mui/material/styles/styled";
import { memo } from "react";

const StyledTextField = styled(TextField)({
  "& .MuiFormHelperText-root": {
    fontSize: "0.9rem",
    marginLeft: 0,
  },
});
export default memo(StyledTextField);
