import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export default function AddActions() {
  const navigate = useNavigate();

  return (
    <Box py={2} sx={containerStyles}>
      <Button
        sx={{
          minWidth: 170,
          maxWidth: 180,
        }}
        startIcon={<AddIcon />}
        variant="contained"
        className="AddButtons"
        onClick={() => navigate("create")}
      >
        Add Group buy
      </Button>
    </Box>
  );
}
const containerStyles = {
  display: "flex",
  gap: 2,
  "& .AddButtons": {
    height: "44px",
    textTransform: "none",
    fontSize: {
      xs: "0.8rem",
      sm: "1rem",
    },
    cursor: "pointer",
  },
};
