import AttachFileIcon from "@mui/icons-material/AttachFile";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import { uploadChatAttachments } from "services/chatService";
import { getErrorMessage } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";

const allowedChatMediaExtensions = /(\.jpg|\.jpeg|\.png|\.mp4)$/i;

const Attachments = ({ roomId, receiverId }) => {
  const [isUploading, setIsUploading] = useState(false);
  const uploadInputRef = useRef();

  const handleSelect = useCallback(
    async (e) => {
      const formData = new FormData();
      formData.append("roomId", roomId);
      formData.append("receiverId", receiverId);
      const maxFileSizes = 367001600; // bytes
      let size = 0;
      Array.from(e.target.files).forEach((file) => {
        size = size + file.size;
        formData.append("chatMedia", file, file.name);
      });
      if (size > maxFileSizes) {
        uploadInputRef.current.value = "";
        return toast.error("Files exceeds maximum size of 350MB");
      }
      if (allowedChatMediaExtensions.exec(e.target.value)) {
        setIsUploading(true);
        try {
          await uploadChatAttachments(formData);
        } catch (error) {
          const message = getErrorMessage(error);
          toast.error(message);
        } finally {
          uploadInputRef.current.value = "";
          setIsUploading(false);
        }
      } else {
        return toast.error("Only jpg/jpeg/png and mp4 files are allowed!");
      }
    },
    [receiverId, roomId]
  );

  return (
    <>
      <Button
        sx={{
          padding: "0",
          minWidth: "20px",
          color: "#000000",
        }}
        component="span"
        disabled={isUploading}
        htmlFor="attachment"
        onClick={() => uploadInputRef.current.click()}
      >
        {isUploading ? (
          <CircularProgress size="20px" />
        ) : (
          <AttachFileIcon
            sx={{ transform: "rotate(222deg)", color: "#00000099" }}
          />
        )}
      </Button>
      <input
        onChange={handleSelect}
        type="file"
        name="attachment"
        multiple
        hidden
        accept="image/png, image/jpeg, image/jpg, video/mp4"
        id="attachment"
        ref={uploadInputRef}
      />
    </>
  );
};

Attachments.propTypes = {
  receiverId: PropTypes.string,
  roomId: PropTypes.string,
};

export default memo(Attachments);
