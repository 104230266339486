import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CategoryDescriptionEditor from "components/Category/CategoryForm/CategoryDescriptionEditor";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { memo } from "react";

function CategoryDescriptionSection({ formik }) {
  return (
    <StyledMuiCard
      title="Category Details"
      subTitle="Enter  description and specifications. Tip: More details of product will increase the sales"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={2}
          p={{ xs: 1, md: 1.5 }}
        >
          <Box>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Description
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              fontSize="1rem"
              paddingBottom={1}
            >
              Enter product details with specifications. Tip: Use bullet points
              for easy viewing
            </Typography>
          </Box>
          <Box>
            <Card>
              <CategoryDescriptionEditor formik={formik} />
            </Card>
            {formik.errors.description && (
              <Typography color="error.light" pt={1} fontSize="1rem">
                {formik.errors.description}
              </Typography>
            )}
          </Box>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}
CategoryDescriptionSection.propTypes = {
  formik: PropTypes.object.isRequired,
};
export default memo(CategoryDescriptionSection);
