import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Children, memo, useCallback, useMemo, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { BACKGROUND, PRIMARY } from "styles/colors";
import isImage from "utils/helpers/isImage";

const Media = ({ media, isReply }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = useCallback(() => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  }, []);

  const _isImage = useMemo(() => isImage(media), [media]);
  return (
    <>
      <Box
        sx={{
          padding: "5px",
          marginTop: "5px",
          lineHeight: "0",
          minHeight: _isImage ? 200 : 115,
          minWidth: 200,
          display: "flex",
          flexDirection: "column",
          gap: 0.75,
        }}
      >
        {Children.toArray(
          media.map((media, index) =>
            _isImage ? (
              <img
                src={media}
                onClick={() => openImageViewer(index)}
                alt="attachment"
                width={200}
                height={200}
                style={{
                  objectFit: "cover",
                  border: `4px solid ${isReply ? BACKGROUND : PRIMARY}`,
                  borderRadius: "8px",
                }}
              />
            ) : (
              <video
                src={media}
                controls
                width={200}
                height={115}
                style={{
                  border: `4px solid ${isReply ? BACKGROUND : PRIMARY}`,
                  borderRadius: "8px",
                }}
              />
            )
          )
        )}
      </Box>
      {isViewerOpen && (
        <ImageViewer
          src={media}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.8)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  );
};

Media.propTypes = {
  isReply: PropTypes.bool,
  media: PropTypes.array,
};

export default memo(Media);
