import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminProducts } from "store/slices/productsSlice";
import { FILTERS_BORDER_COLOR, SEARCH_ICON_COLOR } from "styles/colors";
import useDebounce from "utils/hooks/useDebounceValue";

function CustomProductSearch() {
  const dispatch = useDispatch();
  const { page, limit } = useSelector((state) => state.products);
  const [searchValue, setSearchValue] = useState("");
  const deferredValue = useDebounce(searchValue, 700);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (deferredValue.length < 3) {
        dispatch(getAdminProducts({ page, limit }));
      } else {
        dispatch(
          getAdminProducts({
            page,
            limit,
            name: "productName",
            value: deferredValue,
          })
        );
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, [deferredValue, dispatch, limit, page]);

  const SearchValue = useCallback((value) => {
    return setSearchValue(value.trimStart());
  }, []);

  return (
    <Box sx={containerStyles}>
      <TextField
        id="outlined-search"
        label="Search"
        type="search"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: SEARCH_ICON_COLOR }} />
            </InputAdornment>
          ),
        }}
        value={searchValue}
        onChange={(e) => {
          SearchValue(e.target.value);
        }}
      />
    </Box>
  );
}

export default memo(CustomProductSearch);

CustomProductSearch.propTypes = {
  searchKeyParam: PropTypes.string,
  searchKeyParamValue: PropTypes.string,
  searchQueryParam: PropTypes.string.isRequired,
};

const containerStyles = {
  textAlign: "end",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
      borderRadius: "20px",
    },
    "&:hover": {
      backgroundColor: "#edf8fd",
      "& fieldset": {
        borderColor: FILTERS_BORDER_COLOR,
      },
    },
  },
  "&:hover": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
  },
};
