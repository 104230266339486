import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DataGridSearch from "components/DataGridSearch";
import { memo } from "react";
import StoreFilters from "./StoreFilters";

function StoreHeaderActions() {
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" mb={1}>
      <StoreFilters />
      <Stack
        spacing={2}
        direction="row"
        flexWrap="wrap"
        justifyContent="flex-end"
      >
        <DataGridSearch
          searchKeyParam="name"
          searchKeyParamValue="brandName"
          searchQueryParam="value"
        />
      </Stack>
    </Box>
  );
}

export default memo(StoreHeaderActions);
