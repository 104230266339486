import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactCodeInput from "react-verification-code-input";
import { sendEmailOTP } from "services/authServices";
import { verifyEmail } from "store/slices/authSlice";
import { PRIMARY } from "styles/colors";
import EditEmail from "./EditEmail";

function EmailVerification() {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.data.user.email ?? "");
  const [emailOTP, setEmailOTP] = useState("");
  const [time, setTime] = useState(120);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editEmail, setEditEmail] = useState(false);

  const sendOTP = useCallback(() => {
    setError("");
    sendEmailOTP(email, setError, setIsSubmitting);
  }, [email]);

  const resendOTP = useCallback(() => {
    setTime(120);
    setEmailOTP("");
    sendOTP();
  }, [sendOTP]);

  const verifyOTP = useCallback(
    (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      dispatch(verifyEmail(emailOTP))
        .unwrap()
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [dispatch, emailOTP]
  );

  const toggleEditEmail = useCallback(() => {
    setEditEmail((editEmail) => !editEmail);
  }, []);

  useEffect(() => {
    let interval;
    if (time > 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    }
    return () => interval && clearInterval(interval);
  }, [time]);

  if (editEmail) return <EditEmail toggleEditEmail={toggleEditEmail} />;

  return (
    <form onSubmit={verifyOTP}>
      <Stack direction="column" gap={0.5}>
        <Box>
          <ReactCodeInput
            type="number"
            onChange={setEmailOTP}
            className="code-input"
          />
          <Typography
            variant="body1"
            color="text.secondary"
            fontSize={{ lg: "1.15rem" }}
          >
            {`Please enter the verification code sent on ${email}. `}
            <span
              onClick={toggleEditEmail}
              style={{
                color: PRIMARY,
                fontSize: "1rem",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              Edit Email
            </span>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography color="text.secondary" fontSize={{ lg: "1.15rem" }}>
            {`Didn’t receive code?`}
          </Typography>
          <Button
            variant="text"
            onClick={time > 0 ? null : resendOTP}
            disabled={time > 0}
            sx={{
              fontWeight: "bold",
              textTransform: "none",
              fontSize: { lg: "1.15rem" },
            }}
          >
            Resend Code
            {time > 0 && ` in ${time} seconds`}
          </Button>
        </Box>
        {error && (
          <Typography color="error" fontSize={{ lg: "1.15rem" }} mb={1}>
            {error}
          </Typography>
        )}
        <Button
          fullWidth
          disabled={isSubmitting || emailOTP.length < 6}
          type="submit"
          variant="contained"
          color="primary"
          startIcon={
            isSubmitting && <CircularProgress size={20} thickness={6} />
          }
          sx={{ minHeight: { md: 44, xl: 56 } }}
        >
          Verify Email
        </Button>
      </Stack>
    </form>
  );
}

export default memo(EmailVerification);
