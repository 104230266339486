import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { placeAdminOrder } from "store/slices/checkoutSlice";
import { CARD_HEADER_BACKGROUND } from "styles/colors";
import { MOBILE_NETWORK_CODES } from "utils/constants/networkCodes";
import formatPhoneNumber from "utils/helpers/formatPhoneNumber";
import * as Yup from "yup";
import Cart from "../Cart/Cart";
import AddressSection from "./AddressSection";
import ContactUs from "./ContactUs";
import OrderProductList from "./OrderProductList";
import OrderProductSearch from "./OrderProductSearch";
import PaymentMethod from "./PaymentMethod";
import Shipment from "./Shipment";
import Summary from "./Summary";

function AddAdminOrderForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartId = useSelector((state) => state.cart?.cartData?.id ?? "");
  const userId = useSelector((state) => state.cart.userId);
  const userRole = useSelector((state) => state.auth?.userRole);
  const packageItems = useSelector((state) => state.cart.packageItems || []);
  const isSubmitting = useSelector((state) => state.checkout.isSubmitting);
  const discountedProducts = useSelector(
    (state) => state.cart.adminDiscountProducts
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [number, setNumber] = useState("");
  const [networkCode, setNetworkCode] = useState("0300");

  const handleNetworkCode = useCallback((e) => {
    setNetworkCode(e.target.value);
  }, []);

  const initialValues = useMemo(
    () => ({
      phone: "",
      orderNote: "",
      // for advance payment functionality
      // advancePayment: "",
      amount: 0,
    }),
    []
  );

  const handlePhoneNumber = useCallback(() => {
    setPhoneNumber(formatPhoneNumber(networkCode + number));
  }, [networkCode, number]);

  const handleSubmit = useCallback(
    (values) => {
      let data = {
        cart: cartId,
        userId: userId,
        orderNote: values?.orderNote,
        amount: values?.amount ?? 0,
        origin: { source: "web-desktop" },
      };
      if (
        userRole === "admin" &&
        packageItems &&
        discountedProducts.length > 0
      ) {
        const discount = discountedProducts.filter((product) => product.amount);
        data.adminDiscount = discount;
      }
      dispatch(placeAdminOrder(data))
        .unwrap()
        .then(() => {
          return navigate("/orders");
        });
    },
    [
      cartId,
      discountedProducts,
      dispatch,
      navigate,
      packageItems,
      userId,
      userRole,
    ]
  );

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          ORDERS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        paddingBottom={2}
      >
        Create Order
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              {/* Phone Number Section */}
              <Grid item xs={12} lg={6}>
                <Card
                  variant="outlined"
                  sx={{
                    borderRadius: 1.5,
                    height: "350px",
                  }}
                >
                  <CardHeader
                    title="Basic Information"
                    subheader="Please enter the basic information of the order such as number"
                    sx={{
                      bgcolor: CARD_HEADER_BACKGROUND,
                      "& .MuiCardHeader-subheader": {
                        fontSize: "0.9rem",
                      },
                      ".MuiCardHeader-action": {
                        margin: "auto",
                      },
                    }}
                  />
                  <CardContent>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      gap={3}
                      p={{ xs: 1, md: 1.5 }}
                    >
                      <Stack direction="column" gap={1}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Phone Number
                        </Typography>
                        <Grid container>
                          <Grid item xs={3} lg={3}>
                            <Select
                              fullWidth
                              onChange={handleNetworkCode}
                              value={networkCode}
                              disabled={!!phoneNumber}
                              sx={{ borderRadius: 0 }}
                            >
                              {MOBILE_NETWORK_CODES.map((option) => (
                                <MenuItem
                                  sx={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                  }}
                                  key={option.code}
                                  dense
                                  value={option.code}
                                >
                                  {option.code}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={9} lg={9}>
                            <StyledTextField
                              fullWidth
                              name="phone"
                              type="tel"
                              placeholder="Enter phone number"
                              value={formik.values.phone}
                              onBlur={formik.handleBlur}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                maxLength: 7,
                                minLength: 7,
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "0 6px 6px 0",
                                  backgroundColor: "#f6f6f6",
                                },
                              }}
                              disabled={!!phoneNumber}
                              onChange={(e) => {
                                setNumber(e.target.value);
                                formik.setFieldValue("phone", e.target.value);
                              }}
                              error={
                                !!formik.touched.phone && !!formik.errors.phone
                              }
                              helperText={
                                formik.touched.phone && !!formik.errors.phone
                                  ? formik.errors.phone
                                  : "Enter a valid mobile number to create order"
                              }
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                      <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={handlePhoneNumber}
                        disabled={
                          !!(formik.touched.phone && formik.errors.phone) ||
                          !!phoneNumber ||
                          !number
                        }
                        sx={{
                          ml: "auto",
                          fontWeight: "bold",
                          width: { md: 100, lg: 175, xl: 250 },
                          height: { xs: 50, xl: 55 },
                        }}
                      >
                        Continue
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              {phoneNumber && (
                <>
                  {/* Address Section */}
                  <Grid item xs={12} lg={6}>
                    <AddressSection formik={formik} phoneNumber={phoneNumber} />
                  </Grid>
                  {/* Product List Section */}

                  <Grid item xs={12} lg={12}>
                    <OrderProductSearch />
                    <OrderProductList
                      formik={formik}
                      phoneNumber={phoneNumber}
                    />
                  </Grid>
                  {/* Product Cart Section */}
                  <Grid item xs={12} lg={12}>
                    <Cart phoneNumber={phoneNumber} />
                  </Grid>
                  {/* Shipping Method Section */}
                  <Grid item xs={12} lg={12}>
                    <Shipment />
                  </Grid>
                  {/* Payment Method Section */}
                  <Grid item xs={12} lg={12}>
                    <PaymentMethod formik={formik} />
                  </Grid>
                  {/* Order Summary Section */}
                  <Grid item xs={12} lg={12}>
                    <Summary />
                  </Grid>
                  {/* Need Help Section */}
                  <Grid item xs={6} lg={6}>
                    <ContactUs />
                  </Grid>
                  {/* Place Order Button */}
                  <Grid item xs={6} lg={6} textAlign="end" paddingTop="30px">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disableElevation
                      sx={{
                        fontWeight: "bold",
                        width: "220px",
                        fontSize: { sm: "0.5rem", md: "1rem", xl: "1rem" },
                      }}
                      disabled={
                        // formik.isSubmitting ||
                        // !formik.isValid ||
                        // !formik.dirty ||
                        isSubmitting
                      }
                    >
                      Place Order
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(AddAdminOrderForm);

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Required*")
    .min(7, "Phone must be at least 7 digits")
    .max(7, "Phone must be at most 7 digits")
    .matches(/^[+0-9]*$/, "Invalid phone number"),
  orderNote: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Note must be at least 10"),
});
