import AddCardIcon from "@mui/icons-material/AddCard";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PersonIcon from "@mui/icons-material/Person";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import TourIcon from "@mui/icons-material/Tour";
import WebIcon from "@mui/icons-material/Web";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import NumberFormatter from "components/NumberFormatter";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { STATS_NUMBER_FORMATTER_COLOR } from "styles/colors";
import splitCamelCase, { isCamelCase } from "utils/helpers/splitCamelCase";

function AnalyticCards({ data }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const imageIcon = useCallback((title) => {
    switch (title) {
      case "activeUsers":
        return <PeopleIcon />;
      case "addToCarts":
        return <AddShoppingCartIcon />;
      case "averagePurchaseRevenue":
        return <ShoppingCartIcon />;
      case "cartToViewRate":
        return <ProductionQuantityLimitsIcon />;
      case "checkouts":
        return <ShoppingCartCheckoutIcon />;
      case "ecommercePurchases":
        return <AddCardIcon />;
      case "newUsers":
        return <PersonIcon />;
      case "screenPageViews":
        return <WebIcon />;
      case "sessionsPerUser":
        return <TourIcon />;
      case "shippingAmount":
        return <LocalShippingIcon />;
      case "taxAmount":
        return <PointOfSaleIcon />;
      case "totalAdRevenue":
        return <LocalAtmIcon />;
      case "totalPurchasers":
        return <SensorOccupiedIcon />;
      case "totalRevenue":
        return <PaymentsIcon />;
      case "totalUsers":
        return <PeopleOutlineIcon />;
      case "conversions:add_to_cart":
        return <AddShoppingCartIcon />;
      case "addToWishlist":
        return <ShoppingBagIcon />;
      default:
        return;
    }
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ " &.MuiGrid-container": { mt: 0 } }}>
        {Object.keys(data)
          .filter((keyword) => isCamelCase(keyword))
          .map((keyword, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
              <Card
                sx={{
                  p: "1.5rem",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    minHeight: {
                      xs: "120px",
                      md: "120px",
                      lg: "150px",
                      xxl: "210px",
                    },
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      component="h3"
                      fontWeight="bold"
                      color="#3f3f3f"
                      sx={{
                        fontSize: {
                          xs: "0.65rem",
                          md: "1rem",
                          lg: "1.15rem",
                          xxl: "1.5rem",
                        },
                      }}
                    >
                      {splitCamelCase(keyword)}
                    </Typography>
                    <NumberFormatter
                      sx={{
                        fontSize: {
                          xs: "1.5rem",
                          md: "3rem",
                          lg: "2rem",
                          xxl: "2.5rem",
                        },
                        fontWeight: "bold",
                        color: STATS_NUMBER_FORMATTER_COLOR,
                        height: { md: "55px", lg: "30px", xxl: "55px" },
                      }}
                      number={Math.round(Number(data[keyword]) ?? 0)}
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#eafafd"
                    borderRadius="50%"
                    minWidth={{
                      sm: "70px",
                      md: isOpen ? "70px" : "115px",
                      lg: "70px",
                      xxl: "115px",
                    }}
                    height="100%"
                    minHeight={{
                      sm: "70px",
                      md: isOpen ? "70px" : "115px",
                      lg: "70px",
                      xxl: "115px",
                    }}
                    width="auto"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: {
                          xs: "2.5rem",
                          md: "3rem",
                          lg: "2rem",
                          xxl: "3.25rem",
                        },
                        color: "primary.main",
                      },
                    }}
                  >
                    {imageIcon(keyword)}
                  </Box>
                </Stack>
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  );
}

export default AnalyticCards;

AnalyticCards.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};
