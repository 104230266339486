import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadCollectionImage } from "services/Collection";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const getCollection = createAsyncThunk(
  "getCollection",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().collection;
    try {
      const response = await http.get(`/collection?`, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      if (responseData?.results) {
        responseData.results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const deleteCollection = createAsyncThunk(
  "deleteCollection",
  async (collectionId, { getState, rejectWithValue }) => {
    const { results } = getState().collection;
    try {
      const response = await http.delete(`collection/${collectionId}`);
      const responseData = getResponseData(response);
      if (responseData?.status === 200) {
        toast.success(responseData?.message);
        const returnedData = results.filter((data) => data.id !== collectionId);
        return returnedData;
      }
      if (responseData?.status !== 200) {
        toast.error(responseData?.message);
        return results;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);
export const getCollectionById = createAsyncThunk(
  "collections/getCollectionById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await http.get(`/collection/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const submitCollection = createAsyncThunk(
  "collections/submitCollection",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/collection`, data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateCollection = createAsyncThunk(
  "collections/updateCollection",
  async (values = {}, { rejectWithValue }) => {
    const { id, ...data } = values;
    let _data = {
      ...(data?.name && { name: data.name }),
      ...(data?.description && { description: data.description }),
      ...(data?.expireDate && { expireDate: data.expireDate }),
      ...(data?.lang && { lang: data.lang }),
    };
    try {
      const response = await http.patch(`/collection/${id}`, _data);
      const responseData = getResponseData(response);
      if (typeof values.collectionImage === "object") {
        await uploadCollectionImage(
          values.id,
          data.collectionImage,
          "collectionImage"
        );
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateCollectionProducts = createAsyncThunk(
  "collections/updateCollectionProducts",
  async (values = {}, { rejectWithValue }) => {
    const { id, ...data } = values;
    let _data = {
      products: data?.data ? data?.data : [],
    };
    try {
      const response = await http.patch(`/collection/${id}`, _data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteCollectionProducts = createAsyncThunk(
  "collections/deleteCollectionProducts",
  async (values = {}, { rejectWithValue }) => {
    const { id, ...products } = values;
    try {
      const response = await http.patch(`/collection/${id}`, products);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const uploadCollectionCsv = createAsyncThunk(
  "collections/uploadCsv",
  async (data, { rejectWithValue }) => {
    try {
      let formData = new FormData();
      formData.append("csvFile", data.csvFile);
      const response = await http.post(`/collection/csv/${data.id}`, formData);
      const responseData = getResponseData(response);
      if (responseData?.isSuccess === true) {
        toast.success(responseData?.message);
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);
const collectionSlice = createSlice({
  name: "collection",
  initialState: {
    loading: true,
    collection: null,
    results: [],
    queryParams: {},
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    collectionCsv: null,
    error: "",
    collectionLoading: false,
    collectionError: null,
  },
  reducers: {
    // Actions
    resetCollectionState: (state) => {
      state.loading = true;
      state.collection = null;
      state.results = [];
      state.queryParams = {};
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.collectionCsv = null;
      state.error = "";
      state.collectionLoading = false;
      state.collectionError = null;
    },
    resetCollection: (state) => {
      state.loading = false;
      state.collection = null;
      state.collectionError = null;
    },
    setCollection: (state, action) => {
      state.collection = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollection.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCollection.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getCollection.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteCollection.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCollection.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(deleteCollection.rejected, (state) => {
        state.loading = false;
      })
      .addCase(submitCollection.pending, (state) => {
        state.collectionLoading = true;
      })
      .addCase(submitCollection.fulfilled, (state, action) => {
        state.collectionLoading = false;
        state.collection = action.payload;
      })
      .addCase(submitCollection.rejected, (state, action) => {
        state.collectionLoading = false;
        state.collectionError = action.payload;
      })
      .addCase(updateCollection.pending, (state) => {
        state.collectionLoading = true;
      })
      .addCase(updateCollection.fulfilled, (state, action) => {
        state.collectionLoading = false;
        state.collection = action.payload;
      })
      .addCase(updateCollection.rejected, (state, action) => {
        state.collectionLoading = false;
        state.collectionError = action.payload;
      })
      .addCase(getCollectionById.pending, (state) => {
        state.loading = true;
        state.collectionError = false;
      })
      .addCase(getCollectionById.fulfilled, (state, action) => {
        state.collection = action.payload[0];
        state.loading = false;
      })
      .addCase(getCollectionById.rejected, (state) => {
        state.loading = false;
        state.collectionError = true;
      })
      .addCase(uploadCollectionCsv.fulfilled, (state, action) => {
        state.collectionLoading = false;
        state.collectionCsv = action.payload;
      })
      .addCase(uploadCollectionCsv.rejected, (state, action) => {
        state.collectionLoading = false;
        state.collectionError = action.payload;
      })
      .addCase(uploadCollectionCsv.pending, (state) => {
        state.collectionLoading = true;
      })
      .addCase(updateCollectionProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCollectionProducts.fulfilled, (state, action) => {
        state.collection = action?.payload;
        state.loading = false;
      })
      .addCase(updateCollectionProducts.rejected, (state, action) => {
        state.collectionError = action.payload;
      })
      .addCase(deleteCollectionProducts.pending, (state) => {
        state.collectionLoading = true;
        state.loading = true;
      })
      .addCase(deleteCollectionProducts.fulfilled, (state, action) => {
        state.collection = action?.payload;
        state.collectionLoading = false;
        state.loading = false;
      });
  },
});

// Action creators generated for each case reducer function
export const {
  resetCollection,
  setCollection,
  resetCollectionState,
  addQueryParams,
} = collectionSlice.actions;

// Exporting default reducer
export default collectionSlice.reducer;
