import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import flatCategoriesArray from "utils/helpers/flatCategoriesArray";
import http from "utils/httpRequest/http";

export const getStores = createAsyncThunk(
  "filters/getStores",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get(`/sellerDetail`, {
        params: {
          ...data,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getAllMarkets = createAsyncThunk(
  "filters/getAllMarkets",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get(`markets/getAllMarkets`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getAllCities = createAsyncThunk(
  "filters/getAllCities",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get(`address/cities`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getCategories = createAsyncThunk(
  "filters/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(`/categories/getAllCategories`);
      const responseData = getResponseData(response);
      const categories = responseData || responseData?.results;
      const flattenedCategories = flatCategoriesArray(categories);
      return { categories, flattenedCategories };
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    stores: {},
    categories: [],
    flattenedCategories: [],
    markets: [],
    cities: [],
    loading: true,
    error: "",
  },
  reducers: {
    resetState: (state) => {
      state.stores = [];
      state.categories = [];
      state.loading = true;
      state.error = false;
      state.stores = [];
      state.markets = [];
      state.cities = [];
      state.categories = [];
      state.flattenedCategories = [];
      state.loading = true;
      state.error = false;
    },
    resetStoreState: (state) => {
      state.stores = null;
    },
    addQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStores.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getStores.fulfilled, (state, action) => {
        state.stores = action.payload.results;
        state.loading = false;
      })
      .addCase(getStores.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action?.payload?.categories;
        state.flattenedCategories = action?.payload?.flattenedCategories;
        state.loading = false;
      })
      .addCase(getCategories.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAllMarkets.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllMarkets.fulfilled, (state, action) => {
        state.markets = action.payload;
        state.loading = false;
      })
      .addCase(getAllMarkets.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAllCities.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.cities = action.payload;
        state.loading = false;
      })
      .addCase(getAllCities.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
export const { resetState, addQueryParams, resetStoreState } =
  filtersSlice.actions;
export default filtersSlice.reducer;
