import Box from "@mui/material/Box";
import { memo, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import {
  STATS_ORANGE_SECONDARY,
  STATS_PRIMARY_BLUE,
  STATS_PRIMARY_RED,
} from "styles/colors";

function BarChart() {
  const { monthlyAnalytics } = useSelector((state) => state.dashboard);

  const mobileSession = useMemo(() => {
    const customerApp = monthlyAnalytics?.users?.session?.["customer-app"] || 0;
    const sellerApp = monthlyAnalytics?.users?.session?.["seller-app"] || 0;
    return customerApp + sellerApp;
  }, [monthlyAnalytics?.users?.session]);

  const series = [
    {
      name: "Session",
      data: [
        { x: "Mobile", y: mobileSession },
        {
          x: "Desktop",
          y: monthlyAnalytics?.users?.session?.["web-desktop"],
        },
        { x: "Tablet", y: monthlyAnalytics?.users?.session?.null },
      ],
    },
  ];

  const colors = [
    STATS_PRIMARY_BLUE,
    STATS_ORANGE_SECONDARY,
    STATS_PRIMARY_RED,
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
  };

  return (
    <Box id="chart" p="1rem">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={240}
      />
    </Box>
  );
}

export default memo(BarChart);
