import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledSwitch from "components/ToggleButton";
import PropTypes from "prop-types";
import { CARD_HEADER_BACKGROUND } from "styles/colors";
function ProductActiveStatusSection({ formik }) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={2}
      sx={{
        px: { xs: 3, md: 3.5 },
        py: "1rem",
        bgcolor: CARD_HEADER_BACKGROUND,
        borderRadius: 1,
        border: "1px solid #BEBEBE",
        mb: 3,
      }}
    >
      <Stack direction="column" my="1px">
        <Typography variant="h5" color="#5e5873" fontWeight={600}>
          Active Status
        </Typography>
        <Typography variant="body1" fontSize="0.9rem" color="text.secondary">
          Make product active or inactive
        </Typography>
      </Stack>
      <StyledSwitch
        name="active"
        onChange={formik.handleChange}
        checked={formik.values.active}
      />
    </Stack>
  );
}

ProductActiveStatusSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default ProductActiveStatusSection;
