import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Actions from "components/SubBanners/Actions";
import BannerForm from "components/SubBanners/BannerForm";
import DataGrid from "components/SubBanners/DataGrid";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { memo } from "react";

const Banners = ({ create, update }) => {
  if (create || update) {
    return <BannerForm />;
  }

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          BANNERS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Actions />
      <DataGrid />
    </Box>
  );
};

export default memo(Banners);

Banners.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Banners.defaultProps = {
  create: false,
  update: false,
};
