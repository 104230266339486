import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductAddImagePlaceholder from "assets/icons/ProductAddImagePlaceholder.svg";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeGalleryImage,
  uploadGalleryImages,
  uploadMainImage,
} from "services/Product";
import { getProductById } from "store/slices/productsSlice";
import {
  setOpenVariantForm,
  setVariantId,
  setVariantOption,
} from "store/slices/variantsSlice";
import { getErrorMessage } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import GalleryImagePlaceholder from "../GalleryImagePlaceholder";

function MediaSection({ variant }) {
  const dispatch = useDispatch();
  const mainImageInputRef = useRef(null);
  const galleryImageRef = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const product = useSelector((state) => state.products.product);
  const variantTrue = Object.keys(variant).length !== 0;
  const variantId = useSelector((state) => state.variants?.variantId);

  const [mainImage, setMainImage] = useState(variant?.mainImage ?? "");
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImagesError, setGalleryImagesError] = useState("");

  const mainImageHandler = useCallback(
    (e) => {
      uploadMainImage(variantTrue ? variant?.id : variantId, e.target.files[0])
        .then((response) => {
          response?.data?.mainImage && setMainImage(response.data.mainImage);
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          toast.error(message);
        });
    },
    [variant?.id, variantId, variantTrue]
  );

  const galleryImageHandler = useCallback(
    (e) => {
      if (galleryImages?.length + e.target.files.length > 6) {
        return setGalleryImagesError("You can upload maximum 6 images");
      } else {
        uploadGalleryImages(
          variantTrue ? variant?.id : variantId,
          e.target.files
        )
          .then((response) => {
            setGalleryImages(response?.data?.data?.gallery);
          })
          .catch((error) => {
            const message = getErrorMessage(error);
            toast.error(message);
          });
      }
    },
    [galleryImages?.length, variant?.id, variantId, variantTrue]
  );

  const handleRemoveGalleryImage = useCallback(
    (index) => {
      removeGalleryImage(
        variantTrue ? variant?.id : variantId,
        galleryImages[index],
        index
      )
        .then((response) => {
          setGalleryImages(response?.data?.data?.gallery);
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          toast.error(message);
        });
    },
    [galleryImages, variant?.id, variantId, variantTrue]
  );

  const handleClickSave = useCallback(() => {
    if (variantTrue) {
      dispatch(setOpenVariantForm(null));
    } else {
      dispatch(setVariantOption(null));
      dispatch(setVariantId(""));
      dispatch(getProductById(product?.id));
    }
  }, [dispatch, product?.id, variantTrue]);

  useEffect(() => {
    setGalleryImages(variant?.gallery ?? []);
  }, [variant?.gallery]);

  return (
    <Box display="flex" flexWrap="wrap" gap={4} pt={2}>
      {/* Product Main Image */}
      <Stack direction="column" gap={2}>
        <Typography
          variant="h3"
          fontWeight="bold"
          fontSize="1.25rem"
          color="text.secondary"
        >
          Main Image
        </Typography>
        <Box>
          <img
            src={mainImage ? mainImage : ProductAddImagePlaceholder}
            alt="Product Main Media"
            height="auto"
            width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
            style={{ cursor: "pointer" }}
            onClick={() => mainImageInputRef.current.click()}
          />
          <input
            type="file"
            ref={mainImageInputRef}
            style={{ display: "none" }}
            id="update-product-image"
            hidden
            accept="image/x-png,image/png,image/jpeg,image/jpg"
            onChange={mainImageHandler}
          />
        </Box>
      </Stack>

      {/* Product Gallery Images */}
      <Stack
        direction="column"
        gap={2}
        height="100%"
        justifyContent="space-between"
      >
        <Stack direction="column" gap={2}>
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Gallery Images
          </Typography>
          <Box display="flex" flexDirection="row" gap={2}>
            {galleryImages?.length < 6 && (
              <img
                ref={galleryImageRef}
                src={ProductAddImagePlaceholder}
                alt="Add Product Gallery Placeholder"
                height="auto"
                width="150px"
                style={{ cursor: "pointer" }}
                onClick={() => galleryImageRef.current.click()}
              />
            )}
            {!galleryImages?.length
              ? [...Array(4)].map((e, i) => (
                  <Box key={i}>
                    <GalleryImagePlaceholder />
                  </Box>
                ))
              : galleryImages?.map((image, index) => (
                  <Stack direction="row" key={index} alignItems="flex-start">
                    <img src={image} alt="" height="auto" width="150px" />
                    <IconButton onClick={() => handleRemoveGalleryImage(index)}>
                      <RemoveCircle />
                    </IconButton>
                  </Stack>
                ))}
          </Box>
          {galleryImagesError && (
            <Typography variant="subtitle1" color="error">
              {galleryImagesError}
            </Typography>
          )}
          <input
            type="file"
            ref={galleryImageRef}
            style={{ display: "none" }}
            id="upload-product-gallery-images"
            hidden
            multiple
            accept="image/x-png,image/png,image/jpeg,image/jpg"
            onChange={galleryImageHandler}
          />
        </Stack>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "100px" }}
          disabled={mainImage ? false : true}
          onClick={handleClickSave}
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

MediaSection.propTypes = {
  variant: PropTypes.object.isRequired,
};

MediaSection.defaultProps = {
  variant: {},
};

export default memo(MediaSection);
