import styled from "@emotion/styled";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { recoverProfilePassword } from "services/authServices";
import { PRIMARY_LIGHT } from "styles/colors";
import * as Yup from "yup";

function RecoverPasswordForm() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ email }) => {
      recoverProfilePassword(email)
        .then(() => {
          setIsSuccess(true);
        })
        .catch((error) => {
          setError(error?.response?.data?.message ?? "Invalid Email");
          toast.error("Something went wrong");
        });
    },
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="column"
          alignContent="center"
          justifyContent="center"
          minHeight="100vh"
          maxWidth={{ sm: "260px", md: "300px", lg: "410px", xl: "540px" }}
          m="auto"
          spacing={{ xs: 2, md: 2.4, lg: 4 }}
        >
          <Typography
            variant="h3"
            component="h2"
            fontWeight="bold"
            fontSize={{ xs: "1.75rem", sm: "1rem", md: "2rem", lg: "3rem" }}
            lineHeight={1}
          >
            Recover Your Password
          </Typography>
          <Typography
            component="p"
            color="text.secondary"
            fontSize={{
              xs: "0.25rem",
              sm: "0.5rem",
              md: "1rem",
              lg: "1.25rem",
            }}
            lineHeight={1.5}
          >
            Please enter your email address and we will send you instructions on
            how to reset your password
          </Typography>

          <StyledTextField
            fullWidth
            name="email"
            label="Email Address"
            type="email"
            size={isSmall ? "small" : "large"}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
          />
          {isSuccess && (
            <Typography
              color="#4BB543"
              component="p"
              fontSize={{
                xs: "0.25rem",
                sm: "0.5rem",
                md: "0.75rem",
                lg: "1rem",
              }}
              lineHeight={1}
            >
              A password reset email has sent to your Email Account. Go and
              Check out!
            </Typography>
          )}
          {error && <Typography color="error">{error}</Typography>}
          <Button
            variant="contained"
            type="submit"
            disabled={isSuccess}
            size={isSmall ? "small" : "large"}
            sx={{
              fontWeight: "bold",
              fontSize: { sm: "0.75rem", md: "1.2rem", xl: "1.25rem" },
            }}
          >
            Recover Password
          </Button>
        </Stack>
      </form>
    </Container>
  );
}

export default RecoverPasswordForm;
const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: PRIMARY_LIGHT,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is invalid")
    .trim()
    .required("*Required")
    .max(80, "Email is too long")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid Email"),
});
