import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import StyledSwitch from "components/ToggleButton";
import PropTypes from "prop-types";
import { memo } from "react";
import isValidNumber from "utils/helpers/isValidNumber";

function PriceSection({ formik }) {
  return (
    <StyledMuiCard
      title="Pricing"
      subTitle="Enter price and sale price of the product"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={2}
          p={{ xs: 1, md: 1.5 }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Price
          </Typography>
          <StyledTextField
            fullWidth
            name="price"
            type="text"
            value={formik.values.price}
            onChange={(e) => {
              isValidNumber(e.target.value) && formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.price && formik.errors.price)}
            helperText={
              formik.touched.price && !!formik.errors.price
                ? formik.errors.price
                : "Please enter the product price"
            }
            py={2}
            sx={{
              "& .MuiOutlinedInput-input": {
                lineHeight: 1,
                fontSize: "2rem",
                fontWeight: "bold",
                color: "text.secondary",
                height: "auto",
                padding: "0.5rem 0.75rem",
                textAlign: "right",
              },
            }}
            InputProps={{
              startAdornment: (
                <Typography
                  color="text.disabled"
                  sx={{
                    fontSize: "1.25rem",
                    borderRight: "1px solid #00000038",
                    paddingRight: "1rem",
                  }}
                >
                  PKR
                </Typography>
              ),
            }}
          />
          <Stack direction="row" py={1} justifyContent="space-between">
            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Sales Price
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                pt={1}
                fontSize="1rem"
              >
                Enable this option if you want to put product on sales
              </Typography>
            </Box>
            <StyledSwitch
              name="onSale"
              onChange={formik.handleChange}
              checked={formik.values.onSale}
            />
          </Stack>
          <StyledTextField
            fullWidth
            name="salePrice"
            type="text"
            disabled={!formik.values.onSale}
            value={formik.values.salePrice}
            onChange={(e) => {
              isValidNumber(e.target.value) && formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.salePrice && formik.errors.salePrice)}
            helperText={
              formik.touched.salePrice && !!formik.errors.salePrice
                ? formik.errors.salePrice
                : "Sales price must be lower than the regular price"
            }
            py={2}
            sx={{
              "& .MuiOutlinedInput-input": {
                lineHeight: 1,
                fontSize: "2rem",
                fontWeight: "bold",
                color: "text.secondary",
                height: "auto",
                padding: "0.5rem 0.75rem",
                textAlign: "right",
              },
            }}
            InputProps={{
              startAdornment: (
                <Typography
                  color="text.disabled"
                  sx={{
                    fontSize: "1.25rem",
                    borderRight: "1px solid #00000038",
                    paddingRight: "1rem",
                  }}
                >
                  PKR
                </Typography>
              ),
            }}
          />
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

PriceSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(PriceSection, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.values.price === nextProps.formik.values.price &&
    prevProps.formik.values.onSale === nextProps.formik.values.onSale &&
    prevProps.formik.values.salePrice === nextProps.formik.values.salePrice &&
    prevProps.formik.errors.price === nextProps.formik.errors.price &&
    prevProps.formik.errors.salePrice === nextProps.formik.errors.salePrice &&
    prevProps.formik.touched.price === nextProps.formik.touched.price &&
    prevProps.formik.touched.onSale === nextProps.formik.touched.onSale &&
    prevProps.formik.touched.salePrice === nextProps.formik.touched.salePrice
  );
}
