import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DataGridSearch from "components/DataGridSearch";
import DataGrid from "components/Users/DataGrid";
import UserForm from "components/Users/UserForm";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { memo } from "react";

const Users = ({ create, update }) => {
  if (create || update) {
    return <UserForm />;
  }

  return (
    <Box py={2}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          USERS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
        paddingBottom={1}
      >
        Users
      </Typography>
      {/* <Actions /> */}
      <Box textAlign="end">
        <DataGridSearch
          searchKeyParam="name"
          searchKeyParamValue="fullname"
          searchQueryParam="value"
        />
      </Box>
      <DataGrid />
    </Box>
  );
};

export default memo(Users);

Users.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Users.defaultProps = {
  create: false,
  update: false,
};
