import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CollectionDescriptionSection from "components/Collection/CollectionForm/CollectionDescriptionSection";
import CollectionMediaSection from "components/Collection/CollectionForm/CollectionMediaSection";
import LanguageToggleButtons from "components/LanguageToggleButtons";
import DatePicker from "components/Pickers/DatePicker";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetCollection,
  resetCollectionState,
  submitCollection,
  updateCollection,
} from "store/slices/collectionSlice";
import parseEditorValue from "utils/helpers/parseEditorValue";
import reduceObject from "utils/helpers/reduceObject";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";

function CollectionForm() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const collection = useSelector((state) => state.collection.collection);
  const loading = useSelector((state) => state.collection.collectionLoading);
  const selectedLanguage = useSelector((state) => state.UI.language ?? "En");
  const translatedData = useSelector(
    (state) => state.collection?.collection?.lang?.ar ?? null
  );

  useEffect(() => {
    if (!params.id) {
      dispatch(resetCollection());
    }
  }, [dispatch, params.id]);

  const initialValues = useMemo(
    () => ({
      name: selectedLanguage === "Ar" ? translatedData?.name : collection?.name,
      description:
        selectedLanguage === "Ar"
          ? parseEditorValue(translatedData?.description)
          : collection?.description
          ? parseEditorValue(collection?.description)
          : "",
      expireDate: collection?.expireDate ?? "",
      collectionImage: collection?.image ?? "",
    }),
    [collection, selectedLanguage, translatedData]
  );

  const handleSubmit = useCallback(
    (values) => {
      let formData = new FormData();
      formData.append("name", values?.name);
      formData.append("description", values?.description);
      formData.append("expireDate", values?.expireDate);
      formData.append("collectionImage", values?.collectionImage);

      if (params?.id) {
        let formValues = { ...values };
        let collectionValues = { ...collection };
        collectionValues.description = parseEditorValue(
          collection?.description
        );
        collectionValues = {
          ...collectionValues,
          collectionImage: collectionValues.image,
        };
        let updatedValues = reduceObject(formValues, collectionValues);

        if (Object.keys(updatedValues).length > 0) {
          if (selectedLanguage === "Ar") {
            const _data = {
              id: params.id,
              lang: {
                ar: {
                  name: values?.name,
                  description: values?.description,
                },
              },
            };
            dispatch(updateCollection(_data))
              .unwrap()
              .then(() => {
                toast.success("Collection updated successfully");
                dispatch(resetCollectionState());
                navigate("/products/collections");
              });
          } else
            dispatch(updateCollection({ ...updatedValues, id: params.id }))
              .unwrap()
              .then(() => {
                toast.success("Collection updated successfully");
                dispatch(resetCollectionState());
                navigate("/products/collections");
              });
        } else {
          navigate("/products/collections");
        }
      } else {
        dispatch(submitCollection(formData))
          .unwrap()
          .then(() => {
            toast.success("Collection created successfully");
            dispatch(resetCollectionState());
            navigate("/products/collections");
          });
      }
    },
    [collection, dispatch, navigate, params.id, selectedLanguage]
  );

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          COLLECTIONS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
          fontWeight={500}
          paddingTop={1}
          paddingBottom={2}
        >
          {params?.id ? "Update Collection" : "Add Collection"}
        </Typography>
        {params?.id ? <LanguageToggleButtons /> : null}
      </Box>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Basic Information"
                  subTitle="Please enter the basic information of the collection such as name and description"
                >
                  <CardContent>
                    <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Name
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="text"
                              {...formik.getFieldProps("name")}
                              value={formik.values.name}
                              error={Boolean(
                                formik.touched.name && formik.errors.name
                              )}
                              helperText={
                                formik.touched.name && !!formik.errors.name
                                  ? formik.errors.name
                                  : "Enter a descriptive name of the collection here"
                              }
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Expiry Date
                            </Typography>
                            <DatePicker
                              disabled={selectedLanguage === "Ar"}
                              fullWidth
                              name="endDate"
                              value={
                                formik.values.expireDate
                                  ? dayjs(formik.values.expireDate)
                                  : dayjs(new Date())
                              }
                              error={Boolean(
                                formik.touched.expireDate &&
                                  formik.errors.expireDate
                              )}
                              onBlur={formik.handleBlur}
                              onChange={(value) =>
                                formik.setFieldValue(
                                  "expireDate",
                                  value.toString(),
                                  true
                                )
                              }
                              helperText={
                                formik.touched.expireDate &&
                                !!formik.errors.expireDate
                                  ? formik.errors.expireDate
                                  : "Please select the end date"
                              }
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </CardContent>
                </StyledMuiCard>
              </Grid>

              {/*Collection Description Section */}
              <Grid item xs={12} lg={12}>
                <CollectionDescriptionSection formik={formik} />
              </Grid>
              {/*Collection Media Section */}
              {selectedLanguage === "En" ? (
                <Grid item xs={12} lg={12}>
                  <CollectionMediaSection formik={formik} />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Save Collection
                  </Button>
                  <Button
                    disabled={formik.isSubmitting}
                    onClick={() => formik.resetForm()}
                    variant="outlined"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(CollectionForm);

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Name should be at least 10 characters long")
    .max(30, "Name is too long"),
  expireDate: Yup.date()
    .required("Required*")
    .min(new Date(), "End date must be greater than current date."),
  description: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Description must be at least 10 characters long")
    .max(110, "Description must be at most 120 characters long"),
  collectionImage: Yup.mixed().required("Collection Banner Image is required"),
});
