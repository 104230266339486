import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function AddCategoryActionButton() {
  const navigate = useNavigate();

  return (
    <Box py={2} sx={containerStyles}>
      <Button
        startIcon={<AddIcon />}
        size="large"
        variant="contained"
        className="AddButtons"
        onClick={() => navigate(`/products/categories/create`)}
      >
        Add New
      </Button>
    </Box>
  );
}

export default AddCategoryActionButton;

const containerStyles = {
  display: "flex",
  "& .AddButtons": {
    py: 1,
    fontSize: {
      xs: "0.8rem",
      sm: "1rem",
      marginRight: 10,
    },
  },
};
