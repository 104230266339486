import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddCollectionProducts from "components/Collection/AddCollectionProducts/AddCollectionProducts";
import CollectionDataGrid from "components/Collection/CollectionDataGrid";
import CollectionForm from "components/Collection/CollectionForm";
import HeaderActions from "components/Collection/HeaderActions/index";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { store } from "store/configureStore";
import { getCollectionById } from "store/slices/collectionSlice";
function Collection({ create, edit, addProducts }) {
  if (create || edit) {
    return <CollectionForm />;
  }
  if (addProducts) {
    return <AddCollectionProducts />;
  }
  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          PRODUCTS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
      >
        Collections
      </Typography>
      <HeaderActions />
      <CollectionDataGrid />
    </Box>
  );
}
Collection.propTypes = {
  create: PropTypes.bool,
  edit: PropTypes.bool,
  addProducts: PropTypes.bool,
};

Collection.defaultProps = {
  create: false,
  edit: false,
};

export default Collection;

export function loadCollection({ params }) {
  return store.dispatch(getCollectionById(params.id));
}
