import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid } from "@mui/x-data-grid";
import CurrencyFormatter from "components/CurrencyFormatter";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateCollectionProducts } from "store/slices/collectionSlice";
import { getAdminProducts } from "store/slices/productsSlice";
import { ACTIVE_COLOR, INACTIVE_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const StyledDel = styled("del")(() => ({}));

function CollectionProductsDataGrid() {
  const dispatch = useDispatch();
  const params = useParams();

  const queryParams = useQueryParams();

  const { results, loading, totalPages, page } = useSelector(
    (state) => state.products ?? []
  );

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const handlePagination = useCallback(
    (_, newPage) => {
      const _params = { ...queryParams };
      _params.page = newPage;
      dispatch(getAdminProducts(_params));
    },
    [dispatch, queryParams]
  );

  useEffect(() => {
    const id = setTimeout(() => {
      const _params = { ...queryParams };
      _params.active = true;
      dispatch(getAdminProducts(_params));
    }, [500]);
    return () => clearTimeout(id);
  }, [dispatch, queryParams]);

  const handleAddToCollection = useCallback(
    (product) => {
      let data = [
        { productId: product?.id, productName: product?.productName },
      ];
      if (params.id) {
        dispatch(updateCollectionProducts({ data, id: params.id }));
      }
    },
    [dispatch, params]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 40,
        flex: 0.2,
      },
      {
        field: "image",
        headerName: "Image",
        type: "actions",
        minWidth: 100,
        flex: 0.8,
        renderCell: (params) => (
          <Box>
            <img
              src={params?.row?.mainImage}
              height={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              width={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 4,
                objectFit: "contain",
              }}
              alt={params?.row?.productName}
            />
          </Box>
        ),
      },
      {
        field: "Name/Details",
        headerName: "Name/Details",
        minWidth: 200,
        flex: 2,
        renderCell: (params) => (
          <Box>
            <Typography
              sx={{
                height: {
                  xs: 40,
                  md: 45,
                },
                textTransform: "capitalize",
                color: "#000000",
                overflow: "hidden",
                textOverflow: " ellipsis",
                lineClamp: "2",
                WebkitLineClamp: "2",
              }}
            >
              {params?.row?.productName}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  height: "12px",
                  width: "12px",
                  margin: "5px 6px 3px 0",
                  backgroundColor: params?.row?.active
                    ? ACTIVE_COLOR
                    : INACTIVE_COLOR,
                  borderRadius: "20px",
                }}
              />
              <Typography>
                {params?.row?.active ? "Active" : "Inactive"}
              </Typography>
            </Box>
            <Box display="flex">
              Vendor:
              <Typography sx={{ color: "primary.main", marginLeft: 0.5 }}>
                {params?.row?.user?.sellerDetail
                  ? params?.row.user?.sellerDetail?.brandName
                  : params?.row?.user?.fullName}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "quantity",
        headerName: "Qty/Options",
        minWidth: 90,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography>Qty: {params?.row?.quantity}</Typography>
            <Typography>Options: {params?.row?.variants?.length}</Typography>
          </Box>
        ),
      },
      {
        field: "price",
        headerName: "Price",
        minWidth: 90,
        flex: 1,
        renderCell: (params) =>
          params?.row?.onSale ? (
            <Box>
              <CurrencyFormatter number={params?.row?.salePrice ?? 0} />
              <StyledDel
                data-test-id="regularPrice"
                className="product-price"
                sx={{ color: "error.dark" }}
              >
                <CurrencyFormatter number={params?.row?.regularPrice ?? 0} />
              </StyledDel>
            </Box>
          ) : (
            <CurrencyFormatter number={params?.row?.regularPrice ?? 0} />
          ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 180,
        flex: 0.8,
        renderCell: (params) => {
          return (
            <Box display="flex" sx={actionStyles}>
              <Button
                size="small"
                variant="contained"
                onClick={() => handleAddToCollection(params?.row)}
                sx={{ py: 1 }}
              >
                Add To Collection
              </Button>
            </Box>
          );
        },
      },
    ],
    [isTablet, isLaptop, isLed, handleAddToCollection]
  );

  const customPagination = useCallback(() => {
    const _page = page ? page : 1;
    return (
      <Pagination
        variant="outlined"
        color="primary"
        sx={{ marginY: 4 }}
        size={isTablet ? "small" : "large"}
        count={totalPages}
        page={_page}
        disabled={totalPages === 1}
        onChange={handlePagination}
      />
    );
  }, [page, isTablet, totalPages, handlePagination]);

  const customNoRecordFound = useCallback(() => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No record found
      </Stack>
    );
  }, []);

  return (
    <Box sx={containerStyles}>
      <DataGrid
        container
        rows={results}
        columns={columns}
        loading={loading}
        pagination
        autoHeight
        disableRowSelectionOnClick
        checkboxSelection
        disableColumnMenu
        rowsPerPageOptions={[10, 15, 20]}
        components={{
          Pagination: customPagination,
          noRowsOverlay: customNoRecordFound,
        }}
        isCellEditable={() => false}
        getRowHeight={() => "auto"}
        getRowSpacing={(params) => ({
          top: params.isFirstVisible ? 0 : 6,
          bottom: params.isLastVisible ? 0 : 6,
        })}
        getRowId={(row) => row?._id ?? row?.id}
        sx={{
          "& .MuiDataGrid-row": { minHeight: "100px !important" },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
            {
              outline: "none",
            },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "1.25rem",
          },
        }}
      />
    </Box>
  );
}

export default memo(CollectionProductsDataGrid);

const actionStyles = {
  "& .button-styles": { minWidth: "15px" },
};
const containerStyles = {
  width: "100%",
  "& .MuiDataGrid-root": { border: "none", fontSize: "1rem" },
  "& .MuiPaginationItem-root.Mui-selected": {
    border: "none",
  },
  "& .MuiPaginationItem-outlinedPrimary": {
    border: "none",
  },
  "& .MuiPaginationItem-previousNext": {
    backgroundColor: "#078ecf1c",
  },
  "& .MuiDataGrid-cell": {
    color: "primary",
    borderBottom: "0px !important",
  },
  "& .MuiDataGrid-row": {
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#fff !important",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "0px !important",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnSeparator": {
    visibility: "hidden",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
};
