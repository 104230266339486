import SendIcon from "@mui/icons-material/Send";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import getInitialLetters from "utils/helpers/getInitialLetters";
import Attachments from "./Attachments";
import Emojis from "./Emojis";
import Messages from "./Messages";

function Conversation({ socket }) {
  const inputRef = useRef();
  const userId = useSelector((state) => state?.auth?.data?.user?.id ?? "");
  const activeRoom = useSelector((state) => state?.chat?.activeRoom ?? null);
  const [text, setText] = useState("");

  const handleTextChange = (e) => {
    setText(e.target.value.trimStart());
  };

  const handleEmojiSelection = useCallback((emojiObject) => {
    setText((text) => text + emojiObject.emoji);
  }, []);

  const handleSendMessage = useCallback(
    (e) => {
      e.preventDefault();
      if (!text.trim() || !activeRoom?._id) return;
      socket.emit("newChatMessage", {
        roomId: activeRoom?._id,
        message: text.trim(),
        receiverId: activeRoom?.customerId,
        senderId: userId,
      });
      setText("");
      inputRef.current.focus();
    },
    [activeRoom, socket, text, userId]
  );

  if (!activeRoom._id)
    return (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        flex={1}
        justifyContent="center"
      >
        <Typography
          variant="h6"
          fontWeight="600"
          textAlign="center"
          color="text.disabled"
        >
          Select a chat to start conversation
        </Typography>
      </Box>
    );

  return (
    <>
      <Stack direction="row" gap={1.5} alignItems="center">
        <Avatar
          src=""
          sx={{
            height: { xs: 40, md: 50 },
            width: { xs: 40, md: 50 },
            bgcolor: "primary.main",
          }}
        >
          {activeRoom?.customerName
            ? getInitialLetters(activeRoom?.customerName)
            : "N/A"}
        </Avatar>
        <Typography variant="h6" fontWeight="600">
          {activeRoom?.customerName ?? "N/A"}
        </Typography>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Messages socket={socket} />
      <Stack
        direction="row"
        gap={0.5}
        component="form"
        mt={1}
        onSubmit={handleSendMessage}
      >
        <TextField
          fullWidth
          ref={inputRef}
          size="small"
          value={text}
          onChange={handleTextChange}
          placeholder="Type a message"
          InputProps={{
            startAdornment: (
              <Emojis handleEmojiSelection={handleEmojiSelection} />
            ),
            endAdornment: (
              <Attachments
                roomId={activeRoom?._id}
                receiverId={activeRoom?.customerId}
              />
            ),
            sx: {
              fontSize: 14,
              lineHeight: "0.4em",
            },
          }}
          inputProps={{
            sx: {
              padding: "10px",
            },
          }}
        />
        <IconButton
          type="submit"
          sx={{ bgcolor: "primary.main", borderRadius: "5px" }}
        >
          <SendIcon sx={{ color: "white" }} />
        </IconButton>
      </Stack>
    </>
  );
}

Conversation.propTypes = {
  socket: PropTypes.object,
};

export default memo(Conversation);
