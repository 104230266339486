export const MOBILE_NETWORK_CODES = [
  { code: "0300" },
  { code: "0301" },
  { code: "0302" },
  { code: "0303" },
  { code: "0304" },
  { code: "0305" },
  { code: "0306" },
  { code: "0307" },
  { code: "0308" },
  { code: "0309" },
  { code: "0310" },
  { code: "0311" },
  { code: "0312" },
  { code: "0313" },
  { code: "0314" },
  { code: "0315" },
  { code: "0316" },
  { code: "0317" },
  { code: "0318" },
  { code: "0319" },
  { code: "0320" },
  { code: "0321" },
  { code: "0322" },
  { code: "0323" },
  { code: "0324" },
  { code: "0325" },
  { code: "0326" },
  { code: "0327" },
  { code: "0328" },
  { code: "0329" },
  { code: "0330" },
  { code: "0331" },
  { code: "0332" },
  { code: "0333" },
  { code: "0334" },
  { code: "0335" },
  { code: "0336" },
  { code: "0337" },
  { code: "0338" },
  { code: "0339" },
  { code: "0340" },
  { code: "0341" },
  { code: "0342" },
  { code: "0343" },
  { code: "0344" },
  { code: "0345" },
  { code: "0346" },
  { code: "0347" },
  { code: "0348" },
  { code: "0349" },
];
