import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyAYfrPkjmTG7WNl5c-S2tTS1pm847FXbJA",
  authDomain: "bazaarghar.com",
  projectId: "bazaarghar",
  storageBucket: "bazaarghar.appspot.com",
  messagingSenderId: "803226185929",
  appId: "1:803226185929:web:6e17f959433176c241dd18",
  measurementId: "G-3MHKMDFL01",
};

export const firebaseApp = initializeApp(firebaseConfig);
