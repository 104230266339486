import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import eyeIcon from "assets/icons/eyeIcon.svg";
import CurrencyFormatter from "components/CurrencyFormatter";
import StyledDataGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrders, resetState } from "store/slices/ordersSlice";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";

function OrdersDataGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParams();
  const { results, loading, totalPages } = useSelector((state) => state.orders);

  const get_orders = useCallback(() => {
    dispatch(getOrders(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      get_orders();
    }, 500);
    return () => {
      dispatch(resetState());
      clearTimeout(timeOut);
    };
  }, [dispatch, get_orders]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 40,
        flex: 0.3,
      },
      {
        field: "OrderID",
        headerName: "OrderID",
        minWidth: 160,
        flex: 1.1,
        renderCell: (params) => <Typography>{params?.row?.orderId}</Typography>,
      },
      {
        field: "Customer",
        headerName: "Customer Name",
        minWidth: 100,
        flex: 0.8,
        renderCell: (params) => (
          <Typography
            sx={{
              textTransform: "capitalize",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params?.row?.customer?.fullname}
          </Typography>
        ),
      },
      {
        field: "City",
        headerName: "City",
        minWidth: 100,
        flex: 0.7,
        renderCell: (params) => (
          <Typography
            sx={{
              textTransform: "capitalize",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params?.row?.address?.city}
          </Typography>
        ),
      },
      {
        field: "OrderNote",
        headerName: "Order Note",
        minWidth: 150,
        flex: 1.2,
        renderCell: (params) => (
          <Tooltip title={params?.row?.orderNote}>
            <Typography
              sx={{
                maxHeight: "70px",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
            >
              {params?.row?.orderNote}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: "date",
        headerName: "Order Date",
        minWidth: 100,
        flex: 0.6,
        renderCell: (params) => (
          <Typography>
            {dayjs(params?.row?.createdAt).format("DD/MM/YYYY")}
          </Typography>
        ),
      },
      {
        field: "Price",
        headerName: "Price",
        minWidth: 100,
        flex: 0.7,
        renderCell: (params) => (
          <CurrencyFormatter
            number={params?.row?.payable ?? 0}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          />
        ),
      },
      {
        field: "status",
        headerName: "Status",
        type: "actions",
        minWidth: 100,
        flex: 0.6,
        renderCell: (params) => (
          <Chip
            sx={{
              backgroundColor: statusBadges(params?.row?.orderStatus?.name)
                .bgColor,
              color: statusBadges(params?.row?.orderStatus?.name).color,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
              fontSize: "12px",
            }}
            variant="contained"
            label={params?.row?.orderStatus?.name}
          />
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 100,
        flex: 0.5,
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Tooltip title="View">
              <IconButton size="small" onClick={() => navigate(params.row.id)}>
                <img src={eyeIcon} alt="view" className="icon-size" />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [navigate]
  );

  return (
    <StyledDataGrid
      rows={results}
      columns={columns}
      loading={loading}
      totalPages={totalPages}
    />
  );
}

export default memo(OrdersDataGrid);

const actionStyles = {
  "& .icon-size": { height: "25px", width: "25px" },
};
