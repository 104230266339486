import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { memo, useCallback, useState } from "react";

function PaymentRadioOptions() {
  const [value, setValue] = useState("");

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <RadioGroup row value={value} onChange={handleChange}>
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* <Box
          sx={{
            backgroundColor: "#068ecf",
            px: { xs: "15px", md: "20px", lg: "10px" },
            borderRadius: "5px",
            color: "#fff",
          }}
        >
          <FormControlLabel
            value="advance"
            disabled
            control={
              <Radio
                sx={{
                  color: grey[50],
                  "&.Mui-checked": {
                    color: grey[50],
                  },
                }}
              />
            }
            label="Advance Payment"
          />
        </Box> */}
        <Box
          sx={{
            backgroundColor: "#068ecf",
            px: { xs: "15px", md: "20px", lg: "10px" },
            borderRadius: "5px",
            color: "#fff",
            marginLeft: "5px",
          }}
        >
          <FormControlLabel
            value="cod"
            checked
            control={
              <Radio
                sx={{
                  color: grey[50],
                  "&.Mui-checked": {
                    color: grey[50],
                  },
                }}
              />
            }
            label="Cash On Delivery"
          />
        </Box>
      </Box>
    </RadioGroup>
  );
}

export default memo(PaymentRadioOptions);
