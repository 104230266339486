// function to get response data
export const getResponseData = (response) =>
  response?.data?.data ?? response?.data ?? response;

export const getErrorData = (error) =>
  error?.response?.data?.data ??
  error?.response?.data ??
  error?.response ??
  error;

export const getErrorMessage = (error) =>
  error?.response?.data?.userMessage?.userMessage ??
  error?.response?.data?.userMessage ??
  error?.response?.userMessage ??
  error?.response?.data?.data?.message ??
  error?.response?.data?.message ??
  error?.response?.message ??
  error?.message ??
  "Something went wrong, please try again later";
