import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const addProductVariant = createAsyncThunk(
  "variants/addVariant",
  async (variant, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `/products/variants/${variant?.id}`,
        variant?.data
      );
      toast.success("Variant added successfully");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteVariant = createAsyncThunk(
  "variants/deleteVariant",
  async (variantId, { getState, rejectWithValue }) => {
    const { results, page, limit } = getState().variants;
    try {
      await http.delete(`/products/${variantId}`);
      toast.success("Deleted successfully");
      let returnedVariants = results.filter((data) => data.id !== variantId);
      if (returnedVariants) {
        returnedVariants = returnedVariants.map((item, index) => {
          const pageStart = (page - 1) * limit;
          return {
            ...item,
            serialNumber: pageStart + index + 1,
          };
        });
      }
      return returnedVariants;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const variantsSlice = createSlice({
  name: "variants",
  initialState: {
    loading: true,
    results: null,
    error: null,
    variantOption: null,
    variantId: "",
    openVariantForm: null,
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.results = [];
      state.error = null;
    },
    setOpenVariantForm: (state, action) => {
      if (action) {
        state.openVariantForm = action.payload;
      }
    },
    setVariantOption: (state, action) => {
      if (action) {
        state.variantOption = action.payload;
      }
    },
    setVariantId: (state, action) => {
      if (action) {
        state.variantId = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProductVariant.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProductVariant.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(addProductVariant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteVariant.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteVariant.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(deleteVariant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const {
  resetState,
  resetProductState,
  setVariantOption,
  setVariantId,
  setOpenVariantForm,
} = variantsSlice.actions;

// Exporting default reducer
export default variantsSlice.reducer;
