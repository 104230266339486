import Box from "@mui/material/Box";
import CardTitle from "components/CardTitle";
import { memo } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { useSelector } from "react-redux";
import {
  SPEEDOMETER_NEEDLE_COLOR,
  SPEEDOMETER_SEGMENT_COLOR,
  SPEEDOMETER_TEXT_COLOR,
  STATS_PRIMARY_BLUE,
} from "styles/colors";
import getTargetValue from "utils/helpers/getTargetValue";

function SpeedoMeter() {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const monthlyAnalytics = useSelector(
    (state) => state.dashboard.monthlyAnalytics
  );
  let maxValue = getTargetValue(
    monthlyAnalytics?.orders?.averageOrderValue,
    1.5
  );
  let halfMaxValue = Math.round(maxValue / 2);

  if (maxValue === 0) {
    maxValue = 1000;
    halfMaxValue = 500;
  }

  return (
    <Box
      p="1rem"
      sx={{
        minHeight: "95px",
        maxHeight: {
          sm: "236px",
          md: isOpen ? "242px" : "236px",
          lg: isOpen ? "242px" : "236px",
          xl: "236px",
        },
      }}
    >
      <CardTitle title="Avg. order value" />
      <Box textAlign="center">
        <ReactSpeedometer
          forceRender={true}
          height={157}
          width={250}
          needleHeightRatio={0.8}
          minValue={0}
          maxValue={maxValue}
          value={monthlyAnalytics?.orders?.averageOrderValue ?? 0}
          labelFontSize={"14px"}
          valueTextFontSize={"36px"}
          valueTextFontWeight={"bold"}
          paddingHorizontal={17}
          paddingVertical={17}
          valueTextColor={SPEEDOMETER_TEXT_COLOR}
          maxSegmentLabels={3}
          customSegmentStops={[0, halfMaxValue, maxValue]}
          segmentColors={[SPEEDOMETER_SEGMENT_COLOR, STATS_PRIMARY_BLUE]}
          ringWidth={57}
          needleTransitionDuration={3333}
          needleTransition="easeElastic"
          needleColor={SPEEDOMETER_NEEDLE_COLOR}
          textColor={SPEEDOMETER_TEXT_COLOR}
        />
      </Box>
    </Box>
  );
}

export default memo(SpeedoMeter);
