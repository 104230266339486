import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useCallback, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTERS_BACKGROUND_COLOR, FILTER_TEXT_COLOR } from "styles/colors";
import { VouchersOptions } from "utils/constants/productForm";
import useQueryParams from "utils/hooks/useQueryParams";

export default function TypeFilter() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const selectedType = useCallback(
    (type) => {
      let _params = params;
      _params.couponType = type?.value;
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );
  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(_, newValue) => {
        setValue(newValue);
        selectedType(newValue);
      }}
      id="controllable-states-demo"
      options={VouchersOptions}
      sx={{
        minWidth: 170,
        maxWidth: 180,
        background: FILTERS_BACKGROUND_COLOR,
      }}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Type"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              color: FILTER_TEXT_COLOR,
              marginTop: "2px",
            },
            "& .MuiInputBase-root": { height: 44 },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}
