import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardTitle from "components/CardTitle";
import StyledCard from "components/Dashboard/StyledCardContent";
import { memo } from "react";
import { useSelector } from "react-redux";
import KeyNumberTile from "./KeyNumberTile";

function KeyNumbers() {
  const { results } = useSelector((state) => state.dashboard);
  return (
    <Card sx={{ p: "1rem", minHeight: "190px" }}>
      <Box pb={2}>
        <CardTitle title="Key Numbers" />
      </Box>
      <StyledCard sx={{ display: "flex", justifyContent: "space-between" }}>
        <KeyNumberTile count={results?.totalUsers} title={"Total Users"} />
        <KeyNumberTile title={"Revenue Generated"} />
        <KeyNumberTile count={results?.totalsales} title={"Year 2022 Sales"} />
        <KeyNumberTile
          count={results?.productStats?.totalProducts}
          title={"Total SKUs"}
        />
        <KeyNumberTile
          count={results?.videos?.totalViews}
          title={"Total Views"}
        />
      </StyledCard>
    </Card>
  );
}
export default memo(KeyNumbers);
