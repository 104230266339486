import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledTextField from "components/authentication/StyledTextField";
import { Form, Formik } from "formik";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFacebookCatalog } from "store/slices/storesSlice";
import { setIsFacebookCatalogDialogOpen } from "store/slices/uiSlice";
import * as Yup from "yup";

function FacebookCatalogDialog() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.UI.isFacebookCatalogDialogOpen);
  const loading = useSelector((state) => state.stores.loadingFacebook);
  const facebookResponse = useSelector(
    (state) => state?.stores?.facebookResponse
  );
  const facebookBusinesses = useSelector(
    (state) => state?.stores?.facebookBusinesses
  );
  const facebookPages = useSelector((state) => state?.stores?.facebookPages);

  const handleClose = useCallback(() => {
    dispatch(setIsFacebookCatalogDialogOpen(false));
  }, [dispatch]);

  const initialValues = useMemo(
    () => ({
      business: null,
      page: null,
    }),
    []
  );

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        createFacebookCatalog({
          businessId: values.business.id,
          pageId: values.page.id,
          fbToken: facebookResponse?.accessToken,
        })
      )
        .unwrap()
        .then(() => {
          handleClose();
        });
    },
    [dispatch, facebookResponse, handleClose]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { minWidth: { lg: "1000px" } },
      }}
      PaperProps={{
        elevation: 0,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={5}
        bgcolor="primary.main"
        sx={{
          padding: "0.25rem 0 0.25rem 2rem",
        }}
      >
        <Stack direction="column">
          <Typography variant="h5" component="h1" color="white">
            Facebook Catalog
          </Typography>
          <Typography variant="body2" color="white">
            Select business and page
          </Typography>
        </Stack>
        <IconButton onClick={handleClose} size="large">
          <CancelIcon sx={{ color: "white", fontSize: "2.5rem" }} />
        </IconButton>
      </Stack>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Stack direction="column" p={4} gap={4}>
              <Autocomplete
                name="business"
                loading={loading.businesses}
                options={facebookBusinesses?.businesses?.data || []}
                value={formik.values.business}
                noOptionsText="No Businesses Available"
                onChange={(_, business) => {
                  formik.setFieldValue("business", business);
                }}
                getOptionLabel={(business) => business.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <StyledTextField
                    fullWidth
                    {...params}
                    key={params.id}
                    label="Select Business"
                    error={Boolean(
                      formik.touched.business && formik.errors.business
                    )}
                    helperText={
                      formik.touched.business && !!formik.errors.business
                        ? formik.errors.business
                        : "Select a business from dropdown"
                    }
                  />
                )}
              />

              <Autocomplete
                name="page"
                loading={loading.pages}
                options={facebookPages || []}
                value={formik.values.page}
                noOptionsText="No Pages Available"
                onChange={(_, page) => {
                  formik.setFieldValue("page", page);
                }}
                getOptionLabel={(page) => page.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <StyledTextField
                    fullWidth
                    {...params}
                    key={params.id}
                    label="Select Page"
                    error={Boolean(formik.touched.page && formik.errors.page)}
                    helperText={
                      formik.touched.page && !!formik.errors.page
                        ? formik.errors.page
                        : "Select a page from dropdown"
                    }
                  />
                )}
              />

              <Box textAlign="right">
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{
                    height: "3.5rem",
                    minWidth: 250,
                  }}
                  disabled={loading.catalog}
                >
                  {loading.catalog ? `Creating Catalog` : `Continue`}
                </Button>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

const validationSchema = Yup.object({
  business: Yup.object().required("Required*").nullable(),
  page: Yup.object().required("Required*").nullable(),
});

export default memo(FacebookCatalogDialog);
