import http from "utils/httpRequest/http";

export const uploadCollectionImage = (
  collectionId = "",
  image = null,
  type = ""
) => {
  const formData = new FormData();
  formData.append(type, image);
  return http.patch(`/collection/${collectionId}`, formData);
};
