import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import VoucherDescriptionEditor from "components/Vouchers/VoucherForm/VoucherDescriptionEditor";
import PropTypes from "prop-types";
import { memo } from "react";

function VoucherDescriptionSection({ formik }) {
  return (
    <Stack direction="column" justifyContent="center" gap={2}>
      <Box py={1}>
        <Typography
          variant="h3"
          fontWeight="bold"
          fontSize="1.25rem"
          color="text.secondary"
        >
          Description
        </Typography>
      </Box>
      <Box>
        <Card>
          <VoucherDescriptionEditor formik={formik} />
        </Card>
        {formik.errors.description ? (
          <Typography color="error.light" pt={1} fontSize="1rem">
            {formik.errors.description}
          </Typography>
        ) : (
          <Typography color="text.secondary" mt={1}>
            Enter voucher description in detail. Tip: Use bullet points for easy
            viewing
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
VoucherDescriptionSection.propTypes = {
  formik: PropTypes.object.isRequired,
};
export default memo(VoucherDescriptionSection);
