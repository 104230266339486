import { ACTIVE_COLOR } from "styles/colors";

export default function returnStatusBadges(status) {
  let color = "";
  switch (status) {
    case StatusOptions.REQUESTED:
      color = ACTIVE_COLOR;
      break;
    case StatusOptions.APPROVED:
      color = ACTIVE_COLOR;
      break;
    case StatusOptions.REJECTED:
      color = "red";
      break;
    case StatusOptions.REFUNDED:
      color = "primary.main";
      break;
    case StatusOptions.REPLACEMENT:
      color = "primary";
      break;
    case StatusOptions.RECEIVED:
      color = "primary.dark";
      break;
    case StatusOptions.RETURNED:
      color = "primary.main";
      break;
    default:
      break;
  }
  return color;
}

const StatusOptions = {
  REQUESTED: "requested",
  APPROVED: "approved",
  REJECTED: "rejected",
  REFUNDED: "refunded",
  REPLACEMENT: "replacement",
  RECEIVED: "received",
  RETURNED: "returned",
};
