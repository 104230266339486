import DesktopMacOutlinedIcon from "@mui/icons-material/DesktopMacOutlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import TabletMacOutlinedIcon from "@mui/icons-material/TabletMacOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardTitle from "components/CardTitle";
import BarChart from "components/Dashboard/BarChart";
import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  DASHBOARD_CARD_TEXT_COLOR,
  STATS_NUMBER_FORMATTER_COLOR,
  STATS_ORANGE_SECONDARY,
  STATS_PRIMARY_BLUE,
  STATS_PRIMARY_RED,
} from "styles/colors";

function SessionByDevice() {
  const { monthlyAnalytics } = useSelector((state) => state.dashboard);

  const mobileSession = useMemo(() => {
    const customerApp = monthlyAnalytics?.users?.session?.["customer-app"] || 0;
    const sellerApp = monthlyAnalytics?.users?.session?.["seller-app"] || 0;
    return customerApp + sellerApp;
  }, [monthlyAnalytics?.users?.session]);

  return (
    <Box height="258px">
      <Grid container>
        <Grid item xs={6} p="1rem">
          <CardTitle title="Session By Device" />
          <Box pt="3rem">
            <Box display="flex" gap={4} mt={2}>
              <PhoneAndroidIcon sx={{ color: STATS_PRIMARY_BLUE }} />
              <Typography
                fontSize="1.125rem"
                flex="0.5"
                color={DASHBOARD_CARD_TEXT_COLOR}
              >
                Mobile
              </Typography>
              <Typography
                fontSize="1.25rem"
                fontWeight="bold"
                color={STATS_NUMBER_FORMATTER_COLOR}
              >
                {mobileSession}
              </Typography>
            </Box>
            <Box display="flex" gap={4} mt={2}>
              <DesktopMacOutlinedIcon sx={{ color: STATS_ORANGE_SECONDARY }} />
              <Typography
                fontSize="1.125rem"
                flex="0.5"
                color={DASHBOARD_CARD_TEXT_COLOR}
              >
                Desktop
              </Typography>
              <Typography
                fontSize="1.25rem"
                fontWeight="bold"
                color={STATS_NUMBER_FORMATTER_COLOR}
              >
                {monthlyAnalytics?.users?.session?.["web-desktop"] ?? 0}
              </Typography>
            </Box>

            <Box display="flex" gap={4} mt={2}>
              <TabletMacOutlinedIcon sx={{ color: STATS_PRIMARY_RED }} />
              <Typography
                fontSize="1.125rem"
                flex="0.5"
                color={DASHBOARD_CARD_TEXT_COLOR}
              >
                Tablet
              </Typography>
              <Typography
                fontSize="1.25rem"
                fontWeight="bold"
                color={STATS_NUMBER_FORMATTER_COLOR}
              >
                {monthlyAnalytics?.users?.session?.null ?? 0}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <BarChart />
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(SessionByDevice);
