import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openSelectProductStoreDialog } from "store/slices/uiSlice";
import { FILTERS_BACKGROUND_COLOR } from "styles/colors";
import SelectStoreDialog from "./SelectStoreDialog";

export default function AddActions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.auth?.userRole);

  const handleClick = useCallback(() => {
    if (userRole !== "admin") {
      navigate("create");
    } else dispatch(openSelectProductStoreDialog());
  }, [dispatch, navigate, userRole]);

  return (
    <Box sx={containerStyles}>
      <Button
        startIcon={<AddIcon />}
        size="large"
        variant="contained"
        className="AddButtons"
        onClick={handleClick}
      >
        Add Product
      </Button>
      {userRole !== "admin" && (
        <Button
          sx={{
            minWidth: 170,
            maxWidth: 180,
            background: FILTERS_BACKGROUND_COLOR,
          }}
          startIcon={<AddIcon />}
          variant="outlined"
          className="AddButtons"
          onClick={() => navigate("bulk-upload")}
        >
          Bulk Upload
        </Button>
      )}
      <SelectStoreDialog />
    </Box>
  );
}
const containerStyles = {
  display: "flex",
  gap: 2,
  "& .AddButtons": {
    height: "44px",
    textTransform: "none",
    fontSize: {
      xs: "0.8rem",
      sm: "1rem",
    },
    cursor: "pointer",
  },
};
