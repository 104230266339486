import GlobalStyles from "@mui/material/GlobalStyles";

export const muiGlobalStyles = (
  <GlobalStyles
    styles={`/* 
    ##Device = Desktops
    ##Screen = 1401px to higher resolution desktops
  */

  @media (min-width: 1401px) {
    /* CSS */
    html {
      font-size: 16px;
    }
  }

  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1400px
  */

  @media (min-width: 1025px) and (max-width: 1400px) {
    /* CSS */
    html {
      font-size: 14px;
    }
  }

  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

  @media (max-width: 1024px) {
    /* CSS */
    html {
      font-size: 12px;
    }
  }
  `}
  />
);
