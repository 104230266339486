export default function flatMarketArray(markets) {
  let flatArray = [];

  markets.forEach((level_0) => {
    flatArray.push({ value: level_0.id, label: level_0.name });
    if (level_0.subMarkets) {
      level_0.subMarkets.forEach((level_1) => {
        flatArray.push({
          value: level_1.id,
          label: `${level_0.name} --> ${level_1.name}`,
        });
      });
    }
  });
  return flatArray;
}
