import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

const extractPackageItems = (packages) => {
  if (packages.length > 0)
    return packages.map((pack) =>
      pack?.packageItems.map((item) => {
        return { ...item, seller: pack?.seller, packageId: pack.id };
      })
    );
  return [];
};

export const getUserCart = createAsyncThunk(
  "cart/getUserCart",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await http.get(`/cart/admin/${userId}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);
export const addProductToCart = createAsyncThunk(
  "cart/addProductToCart",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await http.post(`/cart/admin`, data);
      if (response?.status === 200) {
        toast.success("Product added to cart successfully");
      }
      const userId = getState().cart?.userId ?? "";
      dispatch(getUserCart(userId));
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteProductFromCart = createAsyncThunk(
  "cart/deleteProductFromCart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.patch(`/cart/admin`, data);
      if (response?.status === 200) {
        toast.success("Product deleted from cart successfully");
      }
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteUserCart = createAsyncThunk(
  "cart/deleteUserCart",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await http.delete(`/cart/admin/${userId}`);
      if (response?.status === 200) {
        toast.success("Cart deleted successfully");
        getUserCart(userId);
      }
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  results: [],
  cartData: [],
  packages: [],
  packageItems: [],
  adminDiscountProducts: [],
  walletBalance: "",
  userId: "",
  wallet: false,
  loading: false,
  error: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetErrorState: (state) => {
      state.error = null;
    },
    handleDiscountedProduct: (state, { payload }) => {
      const adminDiscountProducts = current(state.adminDiscountProducts);
      const existedProduct = adminDiscountProducts.find(
        (item) => item.product === payload.product
      );
      if (existedProduct && !payload.amount) {
        state.adminDiscountProducts = adminDiscountProducts.filter(
          (item) => item.product !== payload.product
        );
      } else if (existedProduct) {
        state.adminDiscountProducts = adminDiscountProducts.map((item) => {
          if (item.product === payload.product) {
            return {
              product: payload.product,
              discountType: payload.discountType,
              amount: payload.amount,
            };
          }
          return item;
        });
      } else if (!existedProduct && payload.amount) {
        state.adminDiscountProducts = [...adminDiscountProducts, payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProductToCart.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addProductToCart.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
        state.userId = action.payload?.user?.id;
      })
      .addCase(addProductToCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getUserCart.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserCart.fulfilled, (state, action) => {
        state.loading = false;
        state.cartData = action.payload;
        state.packages = action.payload?.packages;
        state.userId = action.payload?.user?.id;
        state.packageItems = extractPackageItems(action.payload.packages);
        state.walletBalance = action.payload?.user?.wallet?.balance;
        state.wallet = action.payload?.wallet;
      })
      .addCase(getUserCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(deleteProductFromCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProductFromCart.fulfilled, (state, action) => {
        state.loading = false;
        state.cartData = action.payload;
        state.packages = action.payload?.packages;
      })
      .addCase(deleteProductFromCart.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteUserCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserCart.fulfilled, (state, action) => {
        state.loading = false;
        state.packages = action.payload?.packages;
        state.packageItems = extractPackageItems(action.payload.packages);
      })
      .addCase(deleteUserCart.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { resetErrorState, handleDiscountedProduct } = cartSlice.actions;
export default cartSlice.reducer;
