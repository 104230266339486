import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Button, InputAdornment, Stack } from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailOTP } from "services/authServices";
import { updateEmail } from "store/slices/authSlice";
import * as Yup from "yup";
import StyledTextField from "./StyledTextField";

function EditEmail({ toggleEditEmail }) {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth?.data?.user?.email ?? "");

  const formik = useFormik({
    initialValues: {
      email: userEmail ?? "",
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const data = {
        email: values.email.trim(),
      };
      dispatch(updateEmail(data))
        .unwrap()
        .then((data) => {
          setTimeout(() => {
            sendEmailOTP(data.email);
          }, 1500);
          toggleEditEmail();
        })
        .catch((error) => {
          if (error.message.includes("Email already taken"))
            return setErrors({ email: "Email already taken" });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction="column" spacing={1} alignItems="flex-end" pb={4}>
        <StyledTextField
          fullWidth
          type="email"
          label="Email Address"
          {...formik.getFieldProps("email")}
          error={!!formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          type="submit"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        >
          Update Email
        </Button>
      </Stack>
    </form>
  );
}

EditEmail.propTypes = {
  toggleEditEmail: PropTypes.func.isRequired,
};

export default memo(EditEmail);

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is invalid")
    .trim()
    .required("*Required")
    .max(80, "Email is too long")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid Email"),
});
