import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useCallback, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTERS_BACKGROUND_COLOR } from "styles/colors";

export default function ReturnStatusTabs() {
  const navigate = useNavigate();
  const [value, setValue] = useState("ALL");

  const handleChange = useCallback(
    (event, value) => {
      setValue(value);
      let params = {};
      switch (value) {
        case "ALL":
          params = {};
          break;
        case "REQUESTED":
          params.refundStatus = "requested";
          break;
        case "APPROVED":
          params.refundStatus = "approved";
          break;
        case "REJECTED":
          params.refundStatus = "rejected";
          break;
        case "REFUNDED":
          params.refundStatus = "refunded";
          break;
        case "REPLACEMENT":
          params.refundStatus = "replacement";
          break;
        case "RETURNED":
          params.refundStatus = "returned";
          break;
        default:
          break;
      }
      navigate({
        pathname: "/orders/returns",
        search: createSearchParams(params).toString(),
      });
    },
    [navigate]
  );

  return (
    <Box py={1} sx={containerStyles}>
      <Box
        sx={{
          backgroundColor: FILTERS_BACKGROUND_COLOR,
          marginBottom: { xs: 2, md: 0 },
        }}
      >
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="return status filters"
          >
            <Tab label="All" value="ALL" />
            <Tab label="Requested" value="REQUESTED" />
            <Tab label="Approved" value="APPROVED" />
            <Tab label="Rejected" value="REJECTED" />
            <Tab label="Refunded" value="REFUNDED" />
            <Tab label="Replacement" value="REPLACEMENT" />
            <Tab label="Returned" value="RETURNED" />
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
}
const containerStyles = {
  display: { xs: "grid", md: "flex" },
  justifyContent: "space-between",
  alignItems: "baseline",
  "& .styled-button": {
    borderRadius: "1px",
    padding: "10px",
    fontSize: {
      md: "1rem",
      xs: "0.7rem",
    },
  },
  "& .MuiOutlinedInput-root": { borderRadius: "20px" },
  "& .MuiTab-root": { textTransform: "capitalize" },
};
