import { createTheme } from "@mui/material/styles";
import { BACKGROUND } from "styles/colors";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#068ECF",
    },
    secondary: {
      main: "#F5F5FB",
    },
    background: {
      default: BACKGROUND,
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1400,
      xxl: 1920,
    },
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiPaginationItem: {
      root: {
        borderRadius: "0% !important",
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: 50,
      },
    },
    props: {
      MuiTooltip: {
        arrow: true,
      },
    },
    shape: {
      borderRadius: 6,
    },
  },
});

export default theme;
