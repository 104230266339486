import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import DataGridSearch from "components/DataGridSearch";
import queryString from "query-string";
import { useCallback, useLayoutEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { FILTERS_BACKGROUND_COLOR } from "styles/colors";

export default function ProductStatusesButtons() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const [value, setValue] = useState("ALL_PRODUCTS");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  useLayoutEffect(() => {
    if (query.active) {
      setValue(query.active === "true" ? "ACTIVE" : "INACTIVE");
    }
    if (query.feature) {
      setValue(query.featured === "true" ? "FEATURED" : "NOT_FEATURED");
    }
  }, [query.active, query.feature, query.featured]);

  const handleChange = useCallback(
    (event, value) => {
      setValue(value);
      // Necessary for performance
      // eslint-disable-next-line no-unused-vars
      const { active, featured, ...rest } = query;
      let params = { ...rest };
      switch (value) {
        case "ACTIVE":
          params.active = true;
          break;
        case "INACTIVE":
          params.active = false;
          break;
        case "FEATURED":
          params.featured = true;
          break;
        case "NOT_FEATURED":
          params.featured = false;
          break;
        default:
          break;
      }
      navigate({
        pathname: "/products",
        search: createSearchParams(params).toString(),
      });
    },
    [navigate, query]
  );

  return (
    <Box py={1} sx={containerStyles}>
      <Box
        sx={{
          backgroundColor: FILTERS_BACKGROUND_COLOR,
          marginBottom: { xs: 2, md: 0 },
        }}
      >
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="product status filters"
          >
            <Tab label="All Products" value="ALL_PRODUCTS" />
            <Tab label="Active" value="ACTIVE" />
            <Tab label="Inactive" value="INACTIVE" />
            <Tab label="Featured" value="FEATURED" />
            <Tab label="Unfeatured" value="NOT_FEATURED" />
          </Tabs>
        </Box>
      </Box>
      <Box display={isMobile ? "grid" : "flex"} pt={2}>
        <DataGridSearch
          searchKeyParam="name"
          searchKeyParamValue="productName"
          searchQueryParam="value"
        />
      </Box>
    </Box>
  );
}
const containerStyles = {
  display: { xs: "grid", md: "flex" },
  flexDirection: { xs: "column", md: "column", lg: "row" },
  justifyContent: "space-between",
  alignItems: "baseline",
  "& .styled-button": {
    borderRadius: "1px",
    padding: "10px",
    fontSize: {
      md: "1rem",
      xs: "0.7rem",
    },
  },
  "& .MuiOutlinedInput-root": { borderRadius: "20px" },
  "& .MuiTab-root": { textTransform: "capitalize" },
};
