import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormSearch from "components/FormSearch";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAdminProducts } from "store/slices/productsSlice";
import useQueryParams from "utils/hooks/useQueryParams";

function ProductInformationSection({ formik }) {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const _params = useParams();
  const { page, limit } = useSelector((state) => state?.products);

  useEffect(() => {
    let timeOut = "";
    if (!_params?.id) {
      timeOut = setTimeout(() => {
        dispatch(getAdminProducts(params ? params : { page, limit }));
      }, 500);
    }
    return () => timeOut && clearTimeout(timeOut);
  }, [_params, dispatch, limit, page, params]);

  return (
    <StyledMuiCard
      title="Product Information"
      subTitle="Please enter the basic information of the product such as name and description"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={3}
          py={{ xs: 1, md: 1.5 }}
          paddingLeft={1.5}
        >
          <Stack direction="column" gap={2}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Product Name
            </Typography>
            {_params?.id ? (
              <StyledTextField
                disabled={true}
                value={formik?.values?.productName}
                label="Product name"
                error={Boolean(
                  formik.touched.productId && formik.errors.productId
                )}
                helperText={
                  formik.touched.productId && !!formik.errors.productId
                    ? formik.errors.productId
                    : "Enter a descriptive name to help customer find your product"
                }
              />
            ) : (
              <FormSearch
                formik={formik}
                searchKeyParam="name"
                searchKeyParamValue="productName"
                searchQueryParam="value"
              />
            )}
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

ProductInformationSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(ProductInformationSection);
