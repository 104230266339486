import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Actions from "components/Mastertable/Actions";
import DataGrid from "components/Mastertable/DataGrid";
import Filters from "components/Mastertable/Filters";
import MasterTableForm from "components/Mastertable/MasterTableForm";
import DateFilters from "components/Mastertable/MasterTableForm/DateFilters";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { memo } from "react";

const MasterTable = ({ create, update }) => {
  if (create || update) {
    return <MasterTableForm />;
  }

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          Master Table
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
      >
        Master Table
      </Typography>
      <Box display="flex" justifyContent="space-between" py={2}>
        <Actions />
        <DateFilters />
      </Box>
      <Filters />
      <DataGrid />
    </Box>
  );
};

export default memo(MasterTable);

MasterTable.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

MasterTable.defaultProps = {
  create: false,
  update: false,
};
