import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { memo } from "react";

function PageTitle({ title }) {
  return (
    <Typography
      variant="h1"
      color="primary"
      fontSize="2.6rem"
      fontWeight="500"
      mt={1.5}
      mb={2}
    >
      {title ?? "Page Title"}
    </Typography>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

PageTitle.defaultProps = {
  title: "Page Title",
};

export default memo(PageTitle);
