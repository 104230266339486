import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getSellers = createAsyncThunk(
  "getSellers",
  // async (dataProvidedInFunctionParameter, { getState, dispatch }) => {}
  async () => {}
);

export const getSeller = createAsyncThunk("getSeller", async () => {});

export const addSeller = createAsyncThunk("addSeller", async () => {});

export const updateSeller = createAsyncThunk("updateSeller", async () => {});

export const deleteSeller = createAsyncThunk("deleteSeller", async () => {});

const sellersSlice = createSlice({
  name: "sellers",
  initialState: {
    loading: true,
    seller: null,
    results: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: "",
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.seller = null;
      state.results = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = "";
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase();
  // },
});

// Action creators generated for each case reducer function
export const { resetState } = sellersSlice.actions;

// Exporting default reducer
export default sellersSlice.reducer;
