import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import _, { debounce } from "lodash";
import PropTypes from "prop-types";
import { memo, useLayoutEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTERS_BORDER_COLOR, SEARCH_ICON_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

function DataGridSearch({
  searchKeyParam,
  searchKeyParamValue,
  searchQueryParam,
}) {
  const params = useQueryParams();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  const handleChange = debounce((value) => {
    // Remove symbols and special characters from the input value
    const sanitizedValue = value.trimStart().replace(/[^\w\s]/gi, "");

    let _params = { ...params };
    if (sanitizedValue) {
      if (searchKeyParam) _params[searchKeyParam] = searchKeyParamValue;
      _params[searchQueryParam] = sanitizedValue;
    } else {
      _params = _.omit(
        params,
        searchKeyParam ? [searchKeyParam, searchQueryParam] : [searchQueryParam]
      );
    }
    navigate({
      search: createSearchParams(_params).toString(),
    });
  }, 500);

  useLayoutEffect(() => {
    const id = setTimeout(() => {
      if (params[searchQueryParam]) setSearchValue(params[searchQueryParam]);
      else setSearchValue("");
    }, 1000);
    return () => id && clearTimeout(id);
  }, [params, searchQueryParam]);

  return (
    <Box sx={containerStyles}>
      <TextField
        id="outlined-search"
        placeholder="Search"
        type="search"
        size="small"
        value={searchValue}
        sx={{
          "& .MuiOutlinedInput-input": {
            width: "80%",
            overflow: "hidden",
            textOverflow: " ellipsis",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: SEARCH_ICON_COLOR }} />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          handleChange(e.target.value);
          setSearchValue(e.target.value.trimStart());
        }}
      />
    </Box>
  );
}

export default memo(DataGridSearch);
DataGridSearch.propTypes = {
  searchKeyParam: PropTypes.string,
  searchKeyParamValue: PropTypes.string,
  searchQueryParam: PropTypes.string.isRequired,
};

const containerStyles = {
  textAlign: "end",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
      borderRadius: "20px",
    },
    "&:hover": {
      backgroundColor: "#edf8fd",
      "& fieldset": {
        borderColor: FILTERS_BORDER_COLOR,
      },
    },
  },
  "&:hover": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
  },
};
