import axios from "axios";
import { signInWithPhoneNumber } from "firebase/auth";
import {
  refreshTokens,
  setPhoneOTPStatus,
  verifyPhoneNumber,
} from "store/slices/authSlice";
import { setSessionExpiryDialogOpen } from "store/slices/uiSlice";
import { getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const recoverProfilePassword = (email) => {
  return http.post(`/auth/forgot-password`, {
    email,
  });
};

export const resetProfilePassword = (password, token) => {
  return http.post(`/auth/reset-password?token=${token}`, {
    password,
  });
};

export const getNewAccessToken = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/refresh-tokens`,
      {
        refreshToken: localStorage.getItem("refresh-token"),
      }
    );
    const tokens = getResponseData(response)?.tokens;
    localStorage.setItem("token", tokens?.access?.token);
    localStorage.setItem("refresh-token", tokens?.refresh?.token);
    window?.dispatch(refreshTokens(tokens));
    return tokens?.access?.token;
  } catch (error) {
    const errorData = error?.response?.data?.data;
    if (errorData?.code === 401 && errorData?.message === "jwt expired") {
      window?.dispatch(setSessionExpiryDialogOpen());
    }
    return new Error(error);
  }
};

export const sendPhoneOTP = (auth, phoneNumber, setError, setIsSubmitting) => {
  if (window?.recaptchaVerifier) {
    setIsSubmitting(true);
    signInWithPhoneNumber(auth, phoneNumber, window?.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        window?.dispatch(setPhoneOTPStatus(true));
        toast.success("Verification code sent to your phone.");
      })
      .catch((error) => {
        window?.grecaptcha &&
          window?.grecaptcha?.reset(
            window?.recaptchaWidgetId ??
              window?.recaptchaVerifier?.widgetId ??
              ""
          );
        if (error?.message?.includes("auth/too-many-requests")) {
          setError("Too many SMS requests. Please try again later.");
        } else {
          setError("Something went wrong. Please try reloading the page.");
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }
};

export const verifyPhoneOTP = (phoneOTP, setError, setIsSubmitting) => {
  if (window?.confirmationResult?.confirm) {
    setIsSubmitting(true);
    window?.confirmationResult
      ?.confirm(phoneOTP)
      .then((response) => {
        const token = response?._tokenResponse?.idToken;
        window.dispatch(verifyPhoneNumber(token));
      })
      .catch((error) => {
        if (error?.message?.includes("INVALID_CODE")) {
          return setError("Invalid Verification Code");
        }
        if (error?.message?.includes("auth/invalid-verification-code")) {
          return setError("Invalid Verification Code");
        }
        if (error?.message?.includes("auth/code-expired")) {
          return setError("Verification Code Expired");
        }
        if (error?.message?.includes("SESSION_EXPIRED")) {
          return setError("OTP expired. Request a new OTP.");
        }
        return setError("Verification Code Expired");
      })
      .finally(() => {
        return setIsSubmitting(false);
      });
  } else {
    setError("Session expired. Please resend the phone code.");
  }
};

export const sendEmailOTP = (email, setError, setIsSubmitting) => {
  setIsSubmitting && setIsSubmitting(true);
  http
    .post(`/auth/resend-code`, {
      email,
    })
    .then(() => {
      toast.success("Verification code sent to your email.");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      setError &&
        setError(
          error?.response?.data?.message ??
            "Something went wrong. Please try reloading the page."
        );
    })
    .finally(() => {
      setIsSubmitting && setIsSubmitting(false);
    });
};

export const uploadFrontCNICImage = (authId = "", image = null, type = "") => {
  const formData = new FormData();
  formData.append(type, image);
  return http.post(`/sellerConfidentialDetail/${authId}`, formData);
};

export const uploadBackCNICImage = (authId = "", image = null, type = "") => {
  const formData = new FormData();
  formData.append(type, image);
  return http.post(`/sellerConfidentialDetail/${authId}`, formData);
};
