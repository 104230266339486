import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import queryString from "query-string";
import { useCallback, useLayoutEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

export default function CollectionStatusBar() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const [value, setValue] = useState("ACTIVE");

  useLayoutEffect(() => {
    if (query.active) {
      setValue(query.active === "true" ? "ACTIVE" : "INACTIVE");
    }
  }, [query.active]);

  const handleChange = useCallback(
    (event, value) => {
      setValue(value);
      // Necessary for performance
      // eslint-disable-next-line no-unused-vars
      const { active, featured, ...rest } = query;
      let params = { ...rest };
      switch (value) {
        case "ALL":
          params.active = "";
          break;
        case "ACTIVE":
          params.active = true;
          break;
        case "INACTIVE":
          params.active = false;
          break;
        default:
          break;
      }
      navigate({
        pathname: "/products/collections",
        search: createSearchParams(params).toString(),
      });
    },
    [navigate, query]
  );

  return (
    <Box py={1} sx={containerStyles}>
      <Box sx={{ backgroundColor: "#fff", marginBottom: { xs: 2, md: 0 } }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="product status filters"
        >
          <Tab label="All" value="All" />
          <Tab label="Active" value="ACTIVE" />
          <Tab label="In Active" value="INACTIVE" />
        </Tabs>
      </Box>
    </Box>
  );
}
const containerStyles = {
  display: { xs: "grid", md: "flex" },
  justifyContent: "space-between",

  "& .styled-button": {
    borderRadius: "1px",
    padding: "10px",
    fontSize: {
      md: "1rem",
      xs: "0.7rem",
    },
  },
  "& .MuiOutlinedInput-root": { borderRadius: "20px" },
  "& .MuiTab-root": { textTransform: "capitalize" },
};
