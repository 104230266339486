export const PRIMARY = "#068ECF";
export const PRIMARY_LIGHT = "#e3f2fd";
export const BACKGROUND = "#f7fcfe";
export const LINK_TEXT = "#0266d6";
export const FOOTER_ICON_COLOR = "#ea5455";
export const STATS_PRIMARY_BLUE = "#068ecf";
export const STATS_ORANGE_SECONDARY = "#ff9f42";
export const STATS_PRIMARY_RED = "#ea5455";
export const STATS_CARD_TEXT_COLOR = "#707070";
export const STATS_NUMBER_FORMATTER_COLOR = "#3f3f3f";
export const FILTER_TEXT_COLOR = "#707070";
export const SEARCH_ICON_COLOR = "#a8a8a8";
export const KEY_NUMBERS_BACKGROUND_COLOR = "#eafafd";
export const ACTIVE_COLOR = "#00b850";
export const INACTIVE_COLOR = "#707070";
export const CARD_HEADER_BACKGROUND = "#f6f6f6";
export const WHATSAPP = "#5FFC7B";
export const FACEBOOK = "#3B5998";
export const TWITTER = "#00ACEE";
export const YOUTUBE = "#FF0000";
export const BUTTON_HOVER = "#edf8fd";
export const BUTTON_HOVER_BORDER_COLOR = "#02b4fffc";
export const FILTERS_BORDER_COLOR = "#069ddd80";
export const FILTERS_BACKGROUND_COLOR = "#ffffff";
export const DASHBOARD_CHART_ROW_COLOR = "#f3f3f3";
export const SPEEDOMETER_TEXT_COLOR = "#3f3f3f";
export const SPEEDOMETER_NEEDLE_COLOR = "#a7ff83";
export const SPEEDOMETER_SEGMENT_COLOR = "#817f7f";
export const DASHBOARD_CARD_TEXT_COLOR = "5e5873";
export const TEXTFIELD_BACKGROUND_COLOR = "#E6F4FB";
export const BORDER_LIGHT = "#0000001f";
