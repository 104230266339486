import Box from "@mui/material/Box";
import DataGridSearch from "components/DataGridSearch";
import AddAction from "./AddAction";
import CollectionStatusBar from "./CollectionStatusBar";

function index() {
  return (
    <>
      <AddAction />
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <CollectionStatusBar />
        <DataGridSearch
          searchKeyParam="searchKey"
          searchKeyParamValue="name"
          searchQueryParam="searchValue"
        />
      </Box>
    </>
  );
}

export default index;
