import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Box from "@mui/system/Box";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addAddress,
  getCities,
  updateAddress,
} from "store/slices/checkoutSlice";
import formatPhoneNumber from "utils/helpers/formatPhoneNumber";

function AddForm({ addressType, isEditing, handleClose, phoneNumber }) {
  const dispatch = useDispatch();
  const cities = useSelector((state) => state.checkout.cities ?? []);
  const addresses = useSelector((state) => state.checkout.addresses ?? []);
  const defaultAddress = useSelector(
    (state) => state.cart.cartData?.user?.defaultAddress ?? {}
  );
  const [cityInputValue, setCityInputValue] = useState("");
  const [initialValues, setInitialValues] = useState({
    address: "",
    addressType: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    city_code: "",
    fullname: "",
    phone: "",
  });

  useEffect(() => {
    if (!cities.length) {
      dispatch(getCities());
    }
  }, [dispatch, cities]);

  useEffect(() => {
    if (defaultAddress.id && isEditing && !initialValues.name) {
      const _city = cities.find(
        (city) => city.city_code === defaultAddress.city_code
      );
      const {
        address,
        addressType,
        city,
        country,
        state,
        zipCode,
        city_code,
        fullname,
        phone,
      } = addresses.find((address) => address.id === defaultAddress.id);
      setInitialValues({
        ...{
          address,
          addressType,
          city,
          country,
          state,
          zipCode,
          city_code,
          fullname,
          phone: formatPhoneNumber(phone),
        },
        city_code: _city,
      });
    }
  }, [defaultAddress, isEditing, cities, initialValues.name, addresses]);

  const handleFormSubmit = (values, { setSubmitting }) => {
    let data = {};
    if (addressType === "international") {
      data = { ...values };
      data.localType = addressType;
      delete data.addressLine2;
      delete data.city_code;
    } else {
      data.fullname = values.fullname;
      data.address = values.address;
      data.addressType = values.addressType;
      data.city_code = values.city_code.city_code;
      data.city = values.city_code.label;
    }
    data.phone = "+" + values.phone;
    if (isEditing) {
      data.addressId = defaultAddress.id;
      data.localType = addressType;

      return dispatch(updateAddress({ data, phoneNumber }))
        .unwrap()
        .then((response) => {
          if (!response.code) {
            handleClose();
          }
          setSubmitting(false);
        });
    } else {
      return dispatch(addAddress({ data, phoneNumber }))
        .unwrap()
        .then((response) => {
          if (!response.code) {
            handleClose();
          }
          setSubmitting(false);
        });
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <Box component="form" mt={2} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Full Name"
                variant="outlined"
                required
                fullWidth
                type="text"
                name="fullname"
                inputProps={{ maxLength: 20 }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fullname}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneInput
                country="pk"
                value={values.phone}
                onBlur={handleBlur}
                countryCodeEditable={false}
                disableDropdown={addressType === "local" && true}
                onChange={(number) => setFieldValue("phone", number)}
                inputProps={{
                  name: "phone",
                }}
              />
            </Grid>
            {addressType === "local" && (
              <>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Address"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="address"
                    inputProps={{ maxLength: 90 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                  />
                </Grid>
                <Grid item md={6} xs={12} sm={6} lg={6}>
                  {/* TODO: filter out for same component key */}
                  <Autocomplete
                    options={cities}
                    value={values.city_code}
                    onChange={(event, newValue) => {
                      setFieldValue("city_code", newValue);
                    }}
                    inputValue={cityInputValue}
                    onInputChange={(event, newInputValue) => {
                      setCityInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} label="City" />
                    )}
                  />
                </Grid>
              </>
            )}
            {addressType === "international" && (
              <>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Address"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="address"
                    inputProps={{ maxLength: 90 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="City"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="city"
                    inputProps={{ maxLength: 20 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Country"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="country"
                    inputProps={{ maxLength: 20 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="State"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="state"
                    inputProps={{ maxLength: 15 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Zip code"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="zipCode"
                    inputProps={{ maxLength: 10 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zipCode}
                  />
                </Grid>
              </>
            )}
            <Grid item md={6} xs={12} sm={6} lg={6}>
              <TextField
                select
                name="addressType"
                label="Address Type"
                value={values.addressType}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              >
                <MenuItem value="home">Home</MenuItem>
                <MenuItem value="office">Office</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={12}>
              <Button
                disabled={isSubmitting}
                color="primary"
                variant="contained"
                type="submit"
                sx={{ marginTop: "10px" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
}

AddForm.propTypes = {
  addressType: PropTypes.string,
  handleClose: PropTypes.func,
  isEditing: PropTypes.bool,
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default memo(AddForm);
