import styled from "@emotion/styled";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetState, userLogin } from "store/slices/authSlice";
import { LINK_TEXT, PRIMARY_LIGHT } from "styles/colors";
import * as Yup from "yup";

export default function SignUpForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [showPassword, setShowPassword] = useState(false);
  const error = useSelector((state) => state.auth.error);

  const initialValues = {
    email: "",
    password: "",
  };
  const handleSubmit = (values) => {
    dispatch(
      userLogin({
        email: values.email,
        password: values.password,
      })
    )
      .unwrap()
      .then(() => {
        return navigate("/dashboard");
      });
  };

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  return (
    <Container>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Stack
              direction="column"
              alignContent="center"
              justifyContent="center"
              minHeight="100vh"
              maxWidth={{ sm: "250px", md: "450px", lg: "400px", xl: "535px" }}
              m="auto"
              spacing={{ xs: 2, md: 2.4, lg: 4 }}
            >
              <Typography
                variant="h3"
                component="h2"
                fontSize={{
                  xs: "1.75rem",
                  sm: "2rem",
                  md: "3.25rem",
                  lg: "4rem",
                }}
                lineHeight={1}
              >
                Log In
              </Typography>
              <Typography
                component="h3"
                color="text.secondary"
                fontSize={{
                  xs: "1rem",
                  sm: "1.15rem",
                  md: "1.5rem",
                  lg: "2rem",
                }}
                lineHeight={1}
              >
                Please Login to your account
              </Typography>
              <StyledTextField
                fullWidth
                type="email"
                label="Email Address"
                size={isSmall ? "small" : "large"}
                {...formik.getFieldProps("email")}
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Box>
                <StyledTextField
                  fullWidth
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  size={isSmall ? "small" : "large"}
                  {...formik.getFieldProps("password")}
                  error={Boolean(
                    formik.touched.password && formik.errors.password
                  )}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {error && (
                  <Typography color="error" mt={2}>
                    Incorrect Email or Password
                  </Typography>
                )}
                <Box mt={1} display="flex" justifyContent="flex-end">
                  <Typography
                    component={Link}
                    to="/recover-password"
                    color={LINK_TEXT}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline !important",
                    }}
                  >
                    Forgot Password
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                type="submit"
                size={isSmall ? "small" : "large"}
                sx={{
                  fontWeight: "bold",
                  fontSize: { sm: "0.75rem", md: "1.2rem", xl: "1.25rem" },
                }}
                disabled={!(formik.isValid && formik.dirty)}
              >
                Log In
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: PRIMARY_LIGHT,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is invalid")
    .trim()
    .required("*Required")
    .max(80, "Email is too long")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid Email"),
  password: Yup.string()
    .required("Required*")
    .min(8, "Password must be at least 8 characters"),
});
