import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { memo } from "react";
import { useSelector } from "react-redux";
import { ACTIVE_COLOR } from "styles/colors";
import returnStatusBadges from "utils/helpers/returnStatusBadge copy";

function BasicReturnInfo() {
  const returnedData = useSelector((state) => state.returns.refundData);

  return (
    <Card>
      <CardContent
        sx={{
          "&:last-child": {
            paddingBottom: "1rem",
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Box display="flex">
              <Typography
                variant="body1"
                mr={1}
                fontSize={{ xs: "0.5rem", md: "0.75rem", lg: "1rem" }}
              >
                Date:
              </Typography>
              <Typography
                color={ACTIVE_COLOR}
                fontSize={{ xs: "0.5rem", md: "0.75rem", lg: "1rem" }}
              >
                {dayjs(returnedData?.refundDate).format("DD/MM/YYYY")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              fontSize={{ xs: "0.5rem", md: "0.75rem", lg: "1rem" }}
            >
              Seller Name: {returnedData?.seller?.fullname ?? "N/A"}
            </Typography>
            <Typography
              variant="body1"
              fontSize={{ xs: "0.5rem", md: "0.75rem", lg: "1rem" }}
            >
              Return By: {returnedData?.refundTo?.fullname ?? "N/A"}
            </Typography>
          </Box>
          <Stack direction="column" gap={1}>
            <Typography
              variant="body1"
              fontWeight="bold"
              fontSize={{ xs: "0.5rem", md: "0.75rem", lg: "1rem" }}
            >
              Status
            </Typography>
            <Chip
              sx={{
                backgroundColor: returnStatusBadges(returnedData?.refundStatus),
                color: "#fff",
                borderRadius: "5px",
                height: "26px",
                textTransform: "capitalize",
              }}
              variant="contained"
              label={returnedData?.refundStatus}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default memo(BasicReturnInfo);
