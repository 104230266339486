import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "UI",
  initialState: {
    isSidebarOpen: true,
    isSessionExpiryDialogOpen: false,
    showToast: false,
    toastSeverity: "success",
    toastMessage: "",
    isSelectProductStoreDialogOpen: false,
    isConfirmDeletionDialogOpen: false,
    deleteId: "",
    isImageValidationDialogOpen: false,
    imageValidationError: "",
    isValidationDialogOpen: false,
    validationMessage: "",
    language: "En",
    isFacebookCatalogDialogOpen: false,
  },
  reducers: {
    setSideBarOpen: (state) => {
      state.isSidebarOpen = true;
    },
    setSideBarClose: (state) => {
      state.isSidebarOpen = false;
    },
    closeToast: (state) => {
      state.showToast = false;
      state.toastMessage = "";
      state.toastSeverity = "success";
    },
    showToast: (state, action) => {
      state.showToast = true;
      state.toastMessage = action.payload.message;
      if (action.payload.severity)
        state.toastSeverity = action.payload.severity;
    },
    setSessionExpiryDialogOpen: (state) => {
      state.isSessionExpiryDialogOpen = true;
    },
    setSessionExpiryDialogClose: (state) => {
      state.isSessionExpiryDialogOpen = false;
    },
    setConfirmDeletionDialogOpen: (state) => {
      state.isConfirmDeletionDialogOpen = true;
    },
    setDeleteId: (state, action) => {
      if (action) {
        state.deleteId = action.payload;
      }
    },
    resetDeleteDialogState: (state) => {
      state.deleteId = "";
      state.isConfirmDeletionDialogOpen = false;
    },
    resetValidationDialog: (state) => {
      state.isValidationDialogOpen = false;
      state.validationMessage = "";
    },
    openSelectProductStoreDialog: (state) => {
      state.isSelectProductStoreDialogOpen = true;
    },
    closeSelectProductStoreDialog: (state) => {
      state.isSelectProductStoreDialogOpen = false;
    },
    setIsImageValidationDialogOpen: (state, action) => {
      state.isImageValidationDialogOpen = action.payload;
      if (!action.payload) state.imageValidationError = "";
    },
    setImageValidationError: (state, action) => {
      state.imageValidationError = action.payload;
    },
    setValidationDialogOpen: (state, action) => {
      state.isValidationDialogOpen = action.payload;
    },
    setValidationMessage: (state, action) => {
      state.validationMessage = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setIsFacebookCatalogDialogOpen: (state, action) => {
      state.isFacebookCatalogDialogOpen = action.payload;
    },
  },
});

export const {
  setSideBarOpen,
  setSideBarClose,
  openSelectProductStoreDialog,
  closeSelectProductStoreDialog,
  closeToast,
  showToast,
  setSessionExpiryDialogOpen,
  setSessionExpiryDialogClose,
  setConfirmDeletionDialogOpen,
  setDeleteId,
  setIsImageValidationDialogOpen,
  setImageValidationError,
  setValidationDialogOpen,
  setValidationMessage,
  setLanguage,
  setIsFacebookCatalogDialogOpen,
} = uiSlice.actions;

export const { resetDeleteDialogState, resetValidationDialog } =
  uiSlice.actions;

export default uiSlice.reducer;
