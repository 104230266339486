import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useSelector } from "react-redux";
import { CARD_HEADER_BACKGROUND } from "styles/colors";
import SummaryTile from "./SummaryTile";

function SummarySection() {
  const order = useSelector((state) => state.orders.order);

  return (
    <Card>
      <CardContent
        sx={{
          p: 0,
          "&:last-Child": {
            p: 0,
          },
        }}
      >
        <Box bgcolor={CARD_HEADER_BACKGROUND} p={2}>
          <Typography variant="h6" fontWeight="bold">
            Total Summary
          </Typography>
        </Box>
        <Stack direction="column" p={2} gap={1}>
          <SummaryTile
            title="Product Total"
            value={order?.retailTotal ?? 0}
            price
          />
          <SummaryTile title="Discount" value={order?.discount ?? 0} price />
          <SummaryTile
            title="Admin Discount"
            value={order?.adminDiscount ?? 0}
            price
            divider
          />
          <SummaryTile title="Sub Total" value={order?.subTotal ?? 0} price />
          <SummaryTile
            title="Shipment Charges"
            value={order?.shippmentCharges ?? 0}
            price
            divider
          />
          <SummaryTile
            title="Wallet"
            value={order?.customer?.wallet?.balance ?? 0}
            price
          />
          <SummaryTile title="Payable" value={order?.payable ?? 0} />
          <SummaryTile
            title="Payment Method"
            value={order?.paymentMethod ?? ""}
            paymentMethod
          />
          <SummaryTile
            title="Payment Status"
            value={order?.payment?.status ?? ""}
            isUppercase
            divider
          />
          <SummaryTile title="Total" value={order?.payable ?? 0} price isBold />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default memo(SummarySection);
