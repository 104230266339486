import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { memo } from "react";

function ContactUs() {
  return (
    <Box paddingTop="30px">
      <Typography
        variant="h3"
        color="primary"
        fontSize={{ xs: "0.75rem", sm: "1rem", md: "2rem" }}
        fontWeight="600"
      >
        Need Help? Contact Us
      </Typography>
      <Box py="10px">
        <Typography fontSize="1.5rem"> +92 301 1166-879 </Typography>
        <Typography fontSize="1.5rem"> hello@bazaarghar.com </Typography>
      </Box>
    </Box>
  );
}

export default memo(ContactUs);
