import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import DataGridSearch from "components/DataGridSearch";
import queryString from "query-string";
import { useCallback, useLayoutEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

export default function GroupBuyStatusBar() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const [value, setValue] = useState("ALL_PRODUCTS");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  useLayoutEffect(() => {
    if (query.status) {
      setValue(
        query.status === "active"
          ? "ACTIVE"
          : query.status === "pending"
          ? "PENDING"
          : query.status === "finished"
          ? "FINISHED"
          : "CANCELLED"
      );
    }
  }, [query.status]);

  const handleChange = useCallback(
    (_, value) => {
      setValue(value);
      // Necessary for performance
      // eslint-disable-next-line no-unused-vars
      const { status, ...rest } = query;
      let params = { ...rest };
      switch (value) {
        case "ACTIVE":
          params.status = "active";
          break;
        case "PENDING":
          params.status = "pending";
          break;
        case "FINISHED":
          params.status = "finished";
          break;
        case "CANCELLED":
          params.status = "cancelled";
          break;
        default:
          break;
      }
      navigate({
        pathname: "/products/group-buy",
        search: createSearchParams(params).toString(),
      });
    },
    [navigate, query]
  );

  return (
    <Box py={1} sx={containerStyles}>
      <Box sx={{ backgroundColor: "#fff", marginBottom: { xs: 2, md: 0 } }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="product status filters"
          >
            <Tab label="All Products" value="ALL_PRODUCTS" />
            <Tab label="Active" value="ACTIVE" />
            <Tab label="Pending" value="PENDING" />
            <Tab label="Finished" value="FINISHED" />
            <Tab label="Cancelled" value="CANCELLED" />
          </Tabs>
        </Box>
      </Box>
      <Box display={isMobile ? "grid" : "flex"}>
        <DataGridSearch
          searchKeyParam="name"
          searchKeyParamValue="productName"
          searchQueryParam="value"
        />
      </Box>
    </Box>
  );
}
const containerStyles = {
  display: { xs: "grid", md: "flex" },
  justifyContent: "space-between",

  "& .styled-button": {
    borderRadius: "1px",
    padding: "10px",
    fontSize: {
      md: "1rem",
      xs: "0.7rem",
    },
  },
  "& .MuiOutlinedInput-root": { borderRadius: "20px" },
  "& .MuiTab-root": { textTransform: "capitalize" },
};
