import Autocomplete from "@mui/material/Autocomplete";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo } from "react";
import {
  warrantyPeriodUnits,
  warrantyTypes,
} from "utils/constants/productForm";

function ServiceSection({ formik }) {
  return (
    <StyledMuiCard
      title="Service"
      subTitle="Sellers can opt to provide warranty for the customers."
    >
      <CardContent>
        <Stack direction="column" gap={3}>
          {/* Warranty Type */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Warranty Type
            </Typography>
            <Autocomplete
              name="warrantyType"
              options={warrantyTypes}
              value={formik.values?.warrantyType}
              onChange={(_, value) => {
                if (value === null || value?.value === "none") {
                  formik.setFieldValue("warrantyPeriod", null);
                  formik.setFieldValue("warrantyTenure", "");
                } else if (
                  value?.value !== formik.values?.warrantyType?.value
                ) {
                  formik.setFieldValue("warrantyPeriod", null);
                  formik.setFieldValue("warrantyTenure", "");
                }
                formik.setFieldValue("warrantyType", value);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <StyledTextField
                  fullWidth
                  {...params}
                  placeholder="Please Select"
                  error={Boolean(
                    formik.touched?.warrantyType && formik.errors?.warrantyType
                  )}
                  helperText={
                    formik.touched?.warrantyType &&
                    !!formik.errors?.warrantyType
                      ? formik.errors?.warrantyType
                      : "Select the warranty of the product"
                  }
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
            />
          </Stack>

          {/* Warranty Period */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Warranty Period
            </Typography>
            <Autocomplete
              name="warrantyPeriod"
              options={warrantyPeriodUnits}
              value={formik.values?.warrantyPeriod}
              onChange={(_, value) => {
                if (value === null || value?.value === "none") {
                  formik.setFieldValue("warrantyTenure", "");
                } else if (
                  formik.values?.warrantyTenure?.value !== value?.value
                ) {
                  formik.setFieldValue("warrantyTenure", "");
                }
                formik.setFieldValue("warrantyPeriod", value);
              }}
              onBlur={formik.handleBlur}
              disabled={
                formik.values?.warrantyType?.value === "none" ||
                formik.values?.warrantyType === null
              }
              renderInput={(params) => (
                <StyledTextField
                  fullWidth
                  {...params}
                  placeholder="Please Select"
                  error={Boolean(
                    formik.touched?.warrantyPeriod &&
                      formik.errors?.warrantyPeriod
                  )}
                  helperText={
                    formik.touched?.warrantyPeriod &&
                    !!formik.errors?.warrantyPeriod
                      ? formik.errors?.warrantyPeriod
                      : "Select the warranty unit of the product"
                  }
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
            />
          </Stack>

          {/* Warranty Tenure */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Warranty Tenure
            </Typography>
            <StyledTextField
              fullWidth
              type="number"
              placeholder="Please Select"
              {...formik.getFieldProps("warrantyTenure")}
              disabled={
                formik.values?.warrantyPeriod?.value === "none" ||
                formik.values?.warrantyPeriod === null
              }
              error={Boolean(
                formik.touched?.warrantyTenure && formik.errors?.warrantyTenure
              )}
              helperText={
                formik.touched?.warrantyTenure &&
                !!formik.errors?.warrantyTenure
                  ? formik.errors?.warrantyTenure
                  : "Select the warranty unit of the product"
              }
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

ServiceSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(ServiceSection);
