import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import AnalyticCards from "components/Analytics";
import AnalyticTabPanel from "components/Analytics/AnalyticTabPanel";
import DateRange from "components/Pickers/DateRange";
import dayjs from "dayjs";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import useQueryParams from "utils/hooks/useQueryParams";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

const keywords = [
  "activeUsers",
  "addToCarts",
  "averagePurchaseRevenue",
  "cartToViewRate",
  "checkouts",
  "ecommercePurchases",
  "newUsers",
  "screenPageViews",
  "sessionsPerUser",
  "shippingAmount",
  "taxAmount",
  "totalAdRevenue",
  "totalPurchasers",
  "totalRevenue",
  "totalUsers",
  "conversions:add_to_cart",
  "conversions:add_to_wishlist",
];

function Analytics() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const users = {
    activeUsers: data?.activeUsers,
    newUsers: data?.newUsers,
    sessionsPerUser: data?.sessionsPerUser,
    totalUsers: data?.totalUsers,
    totalPurchasers: data?.totalPurchasers,
    screenPageViews: data?.screenPageViews,
    ecommercePurchases: data?.ecommercePurchases,
  };

  const orders = {
    addToCarts: data?.addToCarts,
    cartToViewRate: data?.cartToViewRate,
    checkouts: data?.checkouts,
    addToWishlist: data["conversions:add_to_wishlist"],
  };

  const revenues = {
    averagePurchaseRevenue: data?.averagePurchaseRevenue,
    totalAdRevenue: data?.totalAdRevenue,
    totalRevenue: data?.totalRevenue,
    taxAmount: data?.taxAmount,
    shippingAmount: data?.shippingAmount,
  };

  const handleDateChange = useCallback(
    (range) => {
      navigate({
        search: createSearchParams({
          ...params,
          from: dayjs(range.startDate).format("YYYY-MM-DD"),
          to: dayjs(range.endDate).format("YYYY-MM-DD"),
        }).toString(),
      });
    },
    [navigate, params]
  );

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    http
      .post("/google-analytics", {
        from: params?.from ?? dayjs().subtract(1, "month").format("YYYY-MM-DD"),
        to: params?.to ?? dayjs().format("YYYY-MM-DD"),
        metrics: keywords.map((keyword) => ({ name: keyword })),
      })
      .then((response) => {
        const responseData = getResponseData(response);
        setData(responseData);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        toast.error(errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [params]);

  return (
    <Box py={2} pr={2}>
      <Box display="flex" alignItems="center" pl={2}>
        <Typography
          variant="h4"
          component="h1"
          textTransform="uppercase"
          fontWeight={600}
          fontSize={{ md: "1rem", lg: "1.5rem" }}
          pr="1rem"
        >
          Analytics
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingY={1}
        paddingX={2}
      >
        Analytics
      </Typography>
      {isLoading ? (
        <Box
          pt="10vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : Object.keys(data).length ? (
        <>
          <Box textAlign="right">
            <DateRange onChange={handleDateChange} />
          </Box>
          <Box>
            <Box>
              <Tabs
                indicatorColor="none"
                sx={{
                  pl: "20px",
                  "& .MuiTab-root.Mui-selected": {
                    backgroundColor: "#068ECF",
                    color: "#fff",
                    borderRadius: 1,
                    fontSize: "1.2rem",
                  },
                  "& .MuiTab-root": {
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  },
                }}
                value={tabValue}
                onChange={handleChange}
              >
                <Tab label="Users" {...tabProps(0)} />
                <Tab label="Orders" {...tabProps(1)} />
                <Tab label="Revenues" {...tabProps(2)} />
              </Tabs>
            </Box>
            <AnalyticTabPanel value={tabValue} index={0}>
              <AnalyticCards title="Users" data={users} />
            </AnalyticTabPanel>
            <AnalyticTabPanel value={tabValue} index={1}>
              <AnalyticCards title="Orders" data={orders} />
            </AnalyticTabPanel>
            <AnalyticTabPanel value={tabValue} index={2}>
              <AnalyticCards title="Revenues" data={revenues} />
            </AnalyticTabPanel>
          </Box>
        </>
      ) : (
        <Typography
          variant="h6"
          component="h2"
          color="text.disabled"
          fontWeight={600}
          textAlign="center"
          pt={5}
        >
          No data found
        </Typography>
      )}
    </Box>
  );
}

export default Analytics;
