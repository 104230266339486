import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DataGridSearch from "components/DataGridSearch";
import { memo } from "react";
import { FILTERS_BORDER_COLOR } from "styles/colors";

function OrderProductSearch() {
  return (
    <Box sx={containerStyles}>
      <Typography
        variant="h3"
        color="primary"
        fontSize={{ xs: "0.75rem", sm: "1rem", md: "2rem" }}
        fontWeight="600"
      >
        Select Products
      </Typography>
      <DataGridSearch
        searchKeyParam="name"
        searchKeyParamValue="productName"
        searchQueryParam="value"
      />
    </Box>
  );
}

export default memo(OrderProductSearch);

const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "50px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
      borderRadius: "20px",
    },
    "&:hover": {
      backgroundColor: "#edf8fd",
      "& fieldset": {
        borderColor: FILTERS_BORDER_COLOR,
      },
    },
  },
  "&:hover": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
  },
};
