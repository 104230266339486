import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductGalleryImagePlaceholder from "assets/icons/ProductGalleryImagePlaceholder.svg";
import CurrencyFormatter from "components/CurrencyFormatter";
import PropTypes from "prop-types";
import { memo } from "react";
function ProductDetailCard({ packageItem }) {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  return (
    <Box display="flex" padding="10px" alignItems="center">
      <Box>
        <img
          src={
            packageItem?.product?.mainImage ?? ProductGalleryImagePlaceholder
          }
          height={
            isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
          }
          width={
            isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
          }
          style={{
            borderRadius: "10px",
            marginTop: 4,
            objectFit: "contain",
          }}
          alt={packageItem?.product?.productName ?? "product"}
        />
      </Box>
      <Box px="10px">
        <Typography
          sx={{
            textTransform: "capitalize",
            color: "#000000",
            overflow: "hidden",
            textOverflow: " ellipsis",
            lineClamp: "2",
            WebkitLineClamp: "2",
          }}
        >
          {packageItem?.product?.productName ?? "N/A"}
        </Typography>
        <Box display="flex">
          Vendor:
          <Typography sx={{ color: "primary.main", marginLeft: 0.5 }}>
            {packageItem?.seller?.sellerDetail
              ? packageItem?.seller?.sellerDetail?.brandName
              : packageItem?.seller?.fullName}
          </Typography>
        </Box>
        <CurrencyFormatter number={packageItem?.total} />
        <Box display="flex">
          Quantity:
          <Typography paddingLeft="5px">{packageItem?.quantity}</Typography>
        </Box>
        <Box display="flex">
          {packageItem.product?.selectedAttributes?.map((item, i) => (
            <Typography key={i} paddingRight="10px">
              {item.name}: {item.value}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

ProductDetailCard.propTypes = {
  packageItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    product: PropTypes.shape({
      id: PropTypes.string,
      mainImage: PropTypes.string,
      onSale: PropTypes.bool,
      productName: PropTypes.string,
      regularPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      salePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      selectedAttributes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ),
    }),
    seller: PropTypes.shape({
      fullName: PropTypes.string,
      sellerDetail: PropTypes.shape({
        brandName: PropTypes.string,
      }),
    }),
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default memo(ProductDetailCard);
