import { ACTIVE_COLOR } from "styles/colors";

export default function statusBadges(status) {
  let color = "";
  let bgColor = "";
  switch (status) {
    case StatusOptions.ACTIVE:
      color = ACTIVE_COLOR;
      bgColor = "#ccf1dc";
      break;
    case StatusOptions.PENDING:
      color = "#3cb2e2";
      bgColor = "#cbe2f9";
      break;
    case StatusOptions.SCHEDULED:
      color = "#FF9800";
      break;
    case StatusOptions.EXPIRED:
      color = "#9E9E9E";
      break;
    case StatusOptions.CANCEL:
      color = "#ff0000";
      bgColor = "#ffcccc";
      break;
    case StatusOptions.FINISHED:
      color = "#0577ae";
      bgColor = "#d5ecf7";
      break;
    case StatusOptions.REQUESTED_SELLER:
      color = "#00b850";
      break;
    case StatusOptions.REJECTED:
      color = "secondary.dark";
      break;
    case StatusOptions.REQUESTED:
      color = ACTIVE_COLOR;
      break;
    case StatusOptions.NEW:
      color = "#0577ae";
      bgColor = "#d5ecf7";
      break;
    case StatusOptions.READY:
      color = "#707070";
      bgColor = "#e8e9e7";
      break;
    case StatusOptions.CONFIRMED:
      color = ACTIVE_COLOR;
      bgColor = "#ccf1dc";
      break;
    case StatusOptions.COMPLETED:
      color = "#ff2c00";
      bgColor = "#ffd4cc";
      break;
    case StatusOptions.DELIVERED:
      color = "#0a22fe";
      bgColor = "#cedcff";
      break;
    case StatusOptions.REFUND_REQUESTED:
      color = "#0577ae";
      bgColor = "#d5ecf7";
      break;
    case StatusOptions.REFUND_APPROVED:
      color = ACTIVE_COLOR;
      bgColor = "#ccf1dc";
      break;
    case StatusOptions.REFUND_REJECTED:
      color = "#707070";
      bgColor = "#e8e9e7";
      break;
    case StatusOptions.REFUNDED:
      color = "#0a22fe";
      bgColor = "#cedcff";
      break;
    case StatusOptions.REFUND_REPLACEMENT:
      color = "#0a22fe";
      bgColor = "#cedcff";
      break;
    case StatusOptions.RETURNED:
      color = "#ff0000";
      bgColor = "#ffcccc";
      break;
    default:
      break;
  }
  return { color, bgColor };
}

const StatusOptions = {
  ACTIVE: "active",
  PENDING: "pending",
  SCHEDULED: "scheduled",
  EXPIRED: "expired",
  CANCEL: "cancel",
  FINISHED: "finished",
  REQUESTED_SELLER: "requested-seller",
  READY: "ready",
  NEW: "new",
  CONFIRMED: "confirmed",
  COMPLETED: "completed",
  DELIVERED: "delivered",
  REFUND_REQUESTED: "requested",
  REFUND_APPROVED: "approved",
  REFUND_REJECTED: "rejected",
  REFUNDED: "refunded",
  REFUND_REPLACEMENT: "replacement",
  RETURNED: "returned",
};
