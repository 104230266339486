import Box from "@mui/material/Box";
import { memo } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import {
  STATS_ORANGE_SECONDARY,
  STATS_PRIMARY_BLUE,
  STATS_PRIMARY_RED,
} from "styles/colors";

function RadialChart() {
  const { monthlyAnalytics } = useSelector((state) => {
    return state.dashboard;
  });

  const data = {
    series: [
      monthlyAnalytics?.users?.currMonthUsers?.user ?? 0,
      monthlyAnalytics?.users?.currMonthUsers?.supplier ?? 0,
      monthlyAnalytics?.users?.currMonthUsers?.requestedSeller ?? 0,
    ],
    options: {
      chart: {
        height: 300,
        type: "radialBar",
      },
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                return w.globals?.seriesTotals.reduce((total, num) => {
                  return total + num;
                }, 0);
              },
            },
          },
        },
      },
      labels: ["Customers", "Sellers", "Others"],
      colors: [STATS_PRIMARY_BLUE, STATS_ORANGE_SECONDARY, STATS_PRIMARY_RED],
    },
  };

  return (
    <Box id="chart">
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="radialBar"
        height={285}
      />
    </Box>
  );
}

export default memo(RadialChart);
