import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { Button, InputAdornment, Stack, useMediaQuery } from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPhoneOTPStatus, updatePhoneNumber } from "store/slices/authSlice";
import formatPhoneNumber from "utils/helpers/formatPhoneNumber";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import StyledTextField from "./StyledTextField";

function EditPhoneNumber({ toggleEditPhoneNumber }) {
  const dispatch = useDispatch();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const phoneNumber = useSelector(
    (state) => state.auth?.data?.user?.phone ?? ""
  );

  const formik = useFormik({
    initialValues: {
      phone: phoneNumber ?? "",
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const data = {
        phone: formatPhoneNumber(values?.phone),
      };
      dispatch(updatePhoneNumber(data))
        .unwrap()
        .then(() => {
          toast.success("Phone Number updated successfully");
          toggleEditPhoneNumber();
        })
        .catch((error) => {
          if (error === "Phone number already taken")
            return setErrors({ phone: "Phone Number already taken" });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction="column" spacing={1} alignItems="flex-end" pb={4}>
        <StyledTextField
          fullWidth
          label="Phone Number"
          type="tel"
          size={isSmall ? "small" : "large"}
          {...formik.getFieldProps("phone")}
          error={!!formik.touched.phone && !!formik.errors.phone}
          helperText={formik.touched.phone && formik.errors.phone}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIphoneIcon />
              </InputAdornment>
            ),
          }}
        />
        {formatPhoneNumber(formik.values.phone) !==
          formik?.initialValues?.phone && formik.isValid ? (
          <Button
            variant="contained"
            size="large"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          >
            Update
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            disabled={!formik.isValid}
            onClick={() => {
              dispatch(setPhoneOTPStatus(false));
              toggleEditPhoneNumber();
            }}
          >
            Next
          </Button>
        )}
      </Stack>
    </form>
  );
}

EditPhoneNumber.propTypes = {
  toggleEditPhoneNumber: PropTypes.func.isRequired,
};

export default memo(EditPhoneNumber);

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Required*")
    .matches(/^(?:\+92|0)(3)([0-9]{9})$/gm, "Invalid phone number"),
});
