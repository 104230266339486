import { Autocomplete, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo } from "react";
import { CARD_HEADER_BACKGROUND } from "styles/colors";
import { volumeUnits, weightUnits } from "utils/constants/productForm";

function DeliverySection({ formik }) {
  return (
    <StyledMuiCard
      title="Delivery"
      subTitle="Please ensure you have entered the right package weight (kg) and dimensions for accurate shipping fee calculations."
    >
      <CardContent>
        <Stack direction="column" gap={3}>
          {/* Volume Unit */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Package Volume Unit
            </Typography>
            <Autocomplete
              name="packageVolumeUnit"
              options={volumeUnits}
              value={formik.values?.packageVolumeUnit}
              onChange={(_, value) => {
                if (value === null || value?.value === "none") {
                  formik.setFieldValue("packageLength", "");
                  formik.setFieldValue("packageWidth", "");
                  formik.setFieldValue("packageHeight", "");
                }
                formik.setFieldValue("packageVolumeUnit", value);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <StyledTextField
                  fullWidth
                  {...params}
                  placeholder="Please Select"
                  error={Boolean(
                    formik.touched?.packageVolumeUnit &&
                      formik.errors?.packageVolumeUnit
                  )}
                  helperText={
                    formik.touched?.packageVolumeUnit &&
                    !!formik.errors?.packageVolumeUnit
                      ? formik.errors?.packageVolumeUnit
                      : "Select the volume unit of the package"
                  }
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
            />
          </Stack>

          {/* Package Dimensions */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Package Dimensions
            </Typography>
            <Box display="flex" gap={2}>
              <StyledTextField
                fullWidth
                type="number"
                label="Length"
                placeholder="Length"
                {...formik.getFieldProps("packageLength")}
                disabled={
                  formik.values?.packageVolumeUnit?.value === "none" ||
                  formik.values?.packageVolumeUnit === null
                }
                error={Boolean(
                  formik?.touched?.packageLength &&
                    formik?.errors?.packageLength
                )}
                helperText={
                  formik?.touched?.packageLength &&
                  !!formik?.errors?.packageLength
                    ? formik?.errors?.packageLength
                    : "Value should be greater than 0"
                }
                py={2}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
              <StyledTextField
                fullWidth
                type="number"
                label="Width"
                placeholder="Width"
                {...formik.getFieldProps("packageWidth")}
                disabled={
                  formik.values?.packageVolumeUnit?.value === "none" ||
                  formik.values?.packageVolumeUnit === null
                }
                error={Boolean(
                  formik?.touched?.packageWidth && formik?.errors?.packageWidth
                )}
                helperText={
                  formik?.touched?.packageWidth &&
                  !!formik?.errors?.packageWidth &&
                  formik?.errors?.packageWidth
                }
                py={2}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
              <StyledTextField
                fullWidth
                type="number"
                label="Height"
                placeholder="Height"
                {...formik.getFieldProps("packageHeight")}
                disabled={
                  formik.values?.packageVolumeUnit?.value === "none" ||
                  formik.values?.packageVolumeUnit === null
                }
                error={Boolean(
                  formik?.touched?.packageHeight &&
                    formik?.errors?.packageHeight
                )}
                helperText={
                  formik?.touched?.packageHeight &&
                  !!formik?.errors?.packageHeight &&
                  formik?.errors?.packageHeight
                }
                py={2}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </Box>
          </Stack>

          {/* Package Weight Section */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Package Weight (kg)
            </Typography>
            <Box>
              {/* <StyledTextField
                type="number"
                label="Weight"
                placeholder="Weight"
                {...formik.getFieldProps("packageWeight")}
                error={Boolean(
                  formik?.touched?.packageWeight &&
                    formik?.errors?.packageWeight
                )}
                helperText={
                  formik?.touched?.packageWeight &&
                  !!formik?.errors?.packageWeight
                    ? formik?.errors?.packageWeight
                    : "Weight should be greater than 0"
                }
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              /> */}
              <StyledTextField
                fullWidth
                type="number"
                label="Weight"
                placeholder="Weight"
                {...formik.getFieldProps("packageWeight")}
                InputProps={{
                  endAdornment: (
                    <Select
                      {...formik.getFieldProps("packageWeightUnit")}
                      sx={{
                        "& .MuiSelect-select": {
                          textTransform: "capitalize",
                          bgcolor: CARD_HEADER_BACKGROUND,
                          color: "text.secondary",
                          fontWeight: 500,
                          fontSize: "1.2rem",
                          minWidth: 40,
                          textAlign: "center",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          boxShadow: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                      }}
                    >
                      {weightUnits.map((option) => (
                        <MenuItem
                          sx={{
                            fontSize: 16,
                            textTransform: "none",
                            textAlign: "center",
                          }}
                          key={option}
                          value={option}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  ),
                }}
                error={Boolean(
                  formik?.touched?.packageWeight &&
                    formik?.errors?.packageWeight
                )}
                helperText={
                  formik?.touched?.packageWeight &&
                  !!formik?.errors?.packageWeight
                    ? formik?.errors?.packageWeight
                    : "Weight should be greater than 0"
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    pr: 0,
                  },
                  // "& .MuiOutlinedInput-input": {
                  //   fontSize: "1.25rem",
                  //   textAlign: "right",
                  //   padding: "14px",
                  // },
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

DeliverySection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(DeliverySection);
