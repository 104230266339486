import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import deleteIcon from "assets/icons/deleteIcon.svg";
import CurrencyFormatter from "components/CurrencyFormatter";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledGrid from "components/StyledGrid";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToCart,
  deleteProductFromCart,
  deleteUserCart,
  getUserCart,
} from "store/slices/cartSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import {
  ACTIVE_COLOR,
  FILTERS_BORDER_COLOR,
  INACTIVE_COLOR,
  PRIMARY,
} from "styles/colors";
import { calculateProductDiscount } from "utils/helpers/createOrderFormHelpers";
import AdminDiscountOptions from "./AdminDiscountOptions";

const StyledDel = styled("del")(() => ({}));

function Cart({ phoneNumber }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.cart.loading);
  const userId = useSelector((state) => state.cart.userId);
  const packageItems = useSelector((state) => state.cart.packageItems || []);
  const flattenedItems = [].concat(...packageItems);
  const discountedProducts = useSelector(
    (state) => state.cart.adminDiscountProducts || []
  );
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const handleClickOpen = useCallback(() => {
    dispatch(setConfirmDeletionDialogOpen());
    dispatch(setDeleteId(userId));
  }, [dispatch, userId]);

  const deleteCartProduct = useCallback(
    (packageId, productId) => {
      if (userId) {
        const data = {
          _package: packageId,
          product: productId,
          userId,
        };
        dispatch(deleteProductFromCart(data)).then(() =>
          dispatch(getUserCart(userId))
        );
      }
    },
    [dispatch, userId]
  );

  const handleQuantity = useCallback(
    (id, quantity) => {
      const data = {
        phoneNumber: phoneNumber,
        product: id,
        quantity: quantity,
      };
      dispatch(addProductToCart(data)).then(() =>
        dispatch(getUserCart(userId))
      );
    },
    [dispatch, phoneNumber, userId]
  );

  const columns = useMemo(
    () => [
      {
        field: "image",
        headerName: "Image",
        type: "actions",
        minWidth: 100,
        flex: 0.8,
        renderCell: (params) => (
          <Box>
            <img
              src={params?.row?.product?.mainImage}
              height={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              width={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 4,
                objectFit: "contain",
              }}
              alt={params?.row?.product?.productName}
            />
          </Box>
        ),
      },
      {
        field: "Name/Details",
        headerName: "Name/Details",
        minWidth: 180,
        flex: 1.5,
        renderCell: (params) => (
          <Box>
            <Typography
              sx={{
                height: {
                  xs: 40,
                  md: 45,
                },
                textTransform: "capitalize",
                color: "#000000",
                overflow: "hidden",
                textOverflow: " ellipsis",
                lineClamp: "2",
                WebkitLineClamp: "2",
              }}
            >
              {params?.row?.product?.productName}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  height: "12px",
                  width: "12px",
                  margin: "5px 6px 3px 0",
                  backgroundColor: params?.row?.product?.active
                    ? ACTIVE_COLOR
                    : INACTIVE_COLOR,
                  borderRadius: "20px",
                }}
              />
              <Typography>
                {params?.row?.product?.active ? "Active" : "Inactive"}
              </Typography>
            </Box>
            <Box display="flex">
              Vendor:
              <Typography sx={{ color: "primary.main", marginLeft: 0.5 }}>
                {params?.row?.seller?.sellerDetail
                  ? params?.row?.seller?.sellerDetail?.brandName
                  : params?.row?.seller?.sellerDetail?.fullName}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "price",
        headerName: "Price",
        minWidth: 90,
        flex: 0.5,
        renderCell: (params) =>
          params?.row?.product?.onSale ? (
            <Box>
              <CurrencyFormatter number={params?.row?.product?.salePrice} />
              <StyledDel
                data-test-id="regularPrice"
                className="product-price"
                sx={{ color: "error.dark" }}
              >
                <CurrencyFormatter
                  number={params?.row?.product?.regularPrice}
                />
              </StyledDel>
            </Box>
          ) : (
            <CurrencyFormatter number={params?.row?.product?.regularPrice} />
          ),
      },
      {
        field: "discount",
        headerName: "Discount",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => <AdminDiscountOptions item={params?.row} />,
      },
      {
        field: "total",
        headerName: "Total",
        minWidth: 70,
        flex: 0.5,
        renderCell: (params) => (
          <CurrencyFormatter
            number={
              discountedProducts
                ? calculateProductDiscount(params?.row)
                : params?.row?.total
            }
          />
        ),
      },
      {
        field: "quantity",
        headerName: "Quantity",
        minWidth: 100,
        flex: 0.5,
        renderCell: (params) => (
          <Box display="flex" alignItems="center">
            <IconButton
              variant="contained"
              sx={{
                width: 30,
                height: 30,
                borderRadius: 1,
                backgroundColor: PRIMARY,
                "& .MuiButton-startIcon": { margin: 0 },
                "&:disabled": { backgroundColor: "#e0e0e0" },
              }}
              disabled={loading || params?.row?.quantity === 1}
              onClick={() =>
                !loading &&
                handleQuantity(
                  params?.row?.product?.id,
                  params?.row?.quantity - 1
                )
              }
            >
              <RemoveIcon fontSize="medium" sx={{ color: "#fff" }} />
            </IconButton>
            <Typography sx={{ padding: "10px" }}>
              {params?.row?.quantity ?? "N/A"}
            </Typography>
            <IconButton
              variant="contained"
              disabled={
                loading || params?.row?.quantity >= params.row.product.quantity
              }
              sx={{
                width: 30,
                height: 30,
                borderRadius: 1,
                backgroundColor: PRIMARY,
                "& .MuiButton-startIcon": { margin: 0 },
                "&:disabled": { backgroundColor: "#e0e0e0" },
              }}
              onClick={() =>
                !loading &&
                handleQuantity(
                  params?.row?.product?.id,
                  params?.row?.quantity + 1
                )
              }
            >
              <AddIcon fontSize="medium" sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 70,
        flex: 0.5,
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <IconButton
              variant="text"
              sx={{
                minWidth: "20px",
              }}
              onClick={() =>
                deleteCartProduct(
                  params?.row?.packageId,
                  params?.row?.product?.id
                )
              }
            >
              <img src={deleteIcon} alt="edit" className="icon-size" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [
      isTablet,
      isLaptop,
      isLed,
      discountedProducts,
      loading,
      handleQuantity,
      deleteCartProduct,
    ]
  );
  return (
    <>
      <Box>
        <Box sx={containerStyles}>
          <Typography
            variant="h3"
            color="primary"
            fontSize={{ xs: "0.75rem", sm: "1rem", md: "2rem" }}
            fontWeight="600"
          >
            Cart
          </Typography>
          <Button
            variant="outlined"
            sx={{ backgroundColor: "#fff", color: "#000" }}
            onClick={() => handleClickOpen()}
          >
            Clear Cart
          </Button>
        </Box>
        <StyledGrid
          rows={flattenedItems}
          columns={columns}
          totalPages={1}
          loading={loading}
        />
        <ConfirmDelete deleteAction={deleteUserCart} />
      </Box>
    </>
  );
}

export default memo(Cart);

const actionStyles = {
  "& .button-styles": { minWidth: "15px" },
};

const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "30px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
      borderRadius: "20px",
    },
    "&:hover": {
      backgroundColor: "#edf8fd",
      "& fieldset": {
        borderColor: FILTERS_BORDER_COLOR,
      },
    },
  },
  "&:hover": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
  },
};

Cart.propTypes = {
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};
