import { createSlice } from "@reduxjs/toolkit";
import arraysEqual from "utils/helpers/arraysEqual";
import getUniqueListByKey from "utils/helpers/getUniqueListByKey";

const initialState = {
  rooms: [],
  activeRoom: {
    _id: "",
    storeId: "",
    sellerId: "",
    brandName: "",
    customerId: "",
    customerName: "",
    conversation: {
      messages: [],
      paginatedMessages: [],
      page: 1,
      limit: 10,
      totalPages: 1,
      totalResults: 0,
    },
  },
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetState: () => initialState,
    setRooms: (state, action) => {
      const uniqueRooms = getUniqueListByKey(
        action.payload.map((room) => ({ ...room, ...room._id })),
        "customerId"
      );
      if (!arraysEqual(state.rooms, uniqueRooms)) {
        state.rooms = [...uniqueRooms];
      }
    },
    setActiveRoom: (state, action) => {
      state.activeRoom._id = action.payload?._id;
      state.activeRoom.storeId = action.payload?.storeId;
      state.activeRoom.sellerId = action.payload?.sellerId;
      state.activeRoom.brandName = action.payload?.brandName;
      state.activeRoom.customerId = action.payload?.customerId;
      state.activeRoom.customerName = action.payload?.customerName;
      state.activeRoom.conversation = {
        messages: [],
        paginatedMessages: [],
        page: 1,
        limit: 10,
        totalPages: 1,
        totalResults: 0,
      };
    },
    setMessages: (state, action) => {
      state.activeRoom._id = action.payload.roomId;

      if (action.payload.messages) {
        let _messages = [];
        const { limit, page, totalPages, totalResults, results } =
          action.payload.messages;
        results.forEach((m) => {
          _messages.push({
            ...m,
            reply: m.receiverId === action.payload.userId,
          });
        });
        state.activeRoom.conversation.messages = getUniqueListByKey(
          _messages,
          "id"
        );
        state.activeRoom.conversation.limit = limit;
        state.activeRoom.conversation.page = page;
        state.activeRoom.conversation.totalPages = totalPages;
        state.activeRoom.conversation.totalResults = totalResults;
      } else {
        state.activeRoom = {
          ...state.activeRoom,
          conversation: {
            messages: [],
            paginatedMessages: [],
            page: 1,
            limit: 10,
            totalPages: 1,
            totalResults: 0,
          },
        };
      }
    },
    prependMessages: (state, action) => {
      let _messages = [];
      const { limit, page, totalPages, totalResults, results } = action.payload;
      results.forEach((m) => {
        _messages.push({
          ...m,
          reply: m.receiverId === action.payload.userId,
        });
      });
      state.activeRoom.conversation.paginatedMessages = [
        ...getUniqueListByKey(_messages, "id"),
        ...state.activeRoom.conversation.paginatedMessages,
      ];
      state.activeRoom.conversation.limit = limit;
      state.activeRoom.conversation.page = page;
      state.activeRoom.conversation.totalPages = totalPages;
      state.activeRoom.conversation.totalResults = totalResults;
    },
    appendMessage: (state, action) => {
      if (
        state.activeRoom.conversation.messages.find(
          (m) => m.id === action.payload.message.id
        ) !== undefined
      )
        return;
      const incomingMessage = {
        ...action.payload.message,
        reply: action.payload.message.receiverId === action.payload.userId,
      };
      state.activeRoom.conversation.messages.push(incomingMessage);
    },
  },
});

export const {
  resetState,
  setRooms,
  setActiveRoom,
  setMessages,
  prependMessages,
  appendMessage,
} = chatSlice.actions;

export default chatSlice.reducer;
