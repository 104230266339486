import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { memo, useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function CategoryDescriptionEditor({ formik }) {
  const editorRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === " " && event.target.innerText.trim() === "") {
      event.preventDefault();
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
        ],
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    []
  );

  return (
    <Box sx={quillStyles}>
      <ReactQuill
        ref={editorRef}
        value={formik.values?.description}
        onChange={(value) => {
          if (value === "<p><br></p>") {
            return formik.setFieldValue("description", "");
          }
          formik.setFieldValue("description", value.trimStart());
        }}
        onKeyDown={handleKeyDown}
        theme="snow"
        placeholder="Write description here..."
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
        ]}
        modules={modules}
      />
    </Box>
  );
}

CategoryDescriptionEditor.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(CategoryDescriptionEditor);

const quillStyles = {
  ".quill .ql-toolbar.ql-snow": {
    backgroundColor: "#f6f6f6",
  },
  ".ql-container.ql-snow": {
    height: { xs: 120, md: 200 },
    overflow: "hidden",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
};
