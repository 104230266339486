import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { uploadDescriptionImage } from "services/Product";
import { getErrorMessage } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";

function DescriptionEditor({ formik }) {
  const editorRef = useRef(null);

  useEffect(() => {
    editorRef.current.getEditor().getLength() > 1 &&
      editorRef.current.getEditor().getLength < 81 &&
      formik.setErrors({
        description: "Description must be at least 80 characters long",
      });
  }, [formik]);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      const formData = new FormData();

      const quillObj = editorRef?.current?.getEditor();
      const range = quillObj?.getSelection();

      if (file) {
        formData.append("productId", formik.values.id);
        formData.append("descriptionImage", file);
        try {
          const response = await uploadDescriptionImage(formData);
          quillObj.editor.insertEmbed(
            range.index,
            "image",
            response?.data?.link
          );
        } catch (error) {
          const message = getErrorMessage(error);
          toast.error(message);
        }
      }
    };
  }, [formik.values.id]);

  const modules = useMemo(
    () => ({
      toolbar: {
        handlers: {
          image: imageHandler,
        },
        container: [
          [{ header: "1" }, { header: "2" }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
        ],
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    [imageHandler]
  );

  return (
    <ReactQuill
      ref={editorRef}
      theme="snow"
      value={formik.values.description}
      onChange={(value) => {
        formik.setFieldValue("description", value.trimStart());
      }}
      placeholder="Write description here..."
      formats={[
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
      ]}
      modules={modules}
    />
  );
}

DescriptionEditor.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(DescriptionEditor, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.values.description ===
      nextProps.formik.values.description &&
    prevProps.formik.errors.description ===
      nextProps.formik.errors.description &&
    prevProps.formik.touched.description ===
      nextProps.formik.touched.description &&
    prevProps.formik.values.id === nextProps.formik.values.id
  );
}
