import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
function CardTitle({ title }) {
  return (
    <Typography fontWeight="bold" fontSize="1.25rem" color="#3f3f3f">
      {title}
    </Typography>
  );
}

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CardTitle;
