import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const getAnalytics = createAsyncThunk(
  "dashboard/getAnalytics",
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(`/analytics`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getMonthlyAnalytics = createAsyncThunk(
  "dashboard/getMonthlyAnalytics",
  async (date, { rejectWithValue }) => {
    try {
      const response = await http.get(`/analytics/monthly`, {
        params: {
          date: date ?? new Date().toDateString(),
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getOrdersChart = createAsyncThunk(
  "dashboard/getOrdersChart",
  async (data, { rejectWithValue }) => {
    const currentDate = new Date(); // current date
    const daysBefore = 30;
    const dateBefore = new Date(); // create a new Date object
    dateBefore.setDate(currentDate.getDate() - daysBefore); // subtract daysBefore days
    const dateBeforeString = dateBefore.toDateString();

    const params = {};
    if (data?.range) {
      params.startDate = data?.range.startDate.toDateString();
      params.endDate = data?.range.endDate.toDateString();
      params.format = data?.format ?? "day";
    } else {
      params.startDate = dateBeforeString;
      params.endDate = new Date().toDateString();
      params.format = data?.format ?? "day";
    }

    if (data?.format && data?.dateRange?.startDate) {
      params.startDate = data?.dateRange.startDate.toDateString();
      params.endDate = data?.dateRange.endDate.toDateString();
      params.format = data?.format ?? "day";
    }

    try {
      const response = await http.get(`/analytics/ordersChart`, { params });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    results: [],
    monthlyAnalytics: [],
    ordersChart: [],
    error: null,
    loading: true,
  },
  reducers: {
    // Actions
    resetDashboardState: (state) => {
      state.results = [];
      state.monthlyAnalytics = [];
      state.ordersChart = [];
      state.loading = true;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload;
      })
      .addCase(getAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getMonthlyAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMonthlyAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.monthlyAnalytics = action.payload;
      })
      .addCase(getMonthlyAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getOrdersChart.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrdersChart.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.ordersChart = action.payload;
      })
      .addCase(getOrdersChart.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetDashboardState } = dashboardSlice.actions;

// Exporting default reducer
export default dashboardSlice.reducer;
