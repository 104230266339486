import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { PRIMARY } from "styles/colors";

import { memo, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";

function NotificationMediaSection({ formik }) {
  const dispatch = useDispatch();
  const mainImageInputRef = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  const [mainImage, setMainImage] = useState(formik.values.mainImage ?? []);

  const mainImageHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 300, 300, false, true, 1 / 1, "1 / 1")
          .then((img) => {
            mainImageInputRef.current.value = "";
            formik.setFieldValue("mainImage", img);
            setMainImage(URL.createObjectURL(img));
          })
          .catch((error) => {
            mainImageInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error?.message ?? ""));
          });
      }
    },
    [dispatch, formik]
  );

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload images and videos relevant to the notification"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Notification Main Image */}
          <Stack direction="column" gap={2} p={{ xs: 1, md: 1.5 }}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Main Image
            </Typography>
            {formik.values.mainImage ? (
              <img
                src={formik.values.mainImage ? mainImage : ""}
                alt="Store Main Media"
                height="auto"
                width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                style={{ cursor: "pointer" }}
                onClick={() => mainImageInputRef.current.click()}
              />
            ) : (
              <Box
                width="200px"
                style={{
                  cursor: "pointer",
                  border: " 1px #068ECF dashed",
                }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="200px"
                alt="main"
                onClick={() => mainImageInputRef.current.click()}
              >
                <AddIcon sx={{ color: PRIMARY }} />
              </Box>
            )}
            <input
              type="file"
              ref={mainImageInputRef}
              style={{ display: "none" }}
              id="update-notification-image"
              hidden
              onChange={mainImageHandler}
              accept="image/x-png,image/png,image/jpeg,image/jpg"
            />
            {Boolean(formik.errors.mainImage) && (
              <Typography color="#ef5350">
                {formik.errors.mainImage}*
              </Typography>
            )}
          </Stack>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

NotificationMediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(NotificationMediaSection);
