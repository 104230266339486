import { Card, CardHeader } from "@mui/material";
import PropTypes from "prop-types";
import { CARD_HEADER_BACKGROUND } from "styles/colors";

const StyledMuiCard = ({ title, subTitle, children }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 1.5,
        border: "1px solid #BEBEBE",
      }}
    >
      <CardHeader
        title={title}
        subheader={subTitle}
        sx={{
          px: { xs: 3, md: 3.5 },
          bgcolor: CARD_HEADER_BACKGROUND,
          "& .MuiCardHeader-title": {
            fontWeight: 500,
          },
          "& .MuiCardHeader-subheader": {
            fontSize: "0.9rem",
          },
          ".MuiCardHeader-action": {
            margin: "auto",
          },
          borderBottom: "1px solid #BEBEBE",
        }}
      />
      {children}
    </Card>
  );
};

StyledMuiCard.propTypes = {
  children: PropTypes.any,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default StyledMuiCard;
