import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetUserState, updateUserPassword } from "store/slices/usersSlice";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";

function UpdatePasswordForm(id) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.users);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = useMemo(
    () => ({
      password: "",
      confirmPassword: "",
    }),
    []
  );

  const handleSubmit = useCallback(
    (values) => {
      const data = {
        newPassword: values.password,
        userId: id.userId,
      };
      dispatch(updateUserPassword(data))
        .unwrap()
        .then(() => {
          navigate("/users");
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    },
    [dispatch, id.userId, navigate]
  );

  useEffect(() => {
    return () => dispatch(resetUserState());
  }, [dispatch]);

  return (
    <Box mt={3}>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <StyledMuiCard
              title="Update Password"
              subTitle="Please enter a strong password."
            >
              <CardContent>
                <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Password
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        {...formik.getFieldProps("password")}
                        error={
                          !!formik.touched.password && !!formik.errors.password
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlinedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Confirm Password
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type={showConfirmPassword ? "text" : "password"}
                        {...formik.getFieldProps("confirmPassword")}
                        error={
                          !!formik.touched.confirmPassword &&
                          !!formik.errors.confirmPassword
                        }
                        helperText={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlinedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={formik.handleSubmit}
                  sx={{
                    fontWeight: "bold",
                    width: 100,
                    height: 40,
                  }}
                  disabled={loading}
                >
                  Update
                </Button>
              </CardContent>
            </StyledMuiCard>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(UpdatePasswordForm);

UpdatePasswordForm.propTypes = {
  id: PropTypes.string,
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
      "Password must contain at least one uppercase and one lowercase letter and one number and a special character"
    )
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters"),
  confirmPassword: Yup.string()
    .required("Required*")
    .trim()
    .oneOf([Yup.ref("password"), null], "Passwords do not match"),
});
