import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo } from "react";

function PageHeader({ title }) {
  return (
    <Typography
      variant="h1"
      fontWeight="600"
      color="primary.main"
      fontSize={{ xs: "1.5rem", md: "1.75rem", lg: "2.25rem" }}
    >
      {title}
    </Typography>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

PageHeader.defaultProps = {
  title: "Page Header",
};

export default memo(PageHeader);
