import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";

import http from "utils/httpRequest/http";

export const getVouchers = createAsyncThunk(
  "getVouchers",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().vouchers;
    try {
      const response = await http.get(`/voucher`, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      const results = responseData?.results || responseData?.result;
      if (results) {
        results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getVoucher = createAsyncThunk(
  "voucher/getVoucher",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { results } = getState().vouchers;
      let voucher = results.find((voucher) => voucher.id === id);
      if (voucher) {
        const userPromise = new Promise((resolve) => {
          setTimeout(() => {
            resolve(voucher);
          }, 100);
        });
        return userPromise.then((voucher) => {
          return voucher;
        });
      }
      const response = await http.get(`/voucher/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const addVoucher = createAsyncThunk(
  "voucher/addVoucher",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/voucher`, data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const updateVoucher = createAsyncThunk(
  "voucher/updateVoucher",
  async (values, { rejectWithValue }) => {
    const { id, ...data } = values;
    try {
      const response = await http.patch(`/voucher/${id}`, data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteVoucher = createAsyncThunk(
  "deleteVoucher",
  async (voucherId, { getState, rejectWithValue }) => {
    const { page, limit, results } = getState().vouchers;
    try {
      const response = await http.delete(`voucher/${voucherId}`);
      if (response?.data?.isSuccess === true) {
        toast.success("Voucher deleted successfully");
        let returnedData = results.filter((data) => data.id !== voucherId);
        if (returnedData) {
          returnedData = returnedData.map((item, index) => {
            const pageStart = (page - 1) * limit;
            return {
              ...item,
              serialNumber: pageStart + index + 1,
            };
          });
        }
        return returnedData;
      }
      if (response?.data?.isSuccess === false) {
        toast.error(response?.data?.message);
        return results;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const vouchersSlice = createSlice({
  name: "vouchers",
  initialState: {
    loading: true,
    results: [],
    voucher: {},
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: null,
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.results = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = null;
    },
    resetVoucherState: (state) => {
      state.loading = false;
      state.voucher = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVouchers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVouchers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload.results || action.payload.result;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getVouchers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addVoucher.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addVoucher.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addVoucher.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteVoucher.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteVoucher.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload;
      })
      .addCase(deleteVoucher.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getVoucher.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVoucher.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.voucher = action.payload;
      })
      .addCase(getVoucher.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateVoucher.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateVoucher.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateVoucher.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetVoucherState } = vouchersSlice.actions;

// Exporting default reducer
export default vouchersSlice.reducer;
