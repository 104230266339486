import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import StyledDataGrid from "components/StyledGrid";
import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function SellerStoreDataGrid() {
  const navigate = useNavigate();
  const loading = useSelector((state) => state.stores?.loading ?? false);
  const store = useSelector((state) => state.stores?.store ?? null);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 40,
        flex: 0.2,
        filterable: false,
      },
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 0.9,
        renderCell: (params) => (
          <Typography>{params?.row?.brandName}</Typography>
        ),
      },
      {
        field: "city",
        headerName: "City",
        minWidth: 100,
        flex: 0.5,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.city}
          </Typography>
        ),
      },
      {
        field: "address",
        headerName: "Address",
        minWidth: 150,
        flex: 1.5,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.address}
          </Typography>
        ),
      },
      {
        field: "date",
        headerName: "Date",
        minWidth: 30,
        flex: 0.5,
        renderCell: (params) => (
          <Typography>{params?.row?.createdAt.substring(0, 10)}</Typography>
        ),
      },

      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 30,
        flex: 0.5,
        renderCell: () => (
          <Box display="flex" sx={actionStyles}>
            <IconButton
              variant="text"
              className="button-styles"
              onClick={() => navigate(`update`)}
            >
              <img src={WritingIcon} alt="edit" className="icon-size" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [navigate]
  );

  return (
    <>
      <StyledDataGrid
        rows={[store]}
        columns={columns}
        loading={loading}
        totalPages={1}
      />
    </>
  );
}

export default memo(SellerStoreDataGrid);

const actionStyles = {
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
