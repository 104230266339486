import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { PRIMARY } from "styles/colors";

import { memo, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";

function CategoryMediaSection({ formik }) {
  const dispatch = useDispatch();
  const mainImageInputRef = useRef(null);
  const bannerImageRef = useRef(null);
  const bannerImageArRef = useRef(null);
  const wideBannerImageRef = useRef(null);
  const wideBannerImageArRef = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const [bannerImage, setBannerImage] = useState(
    formik.values?.bannerImage ?? []
  );
  const [bannerImageAr, setBannerImageAr] = useState(
    formik.values?.bannerImageAr ?? []
  );
  const [wideBannerImage, setWideBannerImage] = useState(
    formik.values?.wideBannerImage ?? []
  );
  const [wideBannerImageAr, setWideBannerImageAr] = useState(
    formik.values?.wideBannerImageAr ?? []
  );
  const selectedLanguage = useSelector((state) => state.UI?.language ?? "En");
  const [mainImage, setMainImage] = useState(formik.values?.mainImage ?? []);

  const mainImageHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 300, 300, false, true, 1 / 1, "1 / 1")
          .then((img) => {
            mainImageInputRef.current.value = "";
            formik.setFieldValue("mainImage", img);
            setMainImage(URL.createObjectURL(img));
          })
          .catch((error) => {
            mainImageInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error?.message ?? ""));
          });
      }
    },
    [dispatch, formik]
  );

  const bannerImageHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        validateImageAspectRatio(
          image,
          385,
          561,
          true,
          true,
          35 / 51,
          "35 / 51"
        )
          .then((img) => {
            bannerImageRef.current.value = "";
            formik.setFieldValue("bannerImage", img);
            setBannerImage(URL.createObjectURL(img));
          })
          .catch((error) => {
            bannerImageRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error?.message ?? ""));
          });
      }
    },
    [dispatch, formik]
  );

  const bannerImageArHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        validateImageAspectRatio(
          image,
          385,
          561,
          true,
          true,
          35 / 51,
          "35 / 51"
        )
          .then((img) => {
            bannerImageArRef.current.value = "";
            formik.setFieldValue("bannerImageAr", img);
            setBannerImageAr(URL.createObjectURL(img));
          })
          .catch((error) => {
            bannerImageArRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error?.message ?? ""));
          });
      }
    },
    [dispatch, formik]
  );
  const wideBannerHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        validateImageAspectRatio(image, 1200, 400, true, true, 3 / 1, "3 / 1")
          .then((img) => {
            wideBannerImageRef.current.value = "";
            formik.setFieldValue("wideBannerImage", img);
            setWideBannerImage(URL.createObjectURL(img));
          })
          .catch((error) => {
            wideBannerImageRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error?.message ?? ""));
          });
      }
    },
    [dispatch, formik]
  );

  const wideBannerHandlerAr = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        validateImageAspectRatio(image, 1200, 400, true, true, 3 / 1, "3 / 1")
          .then((img) => {
            wideBannerImageArRef.current.value = "";
            formik.setFieldValue("wideBannerImageAr", img);
            setWideBannerImageAr(URL.createObjectURL(img));
          })
          .catch((error) => {
            wideBannerImageArRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error?.message ?? ""));
          });
      }
    },
    [dispatch, formik]
  );

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload images relevant to the category"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Category Main Image */}
          <Stack direction="column" gap={2} p={{ xs: 1, md: 1.5 }}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Main Image
            </Typography>
            {formik.values.mainImage ? (
              <img
                src={formik.values.mainImage ? mainImage : ""}
                alt="Store Main Media"
                height="auto"
                width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                style={{ cursor: "pointer" }}
                onClick={() => mainImageInputRef.current.click()}
              />
            ) : (
              <Box
                width="200px"
                style={{
                  cursor: "pointer",
                  border: " 1px #068ECF dashed",
                }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="200px"
                alt="main"
                onClick={() => mainImageInputRef.current.click()}
              >
                <AddIcon sx={{ color: PRIMARY }} />
              </Box>
            )}
            <input
              type="file"
              ref={mainImageInputRef}
              style={{ display: "none" }}
              id="update-category-image"
              hidden
              onChange={mainImageHandler}
              accept="image/x-png,image/png,image/jpeg,image/jpg"
            />
            {Boolean(formik.errors.mainImage) && (
              <Typography color="#ef5350">
                {formik.errors.mainImage}*
              </Typography>
            )}
          </Stack>

          {/* Category Banner Image */}
          {selectedLanguage === "En" && (
            <Stack
              direction="column"
              gap={2}
              height="100%"
              justifyContent="space-between"
            >
              <Stack direction="column" gap={2} p={{ xs: 1, md: 1.5 }}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Banner
                </Typography>
                <Box display="flex" flexDirection="row" gap={2}>
                  {formik.values.bannerImage ? (
                    <img
                      src={formik.values.bannerImage ? bannerImage : ""}
                      alt="Store Main Media"
                      height="550px"
                      width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                      style={{ cursor: "pointer" }}
                      onClick={() => bannerImageRef.current.click()}
                    />
                  ) : (
                    <Box
                      width="350px"
                      style={{
                        cursor: "pointer",
                        border: " 1px #068ECF dashed",
                      }}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      height="550px"
                      alt="banner"
                      onClick={() => bannerImageRef.current.click()}
                    >
                      <AddIcon sx={{ color: PRIMARY }} />
                    </Box>
                  )}
                  <input
                    type="file"
                    ref={bannerImageRef}
                    style={{ display: "none" }}
                    id="update-store-image"
                    hidden
                    onChange={bannerImageHandler}
                    accept="image/x-png,image/png,image/jpeg,image/jpg"
                  />
                </Box>
                {Boolean(formik.errors.bannerImage) && (
                  <Typography color="#ef5350">
                    {formik.errors.bannerImage}*
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}
          {selectedLanguage === "Ar" && (
            <Stack
              direction="column"
              gap={2}
              height="100%"
              justifyContent="space-between"
            >
              <Stack direction="column" gap={2} p={{ xs: 1, md: 1.5 }}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Arabic Banner
                </Typography>
                <Box display="flex" flexDirection="row" gap={2}>
                  {formik.values.bannerImageAr ? (
                    <img
                      src={formik.values.bannerImageAr ? bannerImageAr : ""}
                      alt="Store Main Media"
                      height="550px"
                      width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                      style={{ cursor: "pointer" }}
                      onClick={() => bannerImageArRef.current.click()}
                    />
                  ) : (
                    <Box
                      width="350px"
                      style={{
                        cursor: "pointer",
                        border: " 1px #068ECF dashed",
                      }}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      height="550px"
                      alt="banner"
                      onClick={() => bannerImageArRef.current.click()}
                    >
                      <AddIcon sx={{ color: PRIMARY }} />
                    </Box>
                  )}
                  <input
                    type="file"
                    ref={bannerImageArRef}
                    style={{ display: "none" }}
                    id="update-store-image"
                    hidden
                    onChange={bannerImageArHandler}
                    accept="image/x-png,image/png,image/jpeg,image/jpg"
                  />
                </Box>
                {Boolean(formik.errors.bannerImageAr) && (
                  <Typography color="#ef5350">
                    {formik.errors.bannerImageAr}*
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}
          {selectedLanguage === "En" && (
            <Stack
              direction="column"
              gap={2}
              height="100%"
              justifyContent="space-between"
            >
              <Stack direction="column" gap={2} p={{ xs: 1, md: 1.5 }}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Phone Banner
                </Typography>
                <Box display="flex" flexDirection="row" gap={2}>
                  {formik.values?.wideBannerImage ? (
                    <img
                      src={
                        formik.values?.wideBannerImage ? wideBannerImage : ""
                      }
                      alt="Store Main Media"
                      height="auto"
                      width={isSmall ? "300px" : isLarge ? "600px" : "700px"}
                      style={{ cursor: "pointer" }}
                      onClick={() => wideBannerImageRef.current.click()}
                    />
                  ) : (
                    <Box
                      width="600px"
                      style={{
                        cursor: "pointer",
                        border: " 1px #068ECF dashed",
                      }}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      height="200px"
                      alt="banner"
                      onClick={() => wideBannerImageRef.current.click()}
                    >
                      <AddIcon sx={{ color: PRIMARY }} />
                    </Box>
                  )}
                  <input
                    type="file"
                    ref={wideBannerImageRef}
                    style={{ display: "none" }}
                    id="update-store-image"
                    hidden
                    onChange={wideBannerHandler}
                    accept="image/x-png,image/png,image/jpeg,image/jpg"
                  />
                </Box>
                {Boolean(formik.errors?.wideBannerImage) && (
                  <Typography color="#ef5350">
                    {formik.errors?.wideBannerImage}*
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}

          {selectedLanguage === "Ar" && (
            <Stack
              direction="column"
              gap={2}
              height="100%"
              justifyContent="space-between"
            >
              <Stack direction="column" gap={2} p={{ xs: 1, md: 1.5 }}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Arabic Phone Banner
                </Typography>
                <Box display="flex" flexDirection="row" gap={2}>
                  {formik.values?.wideBannerImageAr ? (
                    <img
                      src={
                        formik.values?.wideBannerImageAr
                          ? wideBannerImageAr
                          : ""
                      }
                      alt="Store Main Media"
                      height="auto"
                      width={isSmall ? "300px" : isLarge ? "600px" : "700px"}
                      style={{ cursor: "pointer" }}
                      onClick={() => wideBannerImageArRef.current.click()}
                    />
                  ) : (
                    <Box
                      width="600px"
                      style={{
                        cursor: "pointer",
                        border: " 1px #068ECF dashed",
                      }}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      height="200px"
                      alt="banner"
                      onClick={() => wideBannerImageArRef.current.click()}
                    >
                      <AddIcon sx={{ color: PRIMARY }} />
                    </Box>
                  )}
                  <input
                    type="file"
                    ref={wideBannerImageArRef}
                    style={{ display: "none" }}
                    id="update-store-image"
                    hidden
                    onChange={wideBannerHandlerAr}
                    accept="image/x-png,image/png,image/jpeg,image/jpg"
                  />
                </Box>
                {Boolean(formik.errors?.wideBannerImageAr) && (
                  <Typography color="#ef5350">
                    {formik.errors?.wideBannerImageAr}*
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

CategoryMediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(CategoryMediaSection);
