import axios from "axios";
import interceptorConfiguration from "./interceptorConfiguration";

const httpChat = axios.create({
  baseURL: process.env.REACT_APP_SOCKET_SERVER_URL,
});

interceptorConfiguration(httpChat);

export default httpChat;
