import { IconButton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import deleteIcon from "assets/icons/deleteIcon.svg";
import CurrencyFormatter from "components/CurrencyFormatter";
import StyledGrid from "components/StyledGrid";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteCollectionProducts } from "store/slices/collectionSlice";

const StyledDel = styled("del")(() => ({}));

function CollectionDataGrid({ collectionProducts }) {
  const params = useParams();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const loading = useSelector((state) => state.collection.collectionLoading);

  const handleCollectionProductDelete = useCallback(
    (_id) => {
      if (params?.id) {
        let deleteProducts = [_id];
        dispatch(deleteCollectionProducts({ deleteProducts, id: params?.id }));
      }
    },
    [dispatch, params?.id]
  );

  const columns = useMemo(
    () => [
      {
        field: "image",
        headerName: "Image",
        type: "actions",
        minWidth: 100,
        flex: 0.8,
        renderCell: (params) => (
          <Box>
            <img
              src={params?.row?.mainImage}
              height={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              width={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 4,
                objectFit: "contain",
              }}
              alt={params?.row?.productName}
            />
          </Box>
        ),
      },
      {
        field: "Name/Details",
        headerName: "Name/Details",
        minWidth: 200,
        flex: 1.5,
        renderCell: (params) => (
          <Box>
            <Typography
              sx={{
                height: {
                  xs: 40,
                  md: 45,
                },
                textTransform: "capitalize",
                color: "#000000",
                overflow: "hidden",
                textOverflow: " ellipsis",
                lineClamp: "2",
                WebkitLineClamp: "2",
              }}
            >
              {params?.row?.productName}
            </Typography>
          </Box>
        ),
      },
      {
        field: "price",
        headerName: "Price",
        minWidth: 90,
        flex: 1,
        renderCell: (params) =>
          params?.row?.onSale ? (
            <Box>
              <CurrencyFormatter number={params?.row?.salePrice} />
              <StyledDel
                data-test-id="regularPrice"
                className="product-price"
                sx={{ color: "error.dark" }}
              >
                <CurrencyFormatter number={params?.row?.regularPrice} />
              </StyledDel>
            </Box>
          ) : (
            <CurrencyFormatter number={params?.row?.regularPrice} />
          ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 180,
        flex: 0.8,
        renderCell: (params) => {
          return (
            <Box display="flex">
              <Tooltip title="Delete">
                <IconButton
                  size="small"
                  onClick={() =>
                    handleCollectionProductDelete(params?.row?._id)
                  }
                >
                  <img src={deleteIcon} alt="delete" />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    [isTablet, isLaptop, isLed, handleCollectionProductDelete]
  );
  return (
    <StyledGrid
      rows={collectionProducts ?? collectionProducts}
      columns={columns}
      loading={loading}
      customRowId={"_id"}
    />
  );
}

CollectionDataGrid.propTypes = {
  formik: PropTypes.object.isRequired,
  collectionProducts: PropTypes.array.isRequired,
};

export default memo(CollectionDataGrid);
