import Box from "@mui/material/Box";
import DataGridSearch from "components/DataGridSearch/OrdersSearch";
import OrderFilters from "components/FullOrders/OrderFilters";
import { memo } from "react";

function OrderHeaderComponents() {
  return (
    <Box mb={2} mt={1} sx={containerStyles}>
      <OrderFilters />
      <DataGridSearch searchQueryParam="OrderDetailId" />
    </Box>
  );
}

export default memo(OrderHeaderComponents);

const containerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  flexDirection: "column",
  "& .styled-button": {
    borderRadius: "1px",
    padding: "10px",
    fontSize: {
      md: "1rem",
      xs: "0.7rem",
    },
  },
};
