import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import StyledSwitch from "components/ToggleButton";
import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductAfterVariantUpdate,
  updateProduct,
} from "store/slices/productsSlice";
import {
  addProductVariant,
  setOpenVariantForm,
  setVariantId,
} from "store/slices/variantsSlice";
import isValidNumber from "utils/helpers/isValidNumber";
import { toast } from "utils/hooks/useToast";
import VariantMedia from "./VariantMedia";

function VariantForm({ selectedAttributes, formik, variant }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.products.product);
  const loading = useSelector((state) => state.products.productLoading);
  const variantId = useSelector((state) => state.variants.variantId);
  const [variantQuantity, setVariantQuantity] = useState(
    variant?.quantity ?? ""
  );
  const [variantSku, setVariantSku] = useState(variant?.sku ?? "");
  const [variantPrice, setVariantPrice] = useState(variant?.regularPrice ?? "");
  const [variantOnSale, setVariantOnSale] = useState(variant?.onSale ?? false);
  const [variantSalePrice, setVariantSalePrice] = useState(
    variant?.salePrice ?? ""
  );
  const [errors, setErrors] = useState({});
  const variantTrue = Object.keys(variant).length !== 0;

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (value.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "Required*" }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }

    if (type === "checkbox") {
      if (name === "variantOnSale") {
        setVariantOnSale(checked);
      }
    } else {
      if (name === "variantQuantity") {
        setVariantQuantity(value);
      } else if (name === "variantSku") {
        setVariantSku(value);
      } else if (name === "variantPrice") {
        if (isValidNumber(value)) {
          setVariantPrice(value);
        }
      } else if (name === "variantSalePrice") {
        if (isValidNumber(value)) {
          setVariantSalePrice(value);
        }
      }
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "Required*" }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleClick = useCallback(() => {
    const data = {
      productName: formik.values.name,
      sku: variantSku,
      regularPrice: variantPrice,
      onSale: variantOnSale,
      quantity: variantQuantity,
      weight: formik.values.packageWeight,
      selectedAttributes: selectedAttributes,
    };
    if (variantSalePrice !== "") {
      data.salePrice = variantSalePrice;
    }
    const newErrors = {};
    if (!data.quantity || data.quantity <= 0) {
      newErrors.variantQuantity = "Required*";
    }
    if (!data.sku) {
      newErrors.variantSku = "Please enter SKU";
    }
    if (!data.regularPrice || data.regularPrice <= 0) {
      newErrors.variantPrice = "Please enter a valid price";
    }
    if (data.onSale && (!variantSalePrice || variantSalePrice <= 0)) {
      newErrors.variantSalePrice = "Please enter a valid sale price";
    }
    if (variantSalePrice >= variantPrice) {
      newErrors.variantSalePrice =
        "Sale price must be lower than the regular price";
    }

    if (Object.keys(newErrors).length === 0 && !variantTrue) {
      const variants = [data];
      const variantData = {
        sellerId: product?.user?.id,
        variants: variants,
      };
      dispatch(addProductVariant({ data: variantData, id: product?.id }))
        .unwrap()
        .then((response) => {
          dispatch(setVariantId(response[0]?.id));
        });
    } else {
      setErrors(newErrors);
    }

    const updatedData = {
      productName: formik.values.name,
      sku: variantSku,
      regularPrice: variantPrice,
      onSale: variantOnSale,
      quantity: variantQuantity,
    };

    if (variantSalePrice !== "") {
      updatedData.salePrice = variantSalePrice;
    }

    if (Object.keys(newErrors).length === 0 && variantTrue) {
      dispatch(updateProduct({ productId: variant?.id, body: updatedData }))
        .unwrap()
        .then(() => {
          dispatch(getProductAfterVariantUpdate(product?.id));
          dispatch(setOpenVariantForm(null));
          toast.success("Variant updated successfully");
        });
    } else {
      setErrors(newErrors);
    }
  }, [
    dispatch,
    formik.values.name,
    formik.values.packageWeight,
    product?.id,
    product?.user?.id,
    selectedAttributes,
    variant?.id,
    variantOnSale,
    variantPrice,
    variantQuantity,
    variantSalePrice,
    variantSku,
    variantTrue,
  ]);

  return (
    <Box py={2} px={1}>
      <Grid container spacing={2} sx={{ "& .MuiGrid-root": { p: 0 } }}>
        <Grid item xs={6} lg={6}>
          <Stack
            direction="column"
            justifyContent="center"
            gap={3}
            p={{ xs: 1, md: 1.5 }}
          >
            <Stack direction="column" gap={1}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Quantity
              </Typography>
              <StyledTextField
                fullWidth
                type="text"
                name="variantQuantity"
                value={variantQuantity}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.variantQuantity)}
                helperText={errors.variantQuantity || "Please enter quantity"}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Stack
            direction="column"
            justifyContent="center"
            gap={3}
            p={{ xs: 1, md: 1.5 }}
          >
            <Stack direction="column" gap={1}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Product SKU
              </Typography>
              <StyledTextField
                fullWidth
                type="text"
                name="variantSku"
                value={variantSku}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.variantSku)}
                helperText={errors.variantSku || "Please enter SKU"}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Stack
            direction="column"
            justifyContent="center"
            gap={3}
            p={{ xs: 1, md: 1.5 }}
          >
            <Stack direction="column" gap={1}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Price
              </Typography>
              <StyledTextField
                fullWidth
                name="variantPrice"
                type="text"
                value={variantPrice}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.variantPrice)}
                helperText={errors.variantPrice || "Please enter price"}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Stack
            direction="column"
            justifyContent="center"
            gap={3}
            p={{ xs: 1, md: 1.5 }}
          >
            <Stack direction="column" gap={1}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Sale Price
                </Typography>
                <StyledSwitch
                  checked={variantOnSale}
                  name="variantOnSale"
                  onChange={handleChange}
                />
              </Box>
              <StyledTextField
                fullWidth
                name="variantSalePrice"
                type="text"
                value={variantSalePrice}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.variantSalePrice)}
                disabled={!variantOnSale}
                helperText={
                  variantOnSale
                    ? errors.variantSalePrice || "Please enter sale price"
                    : ""
                }
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        disabled={loading || !!variantId}
        onClick={handleClick}
      >
        {variantTrue ? "Update Variant" : "Add Variant"}
      </Button>
      <VariantMedia variant={variant} />
    </Box>
  );
}

VariantForm.propTypes = {
  selectedAttributes: PropTypes.array.isRequired,
  formik: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
};
VariantForm.defaultProps = {
  selectedAttributes: [],
  formik: {},
  variant: {},
};

export default memo(VariantForm);
