import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const getGroupBuy = createAsyncThunk(
  "groupBuy/getGroupBuy",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().groupBuy;
    try {
      const response = await http.get(`/group-buy`, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      const results = responseData?.results || responseData?.result;
      if (results) {
        results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getGroupBuyProduct = createAsyncThunk(
  "groupBuy/getGroupBuyProduct",
  async (id, { rejectWithValue }) => {
    try {
      const response = await http.get(`/group-buy/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const addGroupBuyProduct = createAsyncThunk(
  "groupBuy/addGroupBuyProduct",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.post(`/group-buy`, payload._data);
      const responseData = getResponseData(response);
      if (responseData?.isSuccess === true) {
        toast.success(responseData?.message);
        payload.navigate("/products/group-buy");
        dispatch(resetGroupBuyState());
      } else {
        return rejectWithValue(responseData?.message);
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const updateGroupBuyProduct = createAsyncThunk(
  "groupBuy/updateGroupBuyProduct",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.put(
        `/group-buy/${payload.id}`,
        payload._data
      );
      const responseData = getResponseData(response);
      if (responseData?.isSuccess === true) {
        toast.success(responseData?.message);
        payload.navigate("/products/group-buy");
        dispatch(resetGroupBuyState());
      } else {
        return rejectWithValue(responseData?.message);
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const deleteGroupBuyProduct = createAsyncThunk(
  "deleteGroupBuyProduct",
  async (groupBuyId, { getState, rejectWithValue }) => {
    const { results } = getState().groupBuy;
    try {
      const response = await http.delete(`group-buy/${groupBuyId}`);
      if (response?.data?.status === 200) {
        toast.success("Deleted successfully");
        const returnedData = results.filter((data) => data.id !== groupBuyId);
        return returnedData;
      }
      if (response?.data?.status !== 200) {
        toast.error(response?.data?.message);
        return results;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const groupBuySlice = createSlice({
  name: "groupBuy",
  initialState: {
    loading: true,
    groupBuyLoading: false,
    groupBuyProduct: null,
    groupBuyError: "",
    results: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: "",
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.results = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = "";
    },
    resetGroupBuyState: (state) => {
      state.groupBuyLoading = false;
      state.groupBuyProduct = null;
      state.groupBuyError = null;
    },
    resetErrorState: (state) => {
      state.groupBuyError = null;
    },
    setGroupBuy: (state, action) => {
      state.groupBuyProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroupBuy.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getGroupBuy.fulfilled, (state, action) => {
        state.results = action.payload.results || action.payload.result;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getGroupBuy.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteGroupBuyProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteGroupBuyProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(deleteGroupBuyProduct.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addGroupBuyProduct.pending, (state) => {
        state.groupBuyLoading = true;
      })
      .addCase(addGroupBuyProduct.fulfilled, (state, action) => {
        state.groupBuyLoading = false;
        state.groupBuyProduct = action.payload;
      })
      .addCase(addGroupBuyProduct.rejected, (state, action) => {
        state.groupBuyLoading = false;
        state.groupBuyError = action.payload;
      })
      .addCase(getGroupBuyProduct.pending, (state) => {
        state.loading = true;
        state.groupBuyError = false;
      })
      .addCase(getGroupBuyProduct.fulfilled, (state, action) => {
        state.groupBuyProduct = action.payload;
        state.loading = false;
      })
      .addCase(getGroupBuyProduct.rejected, (state) => {
        state.loading = false;
        state.groupBuyError = true;
      })
      .addCase(updateGroupBuyProduct.pending, (state) => {
        state.groupBuyLoading = true;
        state.groupBuyError = false;
      })
      .addCase(updateGroupBuyProduct.fulfilled, (state, action) => {
        state.groupBuyProduct = action.payload;
        state.groupBuyLoading = false;
      })
      .addCase(updateGroupBuyProduct.rejected, (state, action) => {
        state.groupBuyLoading = false;
        state.groupBuyError = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetGroupBuyState, setGroupBuy, resetErrorState } =
  groupBuySlice.actions;

// Exporting default reducer
export default groupBuySlice.reducer;
