import UpdateStore from "components/Stores/UpdateStore/UpdateStore";
import { memo } from "react";
import { store } from "store/configureStore";
import { getStore } from "store/slices/storesSlice";

function UpdateStoreForm() {
  return <UpdateStore />;
}

export default memo(UpdateStoreForm);

export function loadSellerStore() {
  return store.dispatch(getStore());
}
