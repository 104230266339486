import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import CardVideoStats from "components/Dashboard/CardVideoStats";
import KeyNumbers from "components/Dashboard/KeyNumbers";
import LineChart from "components/Dashboard/LineChart";
import SessionByDevice from "components/Dashboard/SessionByDevice";
import SpeedoMeter from "components/Dashboard/SpeedoMeter";
import StatsCard from "components/Dashboard/StatsCard";
import UsersCard from "components/Dashboard/UsersCard";
import MonthPicker from "components/Pickers/MonthPicker";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalytics,
  getMonthlyAnalytics,
  getOrdersChart,
} from "store/slices/dashboardSlice";
import getTargetValue from "utils/helpers/getTargetValue";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

export default function Dashboard() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const { results, monthlyAnalytics } = useSelector((state) => state.dashboard);
  const { userRole } = useSelector((state) => state.auth);

  const analytics = useCallback(async () => {
    await Promise.all([
      dispatch(getAnalytics()),
      dispatch(getMonthlyAnalytics()),
      dispatch(getOrdersChart()),
    ]);
  }, [dispatch]);

  const handleChange = useCallback(
    (value) => {
      if (value && value?.$d < new Date()) {
        dispatch(getMonthlyAnalytics(value.$d.toDateString()));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (userRole !== "requestedSeller") {
        analytics();
      }
    }, 500);
    return () => clearTimeout(timeOut);
  }, [analytics, userRole]);

  return (
    <Box sx={{ flexGrow: 1, py: 3, px: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "1.75rem", md: "2rem" }}
          fontWeight={600}
          paddingY={1}
        >
          DASHBOARD
        </Typography>
        <MonthPicker onChange={handleChange} />
      </Box>
      <Grid container spacing={3}>
        <Grid sx={{ mt: 1 }} item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Orders"}
            currentMonth={monthlyAnalytics?.orders?.currMonthTotalOrders}
            target={getTargetValue(
              monthlyAnalytics?.orders?.currMonthTotalOrders,
              1.5
            )}
            lastMonth={monthlyAnalytics?.orders?.prevMonthTotalOrders}
          />
        </Grid>
        <Grid sx={{ mt: 1 }} item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Sales"}
            currentMonth={monthlyAnalytics?.orders?.currMonthSales}
            target={getTargetValue(
              monthlyAnalytics?.orders?.currMonthSales,
              1.5
            )}
            lastMonth={monthlyAnalytics?.orders?.prevMonthSales}
          />
        </Grid>
        <Grid sx={{ mt: 1 }} item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Products"}
            currentMonth={monthlyAnalytics?.products?.currMonthProducts}
            target={getTargetValue(
              monthlyAnalytics?.products?.currMonthProducts,
              1.5
            )}
            lastMonth={monthlyAnalytics?.products?.prevMonthProducts}
          />
        </Grid>
        <Grid sx={{ mt: 1 }} item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Videos"}
            currentMonth={monthlyAnalytics?.videos?.currMonthVideos}
            target={getTargetValue(
              monthlyAnalytics?.videos?.currMonthVideos,
              1.5
            )}
            lastMonth={monthlyAnalytics?.videos?.prevMonthVideos}
          />
        </Grid>
      </Grid>
      {userRole === "admin" && (
        <Grid sx={{ mt: 1 }} container spacing={3}>
          <Grid item xs={12}>
            <Item>
              <KeyNumbers />
            </Item>
          </Grid>
          {/* <Grid item xs={3} lg={6} sm={12} md={12} xl={6}>
            <OrdersByChannel />
          </Grid> */}
        </Grid>
      )}
      <Grid sx={{ mt: 1 }} container spacing={3}>
        <Grid item sm={12} md={12} lg={12} xl={8}>
          <Item>
            <LineChart />
          </Item>
        </Grid>
        <Grid item xs={3} sm={12} md={12} lg={12} xl={4}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={3} xl={6}>
              <CardVideoStats
                title={"Videos"}
                totalVideos={results?.videos?.totalVideos}
                currentMonth={monthlyAnalytics?.videos?.currMonthVideos}
                lastMonth={monthlyAnalytics?.videos?.prevMonthVideos}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3} xl={6}>
              <CardVideoStats
                title={"Views"}
                totalVideos={results?.videos?.totalViews}
                currentMonth={monthlyAnalytics?.videos?.currMonthViews}
                lastMonth={monthlyAnalytics?.videos?.prevMonthViews}
              />
            </Grid>
            <Grid item md={12} lg={6} xl={12}>
              <Item>
                <SpeedoMeter />
              </Item>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {userRole === "admin" && (
        <Grid sx={{ mt: 1 }} container spacing={3}>
          <Grid item xs={12} sm={12} md={12} xl={6} lg={!isOpen ? 6 : 12}>
            <Item>
              <SessionByDevice />
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={6} lg={!isOpen ? 6 : 12}>
            <Item>
              <UsersCard />
            </Item>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
