import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { Children, memo, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Message from "./Message";

function Messages({ socket }) {
  const scrollToRef = useRef();
  const activeRoom = useSelector((state) => state?.chat?.activeRoom ?? {});
  const { messages, paginatedMessages, page, totalPages } = useSelector(
    (state) => state.chat?.activeRoom?.conversation
  );

  const loadMoreMessages = useCallback(
    (page) => {
      socket.emit("messages", {
        options: { page },
        roomId: activeRoom?._id,
      });
    },
    [activeRoom?._id, socket]
  );

  useEffect(() => {
    if (scrollToRef.current) {
      const id = setTimeout(() => {
        scrollToRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 100);
      return () => clearTimeout(id);
    }
  }, [messages]);

  if (!activeRoom?._id)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography variant="body1" align="center">
          No active room!
        </Typography>
      </Box>
    );

  return (
    <Box sx={containerStyles}>
      {page < totalPages && (
        <Box textAlign="center">
          <Button onClick={() => loadMoreMessages(page + 1)}>Load more</Button>
        </Box>
      )}
      {Children.toArray(
        [...paginatedMessages, ...messages].map((item) => (
          <Message
            key={item?.id}
            content={item?.message}
            isMultimedia={item?.contentType === "multimedia"}
            multimedia={item?.multimedia}
            isReply={item?.reply}
            timestamp={item?.date}
          />
        ))
      )}
      <Box ref={scrollToRef} />
      {/* This is the element we want to scroll to */}
    </Box>
  );
}

Messages.propTypes = {
  socket: PropTypes.object,
};

export default memo(Messages);

const containerStyles = {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflowX: "hidden",
  overflowY: "auto",
  scrollBehavior: "smooth",
  "&::-webkit-scrollbar": {
    width: 5,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    minHeight: 25,
    backgroundColor: "primary.light",
    borderRadius: 1,
  },
};
