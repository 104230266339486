import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import deleteIcon from "assets/icons/deleteIcon.svg";
import WritingIcon from "assets/icons/WritingIcon.svg";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import {
  approveRequestedSeller,
  deleteRequestedSeller,
  getRequestedSellers,
} from "store/slices/usersSlice";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { requestedSellers, loading, totalPages } = useSelector(
    (state) => state.users
  );

  const fetchRequestedSellers = useCallback(() => {
    dispatch(getRequestedSellers(params));
  }, [dispatch, params]);

  const approveSeller = useCallback(
    (userId) => {
      dispatch(approveRequestedSeller(userId));
    },
    [dispatch]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchRequestedSellers();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchRequestedSellers]);

  const handleClickOpen = useCallback(
    (sellerID) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(sellerID));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 40,
        flex: 0.3,
        filterable: false,
      },
      {
        field: "userName",
        headerName: "Name/Details",
        minWidth: 150,
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <Typography
            textTransform="capitalize"
            sx={{ wordBreak: "break-all" }}
          >
            {params?.row?.fullname}
          </Typography>
        ),
      },
      {
        field: "role",
        headerName: "Role",
        minWidth: 125,
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        flex: 0.8,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.role}
          </Typography>
        ),
      },
      {
        field: "phone",
        headerName: "Phone Number",
        minWidth: 150,
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        flex: 0.8,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.phone}
          </Typography>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 220,
        flex: 1.2,
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        renderCell: (params) => (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: " ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params?.row?.email}
          </Typography>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 150,
        flex: 0.5,
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        renderCell: (params) => (
          <Box display="flex">
            <Tooltip title="Update">
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => navigate(`update/${params.id}`)}
              >
                <img src={WritingIcon} alt="edit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="delete" />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 150,
        flex: 0.5,
        sortable: false,
        renderCell: (params) => (
          <Chip
            sx={{
              backgroundColor: "#3cb2e2",
              color: "#fff",
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
            }}
            onClick={() => approveSeller(params.id)}
            variant="contained"
            label={"Approve"}
          />
        ),
      },
    ],
    [approveSeller, handleClickOpen, navigate]
  );
  return (
    <>
      <StyledDataGrid
        rows={requestedSellers}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteRequestedSeller} />
    </>
  );
}
