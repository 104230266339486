import Box from "@mui/material/Box";
import Filters from "components/RequestedSellers/Filters";
import { memo } from "react";
import { BUTTON_HOVER } from "styles/colors";

function AddActions() {
  return (
    <Box py={2} sx={containerStyles}>
      <Filters />
    </Box>
  );
}

export default memo(AddActions);

const containerStyles = {
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  "& .MuiOutlinedInput-root": {
    "&:hover": {
      backgroundColor: BUTTON_HOVER,
    },
  },
  "& .MuiSelect-select": { height: "29px !important" },
  "& .Filter-toggle-btn": {
    height: 44,
    textTransform: "none",
    fontSize: "1rem",
  },
};
