import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useSelector } from "react-redux";

function OrderNote() {
  const order = useSelector((state) => state.orders.order);
  return (
    <Card>
      <CardContent
        sx={{
          p: 0,
          "&:last-Child": {
            p: 0,
          },
        }}
      >
        <Typography variant="body1" p={2}>
          <strong>Order Note: </strong>
          {order?.orderNote ?? ""}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default memo(OrderNote);
