import CloseIcon from "@mui/icons-material/Close";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import EmojiPicker from "emoji-picker-react";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

function Emojis({ handleEmojiSelection }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const isActiveRoom = useSelector((state) => state.chat.activeRoom._id ?? "");

  const handleClick = useCallback(
    (newPlacement) => (event) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    },
    [placement]
  );

  useEffect(() => {
    if (isActiveRoom) {
      setOpen(false);
    }
  }, [isActiveRoom]);

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{
          position: "relative",
          zIndex: "100000",
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper>
              <EmojiPicker
                height={320}
                onEmojiClick={handleEmojiSelection}
                searchDisabled
                previewConfig={{
                  showPreview: false,
                }}
                skinTonesDisabled
                lazyLoadEmojis
              />
            </Paper>
          </Fade>
        )}
      </Popper>
      <IconButton
        onClick={handleClick("top-start")}
        sx={{
          color: "#00000099",
          cursor: "pointer",
          p: 0,
        }}
      >
        {open ? <CloseIcon /> : <InsertEmoticonIcon />}
      </IconButton>
    </>
  );
}

Emojis.propTypes = {
  handleEmojiSelection: PropTypes.func.isRequired,
};

export default memo(Emojis);
